/**
 * Description: 维护一些常量.
 * Created Date: 2021-03-04 5:59:35 PM
 * Author: Liu Man <lm@hcttop.com>
 * -----
 * Last Modified: 2021-03-04, 5:59:35 PM
 * Modified By: Liu Man <lm@hcttop.com>
 */
// 新闻中心的列表
export const newsTypeKey = {
  // 全部
  all: '',
  // 企业动态
  qydt: 'qydt',
  // 行业资讯
  hyzx: 'hyzx',
};
