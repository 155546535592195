<!--
 * Description: 智慧水务、环保、园区下各个子系统详情页面.
 * Created Date: 2021-01-25 2:32:03 PM
 * Author: Zhao xiaoqin <zxq@hcttop.com>
 * -----
 * Last Modified: 2021-03-18, 11:41:03 AM
 * Modified By: Liu Man <lm@hcttop.com>
 -->

<template>
  <div>
    <page-header></page-header>
    <div class="product-detail-wrap">
      <!-- banner -->
      <div
        class="detail-banner"
        :style="`background-image:url(${currentDetail.bannerBg});`"
      >
        <div :class="['content banner-content']">
          <div class="left">
            <div data-aos="fade-down" data-aos-delay="100" class="detail-title">
              {{ currentDetail.name }}
            </div>
            <div class="line" data-aos="fade-in" data-aos-delay="300"></div>
            <div data-aos="fade-up" data-aos-delay="100" class="detail-desc">
              {{ currentDetail.desc }}
            </div>
          </div>
          <img
            class="banner-img"
            :src="currentDetail.bannerImg"
            alt=""
            data-aos="fade-up"
            data-aos-delay="100"
          />
        </div>
      </div>
      <!-- 详情内容 -->
      <div class="content-detail-wrap">
        <detail-content :currentDetail="currentDetail"></detail-content>
      </div>
      <!-- 服务咨询 -->
      <service-consultation></service-consultation>
    </div>
    <page-footer></page-footer>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageFooter from '@/components/PageFooter';
import DetailContent from '../product/components/DetailContent';
import ServiceConsultation from '../product/components/ServiceConsultation';
import { waterDetailList, envDetailList, parkDetailList } from './data';

export default {
  name: 'ProductEnvironmentDetail',
  components: {
    PageHeader,
    PageFooter,
    DetailContent,
    ServiceConsultation,
  },
  props: ['name'],

  data() {
    return {
      timer: null,
      currentDetail: {},
    };
  },
  mounted() {
    // 记录锚点定位位置
    this.curAnchor = localStorage.getItem('CURRENT_ANCHOR');
    this.scrollToHash(this.curAnchor);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    scrollToHash(hash) {
      this.$nextTick(() => {
        this.timer = setTimeout(() => {
          this.$scrollTo(`#${hash}`, {
            duration: 1000,
            offset: -70,
          });
        }, 200);
      });
    },
  },
  created() {
    const urlQueryParams = new URLSearchParams(window.location.search);
    const type = urlQueryParams.get('type');
    let name = urlQueryParams.get('name');
    this.currentDetail = envDetailList.get(name);
    switch (type) {
      case 'water':
        this.currentDetail = waterDetailList.get(name);
        break;
      case 'env':
        this.currentDetail = envDetailList.get(name);
        break;
      default:
        this.currentDetail = parkDetailList.get(name);
        break;
    }
  },
};
</script>

<style lang="less" scoped>
@import '~@/assets/css/global';
.hide {
  display: none;
}
// 外层容器
.product-detail-wrap {
  min-width: 1480px;
}
// 内容
.content-detail-wrap {
  width: 100%;
}
.detail-banner {
  height: 500px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  position: relative;
  .banner-content {
    padding: 0 100px;
    display: flex;
    justify-content: space-between;
    height: 100%;
    .left {
      padding-top: 220px;
      display: flex;
      flex-direction: column;
      .detail-title {
        height: 42px;
        font-size: 42px;
        font-weight: 600;
        color: #ffffff;
        line-height: 42px;
      }
      .line {
        width: 40px;
        height: 1px;
        margin: 30px 0 34px;
        background: #ffffff;
        border-radius: 2px;
      }
      .detail-desc {
        width: 600px;
        height: 96px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
      }
    }
    .banner-img {
      width: 500px;
      height: 500px;
    }
  }
}
</style>
