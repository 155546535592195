/**
 * Description: 案例信息.是首页，解决方案，标杆案例页面的案例数据来源
 * Created Date: 2021-02-27 9:37:58 AM
 * Author: Liu Man <lm@hcttop.com>
 * -----
 * Last Modified: 2021-02-27, 9:37:58 AM
 * Modified By: Liu Man <lm@hcttop.com>
 */

/**
 * type: 类型，env-环保，water-水务，park-园区
 * isBenchmark：是否是标杆案例，是标杆案例则需要problem，solution的值
 */
const caseListData = [
  {
    name: '山东青岛智慧泵房建设项目',
    content:
      '居民二次供水作为居民供水的“最后一公里”，是整个供水体系的重要环节。“智慧水务”建设是2021年海润自来水集团重点工作之一，选取深圳路67号东韩社区二次供水泵房，实施“智慧化”二次供水泵房试点建设。',
    imgName: 'img_case_qdhqzhyq',
    type: 'park',
    isBenchmark: true,
    problem: [
      '泵机等设备老化严重，故障率高，能耗大，突发情况难应对。',
      '泵房安全风险高，人员出入随意，远程监管难。',
      '巡检运维方式传统，效率低。',
      '渗漏情况频发，泵房经常淹水。',
    ],
    solution:
      '通过对二次泵房的优化改造，建立泵房集中监管系统，实现二供泵房设备远程控制及变频恒压供水，泵房环境在线监测及通风排水设备联动控制；通过巡检APP与PC端系统的协同使用，实现泵房巡检运维的高效管理；通过泵房内智能安防系统的建设，保障泵房安防360°无死角，最终实现“智能一体化”智慧泵房的构建。',
  },

  {
    name: '济南市智慧环保综合监管项目',
    content:
      '利用物联网、云计算、智能分析等技术手段，将地理信息、监控视频、GPS车辆与环保监测信息整合成统一的综合监管平台，建立市级指挥、区县治理的监管机制，为综合指挥调度和分析决策提供支撑。',
    imgName: 'img_case_jnzhhb',
    type: 'env',
    isBenchmark: false,
    problem: [
      '业务部门使用系统众多，数据联调不方便，调度效率低下',
      '污染溯源难，决策支持缺少数据依据',
    ],
    solution:
      '虹川依照智慧环保建设管理工作机制，搭建智慧环保云平台(基础设施层)、大数据管理平台(数据资源层)和智慧环保应用平台(业务应用层)三大平台，实现监测预警、综合分析、决策评估、环境监管、应急响应和公众参与六大应用系统。',
  },
  {
    name: '深圳市龙华区环境综合监管平台项目',
    content:
      '龙华区是产业大区，辖区有各类工业企业9000余家，涉及的水、气、声、土污染源比较多，环保、水务部门监管压力很大，亟需全面覆盖水、气、声、土的环境监测网络及有效的管理措施。',
    imgName: 'img_case_szlhhjjg',
    type: 'env',
    isBenchmark: false,
    problem: [
      '龙华区是产业大区，辖区有各类工业企业9000余家，企业众多，环保监管压力大',
      '区域内流域污染严重，黑臭水体整治压力大',
    ],
    solution:
      '通过建设龙华环境综合管理平台，将水、气、声、土等环境各要素进行综合监管，在信息化建设基础上实现对环保和水环境的双重管理，节省了大量的人力物力成本，为错综复杂的环保、水务监督工作提供了一套新的管理模式。',
  },
  {
    name: '济宁市智慧环保项目',
    content:
      '为落实省全域“无废城市”建设工作会议精神，市创新推出危废全流程管理模式，实现行业危废统筹运输、集中处置、闭环监管，目前已完成近百家试点企业的接入工作，全过程记录危险废物产生记录5930条，实现1万多个危废包装的全过程管控。帮助政府部门提高监管效率、企业实现危废管理规范化。',
    imgName: 'img_case_jnwfqlc',
    type: 'env',
    isBenchmark: false,
    problem: ['危险废物监管事多人少、数据不透明、监管不实时'],
    solution:
      '平台紧抓危险废物“产生、贮存、转运、收集、处置利用”五个关键环节，以智能终端为依托，基于区块链、大数据、人工智能等信息技术 ，实现危险废物全生命周期可视化、自动化和可验证化，提升危险废物信息化管控水平。',
  },

  // {
  //   name: '深圳市光明区智慧环保信息化工程项目',
  //   content:
  //     '光明环保接入水、气、噪声等环境监测设备，通过新一代物联网、大数据、云计算等先进的信息化技术，实现环境监测数据在GIS地图展示和管理，对数据进行采集过滤、融合汇总，智能分析，并以各类图表形式展现，实时掌握环境动态信息，具备预警告警功能，为决策者制定科学的解决措施提供支撑。',
  //   imgName: 'img_case_szgmzhhb',
  //   type: 'env',
  //   isBenchmark: false,
  // },
  // {
  //   name: '吉水水环境智能一体化监管建设项目',
  //   content:
  //     '利用遥感、无人机、地理信息、大数据、物联网等先进技术手段，针对管理部门、业务部门及民众，在水资源管理、水污染综合防治、水环境治理、水域纳污能力监管、行政监管与执法等方面，建设涵盖监测与预警、问题处理与闭环、综合考核和评价、民众高度参与等一体化流程的监管平台。',
  //   imgName: 'img_case_jsshjznh',
  //   type: 'env',
  //   isBenchmark: false,
  // },
  // {
  //   name: '上栗水环境综合管控平台建设项目',
  //   content:
  //     '建设综合管控平台、水文监测系统、水环境监测系统、入河排污口管理系统、污染源管控系统、卫星遥感分析系统、河道智能巡查系统、事件闭环处置系统和移动应用等，建立水环境监测、视频监控等信息共享制度、工作督察制度、加强公众参与，从而完善和规范上栗县的河湖管理。',
  //   imgName: 'img_case_slshj',
  //   type: 'env',
  //   isBenchmark: false,
  // },
  // {
  //   name: '上栗垃圾转运信息化监管体系建设项目',
  //   content:
  //     '面向垃圾收运处理、水环境监测、污染源分析监管、水环境事件处置等河湖管理和保护工作的需求，建立集垃圾可视化收运监管体系、一体化转运过程、全方位监管体系于一体的垃圾收运处理平台。通过实时在线调度，提升作业质量考核手段，搭建公众参与渠道，形成全社会广泛参与的监管格局。',
  //   imgName: 'img_case_slljzy',
  //   type: 'env',
  //   isBenchmark: false,
  // },
  // {
  //   name: '青岛水务智慧供水管理平台建设项目',
  //   content:
  //     '青岛水资源短缺，且管网设施老化严重，漏损率高，亟需大力提高水资源生产、管理、利用效率。且需对供水管网进行精细化管理，连通水厂监控数据，实现供水的调度管理。',
  //   imgName:'img_case_qdsw',
  //   type: 'water',
  //   isBenchmark: false,
  //   problem: [
  //     '青岛是严重缺水的城市，供水压力大，亟待提高供水能力。',
  //     '管网Gis数据覆盖率低，无法支持供水综合调度管理。',
  //   ],
  //   solution:
  //     '面向青岛水务集团日常供水生产及管理，构建智慧水厂管理平台，并打通整个供水管网系统，实现“一网全感知、一源全共享、一图展全局”，将水厂监控管理系统与管网管理系对接，实现日常供水及应急情况的调度管理，全面提升水务生产效率，管理服务水平。',
  // },
  {
    name: '江西鄱阳湖水流域监管平台项目',
    content:
      '利用遥感、无人机、地理信息、大数据、物联网等先进技术手段，针对管理部门、业务部门及民众，在水资源管理、水污染综合防治、水环境治理、水域纳污能力监管、行政监管与执法等方面，建设涵盖监测与预警、问题处理与闭环、综合考核和评价、民众高度参与等一体化流程的监管平台。',
    // imgName: 'img_case_jnsw',
    imgName: 'img_case_jsshjznh',
    type: 'water',
    isBenchmark: false,
    problem: [
      '供水压力大，亟待提高供水能力',
      '管网Gis数据覆盖率低，无法支持供水综合调度管理',
    ],
    solution:
      '整个工程项目概括为113工程，即：一个只会调度中心、一个业务及数据集成平台、以及生产运营、服务营销、综合管理三大体系。',
  },
  // {
  //   name: '深圳南山区智慧水务建设项目',
  //   content:
  //     '基于打造“智慧南山”的建设目标，结合南山区水务局日常业务管理需求，构建“一网全感知、一源全共享、一图展全局、一中心管控、一体化协同、一机水务通”六大智慧水务模块，全面提升水务管理工作效率、辅助决策能力和公共服务水平。',
  //   imgName: 'img_case_sznssw',
  //   type: 'water',
  //   isBenchmark: false,
  // },
  {
    name: '深圳智慧水务综合调度平台建设项目',
    content:
      '全网综合调度面向水司调度中心管理者和基层员工的应用系统，它用于接入、展示和管理水司给排水全流程的各类关键数据，并下发指令完成日常的管理指挥工作。',
    imgName: 'img_case_sznssw',
    type: 'water',
    isBenchmark: true,
    problem: [
      '无法对给排水全流程进行统一监测',
      '调度指令没有数字化，调度效果无法评估，调度经验难沉淀',
    ],
    solution:
      '平台从实际工作场景出发，打造行政区分公司信息总览、供排水监控、直饮水保障、河道治理、应急指挥、防洪排涝等主题，为行政区分公司给排水调度指挥提供总览全局、决策指挥的条件，为各业务部门提供协同作战、高效处置的作战平台。',
  },
  // {
  //   name: '中山厂网河一体化综合监管平台项目',
  //   content:
  //     '中山市小隐涌流域主要河涌为黑臭水体，水体透明度较低，且在退潮时有黑臭现象， 暗涵段内黑臭严重。为了保障到2023年，消除小隐涌流域全部黑臭河涌，已开始流域水质的综合整治工程，为了稳定治理效果，需对流域水质进行持续有效的监管。',
  //   imgName:'img_case_zscwhyth',
  //   type: 'water',
  //   isBenchmark: false,
  //   problem: [
  //     '上游工业企业多，污水偷排、漏排现象严重。',
  //     '隐涌流域常年水位偏低，水体流速缓慢，加之受到潮汐影响水流循环往复，污染物累积不能外排。',
  //   ],
  //   solution:
  //     '针对小隐涌流域水质监管运营难的问题，通过建立覆盖广、网、站、河全面的物联网监控体系，实现排水常态化自动监测，并通过数据分析处理准确及时反映治理区域水环境质量、污染源现状及变化趋势；通过建立的厂网河一体化调度管理系统，对污水排放进行有效管控，防止溢流；最终实现黑臭水体治理的“长治久清”。',
  // },
  {
    // name: '广州污水集控智慧运营管理平台项目',
    name: '广州某研究院智慧管网平台项目',
    content:
      '构建智慧管网平台，分析排水管网基础设施关系，形成适合现状和未来发展需要的排水管网主体数据库，在此基础上建立市排水管网GIS系统，实现对排水系统现状和未来资产信息的全生命周期管理以及数据共享服务。',
    imgName: 'img_case_gzwsjk',
    type: 'water',
    isBenchmark: false,
  },
  {
    // name: '枣庄市智慧环保综合监管平台建设项目',
    name: '山东枣庄市智慧环保综合监管平台建设项目',
    content:
      '为确保空气质量改善目标，按照国家生态环境建设指示精神，结合市实际情况，以提高监测能力、消除监管盲区、明确考核责任、分解传递压力为目标，重点围绕亟待解决的大气环境污染问题，在前期调研考察外地市基础上，先行建设大气综合监管平台。',
    imgName: 'img_case_zzzhhb',
    type: 'env',
    isBenchmark: true,
    problem: [
      '污染源存在属地不清、行业不清、业主不清的情况，污染问题责任主体和监管部门不明晰，难以管理',
      '环境监管“点多、面广、人手不足”的矛盾日益突出，污染问题不能及时发现、尽早处置',
    ],
    solution:
      '通过建立大气综合监管平台、网格化环境监管平台，实现用数据说话、用数据管理，把实时的数据监测、预警的问题处理和网格化管理相结合，以此为依据推动精准治污手段，有效提高环境管理能力。',
  },

  {
    name: '江苏常州智慧园区建设项目',
    content:
      '经济开发区占地29.93平方公里，大力推进科技进步，全面实施自主创新。智慧园区建设着眼更全面更好掌控开发区运营指标、服务企业，建立企业基础档案，动态管理，对企业每一诉求负责到底，确保园区可持续快速发展。',
    imgName: 'img_case_czzlzhyq',
    type: 'park',
    isBenchmark: false,
    problem: [
      '监管设施建设参差不起，监管机制不健全',
      '问题发现、处理不及时，问题发生影响严重',
    ],
    solution:
      '经济开发区占地29.93平方公里，大力推进科技进步，全面实施自主创新。智慧园区建设着眼更全面更好掌控开发区运营指标、服务企业，建立企业基础档案，动态管理，对企业每一诉求负责到底，确保园区可持续快速发展。',
  },
];

export { caseListData };
