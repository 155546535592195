<template>
  <!-- 虹川科技 -->
  <div class="company-container">
    <PageTitle :title="title" :describe="describe" />
    <div class="company-detail">
      <div
        :class="index === i ? 'louver' : 'louver-small'"
        v-for="(v, i) in louverList"
        :key="i"
        @mouseover="handleMouse(i)"
        @mouseleave="handleLeave"
        :style="{ backgroundImage: `url(${v.img})` }"
      >
        <div :class="['louver-d', index === i && !isHover ? 'stay' : '']">
          <div class="title-d">{{ v.title }}</div>
          <div class="title-m">{{ v.msg }}</div>
          <div class="title-b" @click="pageTo">
            <div class="right-text">MORE</div>
            <div class="right-icon"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/pageTitle/index';
export default {
  components: { PageTitle },
  name: '',
  data() {
    return {
      width: '100%',
      index: 0,
      isHover: false,
      title: '虹川科技',
      describe: 'Hcttop technology',
      louverList: [
        {
          img: require('@/assets/images/home/hc_technology-01.jpg'),
          title: '虹川是谁',
          msg:
            '虹川科技是业界领先的智慧型数字技术服务商，致力于数字技术助力智慧零碳和智慧水务领域转型，为客户提供持续完善的数字化综合解决方案，赋能客户创造可持续发展价值。',
        },
        {
          img: require('@/assets/images/home/hc_technology-02.jpg'),
          title: '虹川使命',
          msg:
            '我们坚信数字经济内在逻辑，努力构建数字技术和产业领域的连接、融合及升华。致力于新兴数字技术的场景化和综合性应用、实践及推广，做数字经济和可持续发展的践行者。',
        },
        {
          img: require('@/assets/images/home/hc_technology-03.jpg'),
          title: '虹川愿景',
          msg:
            '我们倡导精英治理、共创共享、持续完善、至臻务实的理念。坚持以人为本、以客户为中心、以价值创造为根本的价值观。为实现数智引领绿色产业变革持续奋斗。',
        },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleMouse(i) {
      this.index = i;
      this.isHover = true;
    },
    handleLeave() {
      this.isHover = false;
    },
    pageTo() {
      this.$router.push('/about');
    },
  },
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.company-container {
  margin-left: calc((100% - 1200px) / 2);
  .company-detail {
    min-width: 1200px;
    height: 420px;
    margin-top: 60px;
    display: flex;
    overflow: hidden;
    .louver {
      width: 100%;
      background: center no-repeat;
      background-size: cover;
      height: 420px;
      transition: all 0.5s ease-in-out;
      &:hover {
        .title-d {
          top: 60px;
        }
        .title-m {
          max-width: 660px;
          top: 134px;
          margin-right: 60px;
          opacity: 1;
          transition: all 0.5s ease;
        }
        .title-b {
          top: 266px;
          transition: all 0.5s ease;
        }
      }
    }
    .louver-small {
      width: 50%;
      background: center no-repeat;
      background-size: cover;
      height: 420px;
      transition: all 0.5s ease-in-out;
    }
    .louver-d {
      transition: all 0.5s ease;
      padding: 60px;
      position: relative;
      height: 100%;
      &:hover {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.35);
      }
      .title-d {
        font-size: 28px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        position: absolute;
        top: 315px;
        transition: all 0.25s ease;
      }
      .title-m {
        position: absolute;
        top: 420px;
        height: 112px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 28px;
        text-align: justify;
        opacity: 0;
      }
      .title-b {
        cursor: pointer;
        position: absolute;
        top: 420px;
        width: 128px;
        height: 40px;
        opacity: 0.5;
        border: 1px solid #ffffff;
        text-align: center;
        line-height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        opacity: 0.5;
        .right-text {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }
        .right-icon {
          width: 41px;
          height: 24px;
          background-size: 100% 100%;
          background: url('~@/assets/images/home/arrows-r.svg') center no-repeat;
          background-size: cover;
          margin-top: 6px;
        }
        &:hover {
          opacity: 1;
        }
      }
    }
    .stay {
      .title-d {
        top: 60px;
      }
      .title-m {
        max-width: 660px;
        top: 134px;
        margin-right: 60px;
        opacity: 1;
      }
      .title-b {
        top: 266px;
      }
    }
    .louver,
    .louver-small {
      .louver-d {
        &:hover {
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.35);
        }
      }
    }
  }
}
</style>
