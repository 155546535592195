<template>
  <!-- 企业资质 -->
  <div class="enterprise-qualification">
    <div class="qualification-box">
      <page-title
        :title="title"
        :describe="describe"
        data-aos="fade-down"
        data-aos-delay="100"
        class="qualification-title"
      ></page-title>
      <div class="qualification-list">
        <div
          class="item"
          v-for="(item, index) in imgList"
          :key="index"
          data-aos="flip-left"
          data-aos-delay="100"
        >
          <div class="title">{{ item.title }}</div>
          <div class="line"></div>
          <img :src="item.img" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/pageTitle/index';

export default {
  name: 'EnterpriseQualification',
  components: { PageTitle },
  data() {
    return {
      title: '企业资质',
      describe: 'Enterprise qualification',
      imgList: [
        {
          title: '国家高新技术企业证书',
          img: require('@/assets/images/about/certificate_01.png'),
        },
        {
          title: '环境/质量/信息安全管理体系证书',
          img: require('@/assets/images/about/certificate_02.png'),
        },
        {
          title: '中国软件企业评估-软件企业证书',
          img: require('@/assets/images/about/certificate_03.png'),
        },
        {
          title: '中国软件企业评估-软件产品证书',
          img: require('@/assets/images/about/certificate_04.png'),
        },
        {
          title: '深圳市供排水行业协会副会长',
          img: require('@/assets/images/about/certificate_05.png'),
        },
        {
          title: '实用新型专利证书',
          img: require('@/assets/images/about/certificate_06.png'),
        },
        {
          title: '实用新型专利证书',
          img: require('@/assets/images/about/certificate_07.png'),
        },
        {
          title: '计算机软件著作权登记证书',
          img: require('@/assets/images/about/certificate_08.png'),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.enterprise-qualification {
  width: 100%;
  height: 836px;
  background: url('~@/assets/images/about/enterprise_qualification_bg.png')
    center center no-repeat;
  background-size: cover;
  .qualification-box {
    width: 1200px;
    margin: 0 auto;
    .qualification-title {
      padding-top: 50px;
      margin-bottom: 55px;
    }
    .qualification-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      .item {
        width: 283px;
        height: 275px;
        padding: 24px 16px;
        background-color: #ffffff;
        color: #101010;
        margin-bottom: 24px;
        .title {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #101010;
          line-height: 16px;
          letter-spacing: 0px;
        }
        .line {
          width: 17px;
          height: 1px;
          background: #101010;
          margin-top: 16px;
          margin-bottom: 15px;
        }
        img {
          width: 251px;
        }
      }
    }
  }
}
</style>
