<template>
  <!-- 页面title -->
  <div class="title-wrapper">
    <div class="title" :style="`color: ${isBlack ? '#1c1c1c' : '#ffffff'}`">
      {{ title }}
    </div>
    <div class="describe" :style="`color: ${isBlack ? '#1c1c1c' : '#ffffff'}`">
      {{ describe }}
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
    },
    describe: {
      type: String,
    },
    // 是否是黑色
    isBlack: {
      type: Boolean,
      default: true,
    },
  },
  name: '',
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.title-wrapper {
  display: flex;
  flex-direction: column;
  .title {
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 32px;
  }
  .describe {
    margin-top: 10px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 24px;
  }
}
</style>
