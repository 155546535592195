<template>
  <!-- 解决方案 -->
  <div class="solution-wrapper">
    <div class="solution-container">
      <PageTitle :title="title" :describe="describe" class="title" />
      <div class="solution-detail">
        <div
          class="video-item"
          v-for="(v, i) in solutionList"
          :key="i"
          @mouseenter="handleMouse(i)"
          @mouseleave="handleLeave"
          @click="pageTo(v)"
        >
          <video
            :id="`video_${i}`"
            :src="v.video"
            muted
            preload
            @contextmenu="contextmenu"
            disablePictureInPicture="true"
            disableRemotePlayback="true"
            controlsList="nodownload nofullscreen noremoteplayback"
          />
          <div class="video-info">
            <div class="video-name">{{ v.name }}</div>
            <div class="desc">
              {{ v.desc }}
            </div>
            <div class="video-more" @click="pageTo(v)">
              <div class="right-text">MORE</div>
              <div class="right-icon"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/pageTitle/index';
export default {
  components: { PageTitle },
  name: '',
  data() {
    return {
      title: '解决方案',
      describe: 'Total Solution',
      solutionList: [
        {
          video: require('@/assets/video/home_zero_carbon_product.mp4'),
          direction: '/wisdom-zero-carbon-product',
          desc: '助力政府、企业低碳发展早日实现碳达峰、碳中和目标',
          name: '智慧零碳',
        },
        {
          video: require('@/assets/video/home_water.mp4'),
          direction: '/wisdom-water-product',
          desc: '助力政府、水务公司实现信息化升级数字化转型、智慧化变革',
          name: '智慧水务',
        },
      ],
      imgHover: require('@/assets/images/home/solution_hover.png'),
      index: 0,
      video: null,
      plays: [{}, {}],
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleMouse(i) {
      this.index = i;
      this.playVideo();
    },
    playVideo() {
      this.video = document.getElementById(`video_${this.index}`);
      const { currTime } = this.plays[this.index];
      this.video.currentTime = currTime || 0;
      this.video.play();
      this.timeDisplay();
    },
    handleLeave() {
      this.video.pause();
    },
    timeDisplay() {
      this.video.addEventListener(
        'timeupdate',
        () => {
          const currTime = Math.floor(this.video.currentTime); // 当前播放的时长
          const duration = Math.floor(this.video.duration); // 总时长

          this.$set(this.plays, this.index, { currTime, duration });
          if (currTime === duration) {
            setTimeout(() => {
              this.video.play();
            }, 0);
          }
        },
        true
      );
    },
    contextmenu(e) {
      e.returnValue = false;
    },
    pageTo(v) {
      this.$router.push(v.direction);
    },
  },
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
//所有控件
video::-webkit-media-controls-enclosure {
  display: none;
}
.solution-wrapper {
  width: 100%;
  height: 720px;
  .solution-container {
    width: 1200px;
    margin: 0 auto;
    .title {
      padding-top: 80px;
      margin-bottom: 40px;
    }
    .solution-detail {
      width: 1200px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      text-align: center;

      .video-item {
        width: 600px;
        height: 100%;
        video {
          width: 468px;
          height: 384px;
        }
        .video-info {
          width: 100%;
          height: 150px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .video-name {
            font-size: 28px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #313a44;
            line-height: 40px;
            letter-spacing: 1px;
            margin-bottom: 4px;
          }
          .desc {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #313a44;
            line-height: 20px;
            opacity: 0;
            transform: translateY(120px);
            transition: all 0.5s ease;
          }
          .video-more {
            margin-top: 20px;
            opacity: 0;
            transform: translateY(120px);
            transition: all 0.5s ease;
            cursor: pointer;
            display: flex;
            align-items: center;
            .right-text {
              font-size: 14px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #49525c;
              line-height: 20px;
            }
            .right-icon {
              width: 39px;
              height: 7px;
              background-image: url('~@/assets/images/home/arrows_right_black.svg');
              background-position: center;
              background-repeat: no-repeat;
              background-size: 100% 100%;
              opacity: 0;
            }
            &:hover {
              .right-text {
                color: #2750d6;
              }
              .right-icon {
                background-image: url('~@/assets/images/home/arrows_right_blue.svg');
              }
            }
          }
        }
        &:hover {
          .video-info {
            .desc,
            .video-more {
              opacity: 1;
              transform: translateY(0px);
              transition: all 0.5s ease;
              .right-text {
                transition: all 0.5s ease 0.25s;
                margin-right: 20px;
              }
              .right-icon {
                opacity: 1;
                transition: all 0.5s ease 0.25s;
              }
            }
          }
        }
      }
    }
  }
}
</style>
