<!--
 * Description: 智慧环保
 * Created Date: 2020-06-24 7:30:23 PM
 * Author: Liu Man <lm@hcttop.com>
 * -----
 * Last Modified: 2021-03-18, 11:41:28 AM
 * Modified By: Liu Man <lm@hcttop.com>
 -->

<template>
  <div class="hc-product-container">
    <page-header></page-header>
    <!-- 主导航 -->
    <product-contentNav
      :route="routeInfo"
      :navInfo="navInfo"
    ></product-contentNav>
    <!-- 导航列表 -->
    <product-nav-list
      @activeCurNav="activeCurNav"
      :navTitle="navTitle"
      :navList="navList"
    ></product-nav-list>
    <!-- 方案介绍 -->
    <scheme-introduction :introduction="introduction"></scheme-introduction>
    <!-- 方案架构 -->
    <scheme-architecture :archData="archData"></scheme-architecture>
    <!-- 案例 -->
    <case-product :caseList="caseList"></case-product>
    <!-- 跳转服务咨询 -->
    <service-consultation></service-consultation>
    <page-footer></page-footer>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageFooter from '@/components/PageFooter';
import ProductContentNav from '../product/components/ProductContentNav';
import ProductNavList from '../product/components/ProductNavList';
import SchemeIntroduction from '../product/components/SchemeIntroduction';
import SchemeArchitecture from '../product/components/SchemeArchitecture';
import CaseProduct from '../product/components/CaseProduct';
import ServiceConsultation from '../product/components/ServiceConsultation';
import { caseListData } from '@/assets/data/casesInfo.js';

export default {
  components: {
    PageHeader,
    PageFooter,
    ProductContentNav,
    ProductNavList,
    SchemeIntroduction,
    SchemeArchitecture,
    CaseProduct,
    ServiceConsultation,
  },
  data() {
    return {
      timer: null,
      routeInfo: {},
      caseListData,
      // 导航信息
      navInfo: {
        title: '一体化监管调度中心，支撑引领生态环境管理现代化',
        desc:
          '以城市生态环境质量持续改善为目标，通过“三化四网”建设，构建一体化监管调度中心，具备整体态势感知、实时监控预警、数据研判分析、网格化管理、应急指挥调度等功能，助力精准治污、科学治污、依法治污，推进城市生态环境治理体系和治理能力现代化。',
      },
      navTitle: '一体化监管调度中心',
      //  导航列表
      navList: [
        {
          anchor: 'dqzl',
          name: '大气监测',
          desc:
            '集数据采集、管理、分析及应用为一体，通过对监测数据的分析处理，实现智能监控预警',
          img: require('@/assets/images/product/env_icon_01.png'),
        },
        {
          anchor: 'shj',
          name: '水环境监测',
          desc:
            '对流域环境质量实时监控预警，通过数据发掘，实现水质趋势分析、污染源分析等功能，为水环境综合管理提供决策支持',
          img: require('@/assets/images/product/env_icon_02.png'),
        },
        {
          anchor: 'dlnh',
          name: '电量监测',
          desc:
            '远程监管企业生产治污情况，精准打击排污企业治污设施运行异常、生产设施违规开启情况',
          img: require('@/assets/images/product/env_icon_03.png'),
        },
        {
          anchor: 'wqpf',
          name: '尾气监测',
          desc:
            '实时监控城市道路机动车污染排放状况，分析机动车污染对空气质量的影响，为管理提供决策支持。',
          img: require('@/assets/images/product/env_icon_04.png'),
        },
        {
          anchor: 'wgh',
          name: '网格化监管',
          desc:
            '将管辖地域划分成若干网格单元，对每一网格实施动态管理，实现市区联动、资源共享。',
          img: require('@/assets/images/product/env_icon_05.png'),
        },
        {
          anchor: 'wfgl',
          name: '危废管理',
          desc:
            '在危险废物从产生到处置的全过程中，从数据、现场、风险、应急四个方面进行全方位管控',
          img: require('@/assets/images/product/env_icon_06.png'),
        },
      ],
      // 方案介绍数据
      introduction: {
        // 方案介绍背景
        bg: require('@/assets/images/product/env_scheme_bg.png'),
        importantPoint: [
          {
            name: '环境应急指挥联动弱，环境问题难以及时有效处理。',
          },
          {
            name: '监测数据分散孤立、利用率低，区域布局管控能力不足。',
          },
        ],
        description:
          '充分利用物联网、云计算、大数据、地理信息等技术，整合设备、业务、数据和流程，以改善环境质量为目标，以服务社会、企业及公众为宗旨，以需求为导向，以实用性、易用性为原则，统筹规划、整体布局，形成立体网格化环境管理格局，实现环境数据标准化、环境监控动态化、环境监管精准化、环境决策科学化、环境服务便民化，为环境监管智慧化提供强有力的技术支撑。',
        featuresList: [
          {
            name: '污染全留痕',
            desc: '环境质量数据和污染源排放数据融合分析，实现污染全留痕。',
            icon: require('@/assets/images/product/env_fatd_icon_01.svg'),
          },
          {
            name: '线索全收集',
            desc:
              '多渠道收集污染线索、及时保存污染证据，实现科学准确的问题清单。',
            icon: require('@/assets/images/product/env_fatd_icon_02.svg'),
          },
          {
            name: '决策全智能',
            desc:
              '以数据和算法为径，构建环境质量预测溯源体系，为环境管理提供科学建议。',
            icon: require('@/assets/images/product/env_fatd_icon_03.svg'),
          },
          {
            name: '效果全评估',
            desc:
              '实现“经济发展-能源消耗-防控措施-污染排放-环境质量-人群健康”的系统化评估。',
            icon: require('@/assets/images/product/env_fatd_icon_04.svg'),
          },
        ],
      },
      // 方案架构数据
      archData: {
        jiagouImg: require('@/assets/images/product/env_dcjg_img.svg'),
        yewuImg: require('@/assets/images/product/env_yewu_img.svg'),
      },
      // 案例
      caseList: [],
    };
  },
  created() {
    this.initData();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      Object.assign(vm.routeInfo, { to, from });
    });
  },
  mounted() {
    // 记录锚点定位位置
    this.curAnchor = localStorage.getItem('CURRENT_ANCHOR');
    this.scrollToHash(this.curAnchor);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    initData() {
      this.caseList = this.getCaseList(this.caseListData);
    },
    getCaseList(array) {
      const result = array.filter(data => {
        return data.type == 'env';
      });
      return result;
    },
    scrollToHash(hash) {
      this.$nextTick(() => {
        this.timer = setTimeout(() => {
          this.$scrollTo(`#${hash}`, {
            duration: 1000,
            offset: -70,
          });
        }, 200);
      });
    },
    activeCurNav(nav) {
      localStorage.setItem('CURRENT_ANCHOR', nav.anchor);
      this.$router.push('/product-detail?type=env&name=' + nav.anchor);
    },
  },
};
</script>
<style lang="less" scoped>
@import '~@/assets/css/global';
.hc-product-container {
  min-width: 1480px;
  overflow: auto;
}
</style>
