<!--
 * Description: 解决方案下拉菜单
 * Created Date: 2020-06-24 7:30:23 PM
 * Author: Liu Man <lm@hcttop.com>
 * -----
 * Last Modified: 2020-06-24, 7:30:23 PM
 * Modified By: Liu Man <lm@hcttop.com>
 -->

<template>
  <div class="hc-drop-down-box product" @mouseleave="hideOnMouseLeave">
    <div class="product-tabs">
      <div
        v-for="(item, index) in tabs"
        :key="index"
        :class="['item', activeIndex == index ? 'active' : '']"
        @click="changeTabIndex(index)"
      >
        <div class="icon">
          <img :src="activeIndex == index ? item.active : item.icon" alt="" />
        </div>
        <div class="info">{{ item.name }}</div>
      </div>
    </div>
    <div
      :class="['drop-down-box-content', activeIndex === 1 ? 'water-flex' : '']"
    >
      <div
        :class="['drop-down-item']"
        v-for="(product, index) in filterList"
        :key="product.id"
      >
        <router-link
          class="content-title"
          :to="{ path: `${product.destination}` }"
          @click="cleanActiveAnchor"
        >
          <div
            class="product-title"
            :style="`color: ${activeNav == index ? '#2750d6' : ''}`"
            @click="goProductCenter(product)"
          >
            {{ product.title }}
          </div>
          <div :class="['product-line']"></div>
        </router-link>
        <div class="nav-lists-wrap">
          <div
            class="nav-list-item"
            v-for="navL in product.navList"
            :key="navL.id"
          >
            <a
              @click="activeCurNav(navL, product)"
              :class="{
                active:
                  navL.anchor === curAnchor ||
                  (activeNav == 1 && navL.anchor === 'zhltzhjgfwpt'),
              }"
              >{{ navL.title }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const shortid = require('shortid');
export default {
  name: 'DropDownBox',
  props: {
    currActive: {
      type: Number,
      default: 3,
    },
  },
  data() {
    return {
      //当前产品类型（0：水务，1：零碳，2：环保，？：园区）
      activeNav: null,
      curAnchor: '',
      isVisible: false,
      zeroCarbon: [
        {
          title: '城市零碳监管服务',
          destination: '/case-zero-carbon?target=0',
          id: shortid.generate(),
          navList: [
            {
              title: '城市零碳驾驶舱',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '城市零碳分析与规划系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '城市碳排放配额分配系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '城市温室气体清单动态管理系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '城市CCER项目管理系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '碳排放因子数据库管理系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '园区资源评估分析系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '城市碳监测与智能分析系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '城市零减排措施库管理系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '城市碳中和举措协同管理系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '企业动态画像',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '城市碳汇管理系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '循环经济评估考核系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '城市零碳综合调度系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '零碳资源目录管理系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: 'AI辅助決策系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '环保督察支撑服务系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '园区综合调度指挥系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '用能权审核系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
          ],
        },
        {
          title: '企业零碳服务',
          destination: '/case-zero-carbon?target=1',
          id: shortid.generate(),
          navList: [
            {
              title: '企业碳监测与智能分析系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '企业碳资产管理系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '企业碳配额服务系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '企业碳核查系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '企业CCER项目管理系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '企业零碳情景分析系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '企业碳减排措施库管理系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '企业碳考核管理系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '企业碳价值分析系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: 'LCA产品碳足迹',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '企业量化风险监控系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '企业碳环境影响评价系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '企业CDP碳信息披露系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '企业碳排放交易支撑系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '企业碳管家',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '碳知识图谱',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '企业碳学堂',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '绿色资讯服务系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '绿色评级服务系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '绿色消费服务系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '绿色会议系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
          ],
        },
        {
          title: '零碳公众服务',
          destination: '/case-zero-carbon?target=3',
          id: shortid.generate(),
          navList: [
            {
              title: '碳普惠管理系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '碳足迹计算器',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '个人低碳服务系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '绿色消费服务系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
          ],
        },
        {
          title: '碳金融服务',
          destination: '/case-zero-carbon?target=2',
          id: shortid.generate(),
          navList: [
            {
              title: '碳信息披露',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: 'ESG评估',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
            {
              title: '碳汇投资服务系统',
              destination: '',
              anchor: '',
              id: shortid.generate(),
            },
          ],
        },
      ],
      waterAffairs: [
        {
          title: '综合管控',
          destination: '',
          id: shortid.generate(),
          navList: [
            {
              title: '运营管控平台',
              destination: '/case/case-detail',
              anchor: 'yygkpt',
              id: shortid.generate(),
            },
          ],
        },
        {
          title: '供水一体',
          destination: '',
          id: shortid.generate(),
          navList: [
            {
              title: '区域供水一体化管理平台',
              destination: '/case/case-detail',
              anchor: 'qygsyth',
              id: shortid.generate(),
            },
            {
              title: '二次供水泵房管理平台',
              destination: '/case/case-detail',
              anchor: 'ecgsbf',
              id: shortid.generate(),
            },
            {
              title: '智慧厂站一体化管控平台',
              destination: '/case/case-detail',
              anchor: 'zhczyth',
              id: shortid.generate(),
            },
            {
              title: '水库安全综合监管平台',
              destination: '/case/case-detail',
              anchor: 'skaqzh',
              id: shortid.generate(),
            },
            {
              title: '管网GIS管理平台',
              destination: '/case/case-detail',
              anchor: 'gwgis',
              id: shortid.generate(),
            },
          ],
        },
        {
          title: '水环境',
          destination: '',
          id: shortid.generate(),
          navList: [
            {
              title: '水环境综合监管平台',
              destination: '/case/case-detail',
              anchor: 'shjzhjg',
              id: shortid.generate(),
            },
          ],
        },
      ],
      tabs: [
        {
          name: '智慧零碳产品案例',
          icon: require('@/assets/images/navbar/zero_carbon.svg'),
          active: require('@/assets/images/navbar/zero_carbon_active.svg'),
        },
        {
          name: '智慧水务产品案例',
          icon: require('@/assets/images/navbar/water_affairs.svg'),
          active: require('@/assets/images/navbar/water_affairs_active.svg'),
        },
      ],
      activeIndex: 0,
    };
  },
  mounted() {
    let url = window.location.pathname;
    const urlQueryParams = new URLSearchParams(window.location.search);
    const type = urlQueryParams.get('type');
    if (url == '/wisdom-water-product' || type == 'water') {
      this.activeNav = 0;
    } else if (url == '/wisdom-zero-carbon-product' || type == 'zero') {
      this.activeNav = 1;
    } else if (url == '/wisdom-env-product' || type == 'env') {
      this.activeNav = 2;
    }
    // else if (url == '/wisdom-park-product' || type == 'park') {
    //   this.activeNav = 2;
    // }
    else {
      this.activeNav = -1;
    }

    this.curAnchor = localStorage.getItem('CURRENT_ANCHOR');
  },
  computed: {
    filterList() {
      return this.activeIndex === 0 ? this.zeroCarbon : this.waterAffairs;
    },
  },
  methods: {
    activeCurNav(nav, product) {
      // localStorage.setItem('CURRENT_ANCHOR', nav.anchor);
      // if (nav.destination === '/wisdom-zero-carbon-product') {
      //   window.location.href = nav.destination;
      // } else {
      //   window.location.href = `${nav.destination}?type=${
      //     product.destination.split('-')[1]
      //   }&name=${nav.anchor}`;
      // }
      if (product.destination === '/case-zero-carbon') return;
      if (this.activeIndex === 1) {
        localStorage.setItem('CURRENT_ANCHOR', nav.anchor);
        window.location.href = `${nav.destination}?type=water&name=${nav.anchor}`;
      }
    },
    hideOnMouseLeave() {
      this.$emit('hideProductNavBox', this.isVisible);
    },
    cleanActiveAnchor() {
      localStorage.clear();
    },
    goProductCenter(v) {
      if (this.activeIndex === 0) {
        localStorage.clear();
        window.location.href = v.destination;
      }
    },
    changeTabIndex(index) {
      if (this.activeIndex !== index) {
        this.activeIndex = index;
      }
    },
  },
  watch: {
    currActive: {
      handler() {
        if (this.currActive === 3) {
          this.activeIndex =
            window.location.pathname === '/case/case-detail' ? 1 : 0;
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/css/global';
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  width: 4px;
  background: transparent;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  width: 4px;
  border-radius: 5px;
  background-color: #2750d6;
}

.hc-drop-down-box {
  // position: absolute;
  // top: 72px;
  // left: 0;
  overflow: hidden;
  background: rgba(255, 255, 255, 1);
  border-radius: 0 0 2px 2px;
  z-index: 2;
  height: auto;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.06);

  &.product {
    display: flex;
    align-items: flex-start;
  }
}

.product-tabs {
  width: 252px;
  // height: 520px;
  background: rgba(39, 80, 214, 0.03);
  display: flex;
  flex-direction: column;
  .item {
    width: 252px;
    height: 62px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .icon {
      width: 38px;
      height: 38px;
      margin: 12px 10px 12px 33px;
    }
    .info {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #343434;
      line-height: 20px;
      letter-spacing: 1px;
    }
  }
  .active {
    background: rgba(39, 80, 214, 0.06);
    .info {
      color: #2750d6;
    }
  }
}

.drop-down-box-content {
  width: calc(100% - 252px);
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 10px;

  .drop-down-item {
    // width: 705px;
    padding: 0 25px;
    width: 100%;
  }
  .content-title {
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .product-title {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #2750d6;
      line-height: 14px;
      margin-top: 20px;
      margin-bottom: 10px;
      &:hover {
        text-decoration: none;
        color: rgba(48, 60, 168, 1);
      }
    }
    .product-line {
      // width: 630px;
      height: 1px;
      background: #e2e2e2;
    }
    &:hover {
      .product-title {
        text-decoration: none;
        color: rgba(48, 60, 168, 1);
      }
    }
  }
  .nav-lists-wrap {
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: space-between;
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto auto auto;
    &::after {
      content: '';
      width: 200px;
      margin-right: 20px;
    }

    .nav-list-item {
      width: 200px;
      margin-right: 20px;
      padding: 10px;
      padding-left: 0;
      a.active {
        color: rgba(48, 60, 168, 1);
      }
      a {
        cursor: pointer;
        display: block;
        border: none !important;
        white-space: nowrap;

        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #343434;
        line-height: 12px;
        &:hover {
          text-decoration: none;
          color: rgba(48, 60, 168, 1);
        }
      }
    }
  }
}

.water-flex {
  display: flex;
  align-items: flex-start;
  .drop-down-item {
    width: 33%;
    .nav-lists-wrap {
      display: flex;
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 1199px) {
  .product {
    width: 100%;
    position: absolute;
    top: 72px;
    left: 50%;
    transform: translate(-50%, 0%);

    .drop-down-box-content {
      height: 384px;
      overflow-y: auto;
      .nav-lists-wrap {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-template-rows: auto auto auto;
      }
    }
    .water-flex .nav-lists-wrap {
      display: flex;
      flex-direction: column;
    }
  }
}

@media screen and (min-width: 1200px) {
  .product {
    width: 100%;
    height: 520px;
    position: absolute;
    top: 72px;
    left: 50%;
    transform: translate(-50%, 0%);

    .drop-down-box-content {
      height: 520px;
      overflow-y: auto;
    }
  }
}

@media screen and (min-width: 1920px) {
  .product {
    height: 720px;
    position: absolute;
    top: 72px;
    left: 50%;
    transform: translate(-50%, 0%);
    .drop-down-box-content {
      height: 720px;
      overflow-y: auto;
    }
  }
}
</style>
