/**
 *  Description: 利用Sst集合做数据抽离,减少if判断
 * Description: reauired, the file description, reference link, etc.
 * Created Date: 2021-01-28 5:07:15 PM
 * Author: Hu Yongjun <hyj@hcttop.com>
 * -----
 * Last Modified: 2021-01-29, 10:47:13 AM
 * Modified By: Liu Man <lm@hcttop.com>
 */
// 水务详情列表数据
export const waterDetailList = new Map()
  .set('zhsc', {
    name: '智慧水厂',
    desc:
      '针对水厂目前人工参与多，控制不可靠；设备评估不足；药耗、能耗高等问题，通过物联网平台、生产监控平台、大数据分析等应用系统，实现生产、运营的智能化监管，并加强设备全生命周期管理；降低能耗、药耗的同时保障水厂高效生产运营。',
    bannerBg: require('@/assets/images/banner_bg_product_detail.png'),
    bannerImg: require('@/assets/images/product/water_banner_img_01.png'),
    sysArchImg: require('@/assets/images/product/water_jiagou_01.svg'),
    sysViews: require('@/assets/images/product/water_views_01.png'),
    functionList: [
      {
        name: '智能工艺',
        desc:
          '基于物联网、大数据挖掘等先进技术应用，实现水厂各工艺单元智能控制及实时预警',
        icon: require('@/assets/images/product/icon_function_01.svg'),
      },

      {
        name: '资产生命周期管理',
        desc: '实现资产的健康管理，风险评估，维修养护，保障设备良好运行',
        icon: require('@/assets/images/product/icon_function_02.svg'),
      },
      {
        name: '安全监控',
        desc: '实现视频监控、门禁、电子围栏等全方位，一体化实时安全监控',
        icon: require('@/assets/images/product/icon_function_03.svg'),
      },
      {
        name: '生产运行KPI报表',
        desc:
          '针对水厂运行关键指标，进行统计分析，自动输出统计报表，为运行管理提供决策支持',
        icon: require('@/assets/images/product/icon_function_04.svg'),
      },
    ],
  })
  .set('zhgw', {
    name: '智慧管网',
    desc:
      '智慧管网解决方案是一套完整的供水管网信息化系列产品及技术有机组合，方案基于空间数据库及管网水力模型，集成了管网漏失检测与控制关键技术，将管网资产管理、模型运算、漏损控制、调度决策等应用完整融合为一体，帮助水务企业快速构建科学、完善的管网输配体系，不断提升城市水务管理品质。',
    bannerBg: require('@/assets/images/banner_bg_product_detail.png'),
    bannerImg: require('@/assets/images/product/water_banner_img_02.png'),
    sysArchImg: require('@/assets/images/product/water_jiagou_02.svg'),
    sysViews: require('@/assets/images/product/water_views_02.png'),
    functionList: [
      {
        name: '管网数字化管理',
        desc: '实现基于GIS的管网设施数据化管理，对管网压力、流量等进行动态监控',
        icon: require('@/assets/images/product/icon_function_01.svg'),
      },
      {
        name: '管网水力模型',
        desc:
          '实现管网运行分析、日常调度、应急调度、规划设计、辅助决策等水力模型的应用',
        icon: require('@/assets/images/product/icon_function_02.svg'),
      },
      {
        name: '外业工单管理',
        desc: '通过APP和PC端的协同应用，实现管网维检修的闭环管理，全程可追溯',
        icon: require('@/assets/images/product/icon_function_03.svg'),
      },
    ],
  })
  .set('ecgs', {
    name: '二次供水',
    desc:
      '针对二次供水终端水质污染、监管运营难等问题，通过建立二次供水管理系统，实现设备运行状态的远程监控及健康管理，通过工单管理实现外业巡检闭环管理，充分保障泵房在无人/少人值守情况的安全性，保障水质，提高管理效益。',
    bannerBg: require('@/assets/images/banner_bg_product_detail.png'),
    bannerImg: require('@/assets/images/product/water_banner_img_03.png'),
    sysArchImg: require('@/assets/images/product/water_jiagou_03.svg'),
    sysViews: require('@/assets/images/product/water_views_03.png'),
    functionList: [
      {
        name: '设备状态监控',
        desc:
          '供水设备液位、压力、频率、流量、能耗，泵房环境、温度、湿度、跑冒滴漏等',
        icon: require('@/assets/images/product/icon_function_01.svg'),
      },
      {
        name: '设备远程控制',
        desc: '二次机组的急停、远程调参，监控门禁、视频、报警等系统',
        icon: require('@/assets/images/product/icon_function_02.svg'),
      },
      {
        name: '实时水质监测预警',
        desc: '水质余氯、浊度、PH值等数据，智能分析水质',
        icon: require('@/assets/images/product/icon_function_03.svg'),
      },
    ],
  })
  .set('zhgsscdd', {
    name: '综合调度',
    desc:
      '综合调度整合水厂生产单位、加压泵站、供排水管网、二次加压泵房、河道排口等涉水设施的运行数据，结合供排水水力水质模型应用，打通了客服、生产、管网、外勤等业务流程，打造供排水监控、直饮水保障、河道治理、应急指挥、防洪排涝等专项主题，为给排水调度指挥提供总览全局、决策指挥支撑。',
    bannerBg: require('@/assets/images/banner_bg_product_detail.png'),
    bannerImg: require('@/assets/images/product/water_banner_img_04.png'),
    sysArchImg: require('@/assets/images/product/water_jiagou_04.svg'),
    sysViews: require('@/assets/images/product/water_views_04.png'),
    functionList: [
      {
        name: '动态管控',
        desc: '实时数据一张图监测，多业务数据融合分析，全流程可视化动态模拟',
        icon: require('@/assets/images/product/icon_function_01.svg'),
      },
      {
        name: '日常调度',
        desc: '全流程电子指令闭环管理，调度更加及时、准确',
        icon: require('@/assets/images/product/icon_function_02.svg'),
      },
      {
        name: '应急指挥',
        desc: '数据统一、平台统一、处置联动，全面可视化应急指挥全过程',
        icon: require('@/assets/images/product/icon_function_03.svg'),
      },
    ],
  })
  .set('cspldtgk', {
    name: '智慧防汛',
    desc:
      '针对强降雨天气下的城市内涝问题，对排水管网、泵站、蓄水池等雨洪设施进行监管，对超标水位预警预报，调度周边雨洪设施降低易涝点水位。结合气象数据及地表水力模型，对易涝点水位进行预测，运用水力模型模拟雨洪措施效果，为应对内涝提供决策支持。提供城市防汛应急系统支持，实现远程会议召开、资源调度、应急指挥等功能。',
    bannerBg: require('@/assets/images/banner_bg_product_detail.png'),
    bannerImg: require('@/assets/images/product/water_banner_img_05.png'),
    sysArchImg: require('@/assets/images/product/water_jiagou_05.svg'),
    sysViews: require('@/assets/images/product/water_views_05.png'),
    functionList: [
      {
        name: '水位预警预报',
        desc: '对易涝点位进行实时水位及视频监测，对超标水位进行预警预报',
        icon: require('@/assets/images/product/icon_function_01.svg'),
      },
      {
        name: '雨洪设施监控',
        desc:
          '结合易涝点水位情况，对排水管网、泵站、蓄水池等雨洪设施进行监管，保障水位处于安全范围',
        icon: require('@/assets/images/product/icon_function_02.svg'),
      },
      {
        name: '应急管理',
        desc: '提供远程视频会议，资源调度，灾情评估等应急管理支持',
        icon: require('@/assets/images/product/icon_function_03.svg'),
      },
    ],
  })
  .set('cwhpwyth', {
    name: '厂网河一体化',
    desc:
      '排污全过程透明监控：排水源头→管道→泵站→污水处理厂→河道排放。对排水管网进行水量分配，最大限度发挥污水处理效能。实时监测管网水位，保障低水位，减少截流污水溢流。实时监测排污企业，防止偷排漏排，避免污水直接排放入河流。精细化管理，正本清源，根治黑臭水体。',
    bannerBg: require('@/assets/images/banner_bg_product_detail.png'),
    bannerImg: require('@/assets/images/product/water_banner_img_06.png'),
    sysArchImg: require('@/assets/images/product/water_jiagou_06.svg'),
    sysViews: require('@/assets/images/product/water_views_06.png'),
    functionList: [
      {
        name: '排水设施管理',
        desc: '泵站、阀门、排水渠等排水设施精准化管理、设备健康评估',
        icon: require('@/assets/images/product/icon_function_01.svg'),
      },
      {
        name: '管网模型应用',
        desc:
          '通过与水力模型结合应用，实现污水排水调度管理，充分利用污水厂净化能力，防止污水溢流',
        icon: require('@/assets/images/product/icon_function_02.svg'),
      },
      {
        name: '排污纳管监测',
        desc:
          '管网污水水质、水量实时监测，防止污水偷排漏排，调度水量，防止溢流',
        icon: require('@/assets/images/product/icon_function_03.svg'),
      },
    ],
  });

// 环保详情列表数据
export const envDetailList = new Map()
  .set('dqzl', {
    name: '大气监测',
    desc:
      '针对当前环境监管部门人力不足、环境污染和违法情况无法量化评估的问题，通过大范围布点，结合多种监测技术，对监测数据进行空气质量评估，判断其污染程度，及时发现空气质量变化趋势及异常；针对溯源和执法困难等问题，通过AI机器学习对大气相关数据进行挖掘分析，不断优化污染溯源模型，应用于溯源监管，并与其他部门系统进行信息对接，实现多部门协同共治。',
    bannerBg: require('@/assets/images/banner_bg_product_detail.png'),
    bannerImg: require('@/assets/images/product/env_banner_img_01.png'),
    sysArchImg: require('@/assets/images/product/env_jiagou_01.svg'),
    sysViews: require('@/assets/images/product/env_views_01.png'),
    functionList: [
      {
        name: '实时监测',
        desc: '监测各站点的空气质量',
        icon: require('@/assets/images/product/icon_function_01.svg'),
      },
      {
        name: '视频监控',
        desc: '实时监控现场情况，AI智能识别污染事件，自动拍照取证',
        icon: require('@/assets/images/product/icon_function_02.svg'),
      },
      {
        name: '智能预警',
        desc: '规则自由配置，超标时预警提示',
        icon: require('@/assets/images/product/icon_function_03.svg'),
      },
      {
        name: '决策支持',
        desc: '污染溯源、扩散趋势预测，提供管理决策支持',
        icon: require('@/assets/images/product/icon_function_04.svg'),
      },
    ],
  })
  .set('shj', {
    name: '水环境监测',
    desc:
      '针对目前流域水环境污染严重，监管难的问题，通过建立全面的监测体系，实现对河道流域水质状况、污染物排放等异常状况实时监控预警，并结合流域水环境管理数据库、水动力水质模拟预测技术等，实现水环境质量评价、水质趋势分析、污染源分析、风险源管理、水质模拟预测和突发污染事故预警预报等功能，为水环境综合管理工作提供高效可视化的决策工具',
    bannerBg: require('@/assets/images/banner_bg_product_detail.png'),
    bannerImg: require('@/assets/images/product/env_banner_img_02.png'),
    sysArchImg: require('@/assets/images/product/env_jiagou_02.svg'),
    sysViews: require('@/assets/images/product/env_views_02.png'),
    functionList: [
      {
        name: '水环境质量预测预警',
        desc:
          '通过全面的监测设备，对流域水质情况，污染物排放情况，倾倒垃圾等异常行为进行实时监测报警',
        icon: require('@/assets/images/product/icon_function_01.svg'),
      },
      {
        name: '水质模拟预测',
        desc:
          '通过水环境数据库，水质模拟预测等技术，实现水质趋势分析、污染源分析、日常水质模拟预测和突发污染事故预警预报等功能',
        icon: require('@/assets/images/product/icon_function_02.svg'),
      },
      {
        name: '遥感监测',
        desc: '通过遥感监测技术，对流域、湖库污染情况及营养状况进行监测预警',
        icon: require('@/assets/images/product/icon_function_03.svg'),
      },
    ],
  })
  .set('dlnh', {
    name: '电量监测',
    desc:
      '通过采集工业排污企业生产设施和治污设施的电流和电压值，将排污企业生产治污情况置于生态环境主管部门远程监管下，精确打击排污企业治污设施运行异常、生产设施违规开启等情况，为生态环境主管部门日常监管和重污染天气应急差异化管控提供参考，实现远程非现场监管',
    bannerBg: require('@/assets/images/banner_bg_product_detail.png'),
    bannerImg: require('@/assets/images/product/env_banner_img_03.png'),
    sysArchImg: require('@/assets/images/product/env_jiagou_03.svg'),
    sysViews: require('@/assets/images/product/env_views_03.png'),
    functionList: [
      {
        name: '数据监测',
        desc: '实时监测企业生产、治污设备工况数据',
        icon: require('@/assets/images/product/icon_function_01.svg'),
      },
      {
        name: '异常预警',
        desc: '关联分析、超限分析、启停时间分析，及时发现异常情况',
        icon: require('@/assets/images/product/icon_function_02.svg'),
      },
      {
        name: '统计分析',
        desc: '设备状态、点位运行情况、企业用电数据、企业运行状态进行综合分析',
        icon: require('@/assets/images/product/icon_function_03.svg'),
      },
    ],
  })
  .set('wqpf', {
    name: '尾气监测',
    desc:
      '通过实时数据联网建立机动车污染数据分析及环境执法取证平台，实现城区高排污车辆快速筛查，重点区域高污染车限行管理，减少城区高污染车机动车排放废气，并实时监控城市道路机动车污染排放整体状况，分析机动车污染对空气质量的影响，最终为国家及各省市政府应对机动车污染提供精准化管理措施，从而改善城市空气质量。',
    bannerBg: require('@/assets/images/banner_bg_product_detail.png'),
    bannerImg: require('@/assets/images/product/env_banner_img_04.png'),
    sysArchImg: require('@/assets/images/product/env_jiagou_04.svg'),
    sysViews: require('@/assets/images/product/env_views_04.png'),
    functionList: [
      {
        name: '实时监控',
        desc:
          '实时监控所有站点尾气监测参数数据信息、车辆基本信息、途经车量图像、环境空气质量数据',
        icon: require('@/assets/images/product/icon_function_01.svg'),
      },
      {
        name: '综合查询',
        desc: '多维度数据查询，为数据对比、分析、导出提供方便',
        icon: require('@/assets/images/product/icon_function_02.svg'),
      },
      {
        name: '统计分析',
        desc:
          '车流量/合格/不合格信息统计、AQI与车流量对比、尾气排放物与污染物对比、车辆信息比重、监测有效率统计、分时段对比',
        icon: require('@/assets/images/product/icon_function_03.svg'),
      },
    ],
  })
  .set('wgh', {
    name: '网格化监管',
    desc:
      '针对环境污染因素多样，污染源种类多、总量大，污染问题责任主体和监管部门不明晰，难以统一监管和调度指挥等问题，通过网格划分，明确网格职责，组建网格队伍，规范管理制度等，及时为各局办环保措施的改善提供依据，实现环境监管区域全覆盖，污染源全掌握、工作情况全留痕、绩效考核全量化，打造共建共治共享的环境治理新格局。',
    bannerBg: require('@/assets/images/banner_bg_product_detail.png'),
    bannerImg: require('@/assets/images/product/env_banner_img_05.png'),
    sysArchImg: require('@/assets/images/product/env_jiagou_05.svg'),
    sysViews: require('@/assets/images/product/env_views_05.png'),
    functionList: [
      {
        name: 'GIS工作台',
        desc: '基于GIS展示监管对象，可快速定位、关联分析及处理',
        icon: require('@/assets/images/product/icon_function_01.svg'),
      },
      {
        name: '任务和事件管理',
        desc: '事件处理、任务管理和巡查管理',
        icon: require('@/assets/images/product/icon_function_02.svg'),
      },
      {
        name: '统计分析',
        desc: '基于对各类问题、事件、任务、人员等进行统计和分析',
        icon: require('@/assets/images/product/icon_function_03.svg'),
      },
    ],
  })
  .set('wfgl', {
    name: '危废管理',
    desc:
      '危险废物全过程监管平台，利用物联感知、视频识别、GPS跟踪、二维码溯源、大数据分析等技术，对危废从产生到处置的全流程进行有效监管，解决数据不透明、现场难监管、风险未预知，应急无联动四大问题，满足城市危废的监管、决策需求。',
    bannerBg: require('@/assets/images/banner_bg_product_detail.png'),
    bannerImg: require('@/assets/images/product/env_banner_img_06.png'),
    sysArchImg: require('@/assets/images/product/env_jiagou_06.svg'),
    sysViews: require('@/assets/images/product/env_views_06.png'),
    functionList: [
      {
        name: '危险废物产生源管理',
        desc: '对危废产生源进行追溯和监管',
        icon: require('@/assets/images/product/icon_function_01.svg'),
      },
      {
        name: '危险废物转移管理',
        desc: '对危废转移的处理和运输进行监控',
        icon: require('@/assets/images/product/icon_function_02.svg'),
      },
      {
        name: '危险废物处置管理',
        desc: '对危废处置进行全流程、多维度监管',
        icon: require('@/assets/images/product/icon_function_03.svg'),
      },
    ],
  })
  .set('default', {
    name: '大气监测平台',
    desc:
      '基于大气环境监测站仪器监测数据，融合常规监测、气象、遥感等多源数据和大气复合污染多维环境模型算法，为颗粒物组分分析、光化学污染分析和污染传输分析等提供专业分析和可视化工具，实现大气数据价值利用最大化，有效支撑污染特征和成因分析，为环境管理决策、治理成效评估、重大活动保障提供重要支撑。',
    bannerBg: require('@/assets/images/banner_bg_product_detail.png'),
    bannerImg: require('@/assets/images/product/env_banner_img_01.png'),
    sysArchImg: require('@/assets/images/product/env_jiagou_01.svg'),
    sysViews: require('@/assets/images/product/env_views_01.png'),
    functionList: [
      {
        name: '微型监测',
        desc: '微型站监测统计大气质量数据。',
        icon: require('@/assets/images/product/icon_function_01.svg'),
      },
      {
        name: '扬尘监测',
        desc: '智能识别监控的扬尘状况。',
        icon: require('@/assets/images/product/icon_function_02.svg'),
      },
      {
        name: '超标预警',
        desc: '指标预警联动，辅助决策调度。',
        icon: require('@/assets/images/product/icon_function_03.svg'),
      },
    ],
  });
// 园区详情数据
export const parkDetailList = new Map().set('default', {
  name: '智慧水厂',
  desc:
    '建立化工园区立体感知网，全覆盖安全生产、环境管理、应急管理、封闭园区、能源管理等环节，为数据挖掘和决策应用提供快速、精准、全面的感知数据，提供执法、监管、规划、调控不同业务的量化数据抓手。提升园区品牌形象。',
  // bannerBg: require('@/assets/images/banner_bg_product_detail.png'),
  // banneImg: require('@/assets/images/product/water_banner_img_01.png'),
  sysArchImg: require('@/assets/images/product/env_jiagou_01.svg'),
  sysViews: require('@/assets/images/product/park_views_01.png'),
  functionList: [
    {
      name: '安全生产',
      desc: '建立风险评级，一源一档，实时隐患排查。',
      icon: require('@/assets/images/product/icon_function_01.svg'),
    },
    {
      name: '封闭园区',
      desc: '全域人车监控，完整企业申报信息。',
      icon: require('@/assets/images/product/icon_function_02.svg'),
    },
    {
      name: '应急管理',
      desc: '智能预警预报，模拟演练，辅助调度决策。',
      icon: require('@/assets/images/product/icon_function_03.svg'),
    },
  ],
});
