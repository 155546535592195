<template>
  <div class="solution-nav-box solution" @mouseleave="hideOnMouseLeave">
    <div class="solution-tabs">
      <div
        v-for="(item, index) in tabs"
        :key="index"
        :class="['item', activeIndex == index ? 'active' : '']"
        @click="changeTabIndex(item)"
        @mousemove="enter(index)"
        @mouseleave="leave"
      >
        <div class="icon">
          <img :src="activeIndex == index ? item.active : item.icon" alt="" />
        </div>
        <div class="info">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
const shortid = require('shortid');
export default {
  name: 'SolutionDropDownBox',
  data() {
    return {
      activeNav: null,
      isVisible: false,
      tabs: [
        {
          title: '智慧零碳',
          destination: '/wisdom-zero-carbon-product',
          id: shortid.generate(),
          icon: require('@/assets/images/navbar/smart_zero_carbon.svg'),
          active: require('@/assets/images/navbar/smart_zero_carbon_active.svg'),
        },
        {
          title: '智慧水务',
          destination: '/wisdom-water-product',
          id: shortid.generate(),
          icon: require('@/assets/images/navbar/smart_water.svg'),
          active: require('@/assets/images/navbar/smart_water_active.svg'),
        },
      ],
      activeIndex: null,
    };
  },
  mounted() {},
  methods: {
    hideOnMouseLeave() {
      this.$emit('hideProductNavBox', this.isVisible);
    },
    changeTabIndex(nav) {
      const currPath = window.location.pathname.split('&')[0];
      if (currPath !== nav.destination) {
        window.location.href = nav.destination;
      }
    },
    enter(index) {
      this.activeIndex = index;
    },
    leave() {
      this.activeIndex = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.solution-nav-box {
  position: absolute;
  right: calc((100% - 1200px) / 2 + 110px);
  overflow: hidden;

  width: 380px;
  height: 134px;
  background: #f5f8fa;
  box-shadow: 0px 5px 20px 0px rgba(188, 188, 188, 0.5);
  .solution-tabs {
    display: flex;
    justify-content: center;
    margin-top: 14px;
    .item {
      width: 100px;
      height: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      &:nth-child(2) {
        margin-left: 40px;
      }
      .icon {
        width: 48px;
        height: 48px;
        margin: 14px 26px 10px;
      }
      .info {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #343434;
        line-height: 14px;
      }
    }
    .active {
      .info {
        color: #2750d6;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .solution {
    position: absolute;
    top: 72px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
}
</style>
