var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scheme-introduction",style:(("background:url(" + (_vm.introduction.bg) + ") no-repeat center center;background-size: cover;")),attrs:{"id":"scheme-introduction"}},[_c('div',{staticClass:"content introduction"},[_c('div',{staticClass:"left-introduce",attrs:{"data-aos":"fade-in","data-aos-delay":"100"}},[_c('div',{staticClass:"line"}),_c('div',{staticClass:"title"},[_vm._v("方案介绍")]),_c('div',{staticClass:"label"},[_vm._v("业务痛点")]),_c('div',{staticClass:"point-list"},_vm._l((_vm.introduction.importantPoint),function(item,index){return _c('div',{key:index,staticClass:"item"},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',{staticClass:"label"},[_vm._v("方案描述")]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.introduction.description))])]),_c('div',{staticClass:"right-features",attrs:{"data-aos":"fade-in","data-aos-delay":"400"}},[_c('div',{staticClass:"features-list"},[_vm._l((_vm.introduction.featuresList),function(item,index){return _c('div',{key:index,class:[
            'item',
            { bottom: _vm.introduction.isSpecial && index >= 2 },
            { top: _vm.introduction.isSpecial && index < 2 } ],style:(("width:" + (_vm.introduction.featuresList.length == 3 &&
              index == _vm.introduction.featuresList.length - 1
                ? '100%'
                : '280px') + ";padding-top:" + (_vm.introduction.featuresList.length == 3 &&
              index == _vm.introduction.featuresList.length - 1
                ? '96px'
                : index >= 2
                ? '75px'
                : '20px')))},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"desc",style:(("width:" + (_vm.introduction.featuresList.length == 3 &&
                index == _vm.introduction.featuresList.length - 1
                  ? '522px'
                  : '160px')))},[_vm._v(" "+_vm._s(item.desc)+" ")])])}),_c('div',{staticClass:"animate-box"},[_c('div',{staticClass:"circle-bg",style:(("background-image: url(" + _vm.circleBg + ")"))}),_c('div',{staticClass:"animate-img",style:(("background-image: url(" + (_vm.introduction.featuresList.length == 3
                  ? _vm.animateImg1
                  : _vm.animateImg2) + ")"))})])],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }