/**
 * Description: 新闻相关的接口.
 * Created Date: 2021-01-18 2:54:23 PM
 * Author: Liu Man <lm@hcttop.com>
 * -----
 * Last Modified: 2021-01-19, 10:08:09 AM
 * Modified By: Liu Man <lm@hcttop.com>
 */

import http from '@/http';

// 分页查询新闻列表
const getNewsListUrl = '/article/officialWebsiteFrontPage';
// 根据id查询新闻详情
const getNewsDetailUrl = '/article/detail';
// 查询置顶新闻
const getTopArticleUrl = '/article/listTopArticle';

// 分页查询新闻列表
const getNewsList = data => {
  return http({
    url: getNewsListUrl,
    method: 'post',
    data,
  });
};
// 根据id查询新闻详情
const getNewsDetail = params => {
  return http({
    url: getNewsDetailUrl,
    method: 'get',
    params,
  });
};
// 查询置顶新闻
const getTopArticle = data => {
  return http({
    url: getTopArticleUrl,
    method: 'post',
    data,
  });
};
export { getNewsList, getNewsDetail, getTopArticle };
