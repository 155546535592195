<!--
 * Description: 标杆案例-banner模块.
 * Created Date: 2021-01-20 11:00:36 AM
 * Author: Liu Man <lm@hcttop.com>
 * -----
 * Last Modified: 2021-01-20, 11:00:36 AM
 * Modified By: Liu Man <lm@hcttop.com>
 -->

<template>
  <div class="banner">
    <div class="content">
      <div class="title" data-aos="fade-down" data-aos-delay="100">
        产品案例
      </div>
      <div class="line" data-aos="fade-in" data-aos-delay="300"></div>
      <div class="title-en" data-aos="fade-up" data-aos-delay="100">
        <!-- TYPICAL CASE -->
        Product Case
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.banner {
  height: 500px;
  background: url('~@/assets/images/banner_bg_typical.png') center no-repeat;
  background-size: cover;
  .content {
    width: 1440px;
    margin: 0 auto;
    color: #343434;
    padding-top: 221px;
    .title {
      font-size: 42px;
    }
    .line {
      width: 40px;
      height: 1px;
      margin: 30px 0;
      background: #343434;
      border-radius: 2px;
    }
    .title-en {
      font-size: 24px;
    }
  }
}
</style>
