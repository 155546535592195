<template>
  <div class="program-advantage">
    <div :class="['title', info.titleStyle]">
      {{ info.title }}
    </div>
    <div class="list-box">
      <div
        :class="['item', item.more ? 'width1' : 'width2']"
        v-for="(item, index) in filterList"
        :key="index"
        data-aos="fade-in"
        :data-aos-delay="index <= 2 ? '400' : '600'"
      >
        <div class="icon">
          <img :src="item.icon" alt="" />
        </div>
        <div class="info">
          <div class="name">{{ item.name }}</div>
          <div class="desc">{{ item.desc }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgramAdvantageComp',
  props: {
    info: {
      type: Object,
      default: () => ({
        title: '',
        titleStyle: '',
        icons: [],
        infoList: [],
      }),
    },
  },
  data() {
    return {};
  },
  computed: {
    filterList() {
      const { infoList, icons } = this.info;
      return Array.isArray(infoList) &&
        infoList.length &&
        Array.isArray(icons) &&
        icons.length
        ? infoList.map((e, i) => ({
            ...e,
            icon: require(`@/assets/images/solution/zero-carbon-gov/program_advantages_0${icons[i]}.svg`),
            more: icons.length > 3,
          }))
        : this.info;
    },
  },
};
</script>

<style lang="scss" scoped>
.program-advantage {
  width: 1200px;
  height: 680px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  .title {
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #1c1c1c;
    line-height: 32px;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 140px;
  }
  .title-80-100 {
    margin-bottom: 100px;
  }
  .title-80-40 {
    margin-bottom: 40px;
  }
  .list-box {
    display: flex;
    justify-content: space-between;
    .item {
      text-align: center;
      .icon {
        height: 120px;
        margin-bottom: 50px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .info {
        .name {
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #283780;
          line-height: 33px;
          letter-spacing: 2px;
          margin-bottom: 6px;
          white-space: nowrap;
        }
        .desc {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #49525c;
          line-height: 28px;
          letter-spacing: 1px;
        }
      }
    }
    .width1 {
      width: 250px;
    }
    .width2 {
      width: 336px;
    }
  }
}
</style>
