<!--
 * Description: 新闻中心-banner模块.
 * Created Date: 2021-01-20 11:00:36 AM
 * Author: Liu Man <lm@hcttop.com>
 * -----
 * Last Modified: 2021-01-20, 2:14:44 PM
 * Modified By: Liu Man <lm@hcttop.com>
 -->

<template>
  <div class="banner">
    <div class="banner-bg">
      <div class="banner-content">
        <div class="title" data-aos="fade-down" data-aos-delay="100">
          新闻中心
        </div>
        <div class="line" data-aos="fade-in" data-aos-delay="300"></div>
        <div class="title-en" data-aos="fade-up" data-aos-delay="100">
          News Center
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.banner {
  min-width: 1200px;
  height: 500px;
  .banner-bg {
    width: 100%;
    height: 100%;
    background: #f0f0f4 url('~@/assets/images/banner_bg_news.png') center
      no-repeat;
    background-size: 1920px 500px;
    .banner-content {
      width: 1200px;
      margin: 0 auto;
      color: #343434;
      padding-top: 221px;
      .title {
        font-size: 42px;
      }
      .line {
        width: 40px;
        height: 1px;
        margin: 30px 0;
        background: #343434;
        border-radius: 2px;
      }
      .title-en {
        font-size: 24px;
      }
    }
  }
}
</style>
