<!--
 * Description: 典型案例-案例模块.
 * Created Date: 2021-01-20 11:35:50 AM
 * Author: Liu Man <lm@hcttop.com>
 * -----
 * Last Modified: 2021-01-20, 11:35:50 AM
 * Modified By: Liu Man <lm@hcttop.com>
 -->

<template>
  <!-- <div class="cases cases-bg section"> -->
  <div class="cases section">
    <!-- <div class="section-container">
      <div class="section-title">
        <div class="title">{{ getTitle() }}</div>
        <div class="progress-box">
          <span class="number">
            <span class="current">{{ formatData(currentSwiperIndex + 1) }}</span
            >/<span class="total">{{ formatData(caseArr.length) }}</span>
          </span>
        </div>
      </div>
      <div class="swiper-box">
        <swiper
          class="hc-swiper-container"
          :options="swiperOption"
          :style="{ marginLeft: `${swiperLeft}px` }"
          ref="mySwiper"
          @slideChangeTransitionStart="transChange(getCurrentSwiperIndex)"
        >
          <swiper-slide v-for="(item, index) in caseArr" :key="index">
            <div class="case-item">
              <div class="case-img">
                <img
                  :src="require(`@/assets/images/cases/${item.imgName}.png`)"
                  :alt="item.name"
                />
              </div>
              <div class="case-content">
                <div class="desc">
                  <div class="content-title">案例简介</div>
                  <div class="content-desc">
                    {{ item.content }}
                  </div>
                </div>
                <div class="content-box">
                  <div class="content-item problem">
                    <div class="content-title">甲方痛点</div>
                    <div class="problem-box">
                      <div
                        class="problem-item"
                        v-for="(itemP, indexP) in item.problem"
                        :key="indexP"
                      >
                        <div class="icon el-icon-question"></div>
                        <div class="text">{{ itemP }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="content-item">
                    <div class="content-title">虹川解决方案</div>
                    <div>{{ item.solution }}</div>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div> -->
    <div class="cases-container">
      <div
        class="case-item"
        v-for="(item, index) in caseArr"
        :key="index"
        data-aos="fade-up"
        data-aos-delay="400"
      >
        <div class="case-img">
          <img
            :src="require(`@/assets/images/cases/${item.imgName}.png`)"
            :alt="item.name"
          />
        </div>
        <div class="case-content">
          <div class="case-title">{{ item.name }}</div>
          <div class="case-desc">
            <div class="desc-title">案例简介</div>
            <div>{{ item.content }}</div>
          </div>
          <div class="case-other">
            <div class="other-item problem-box">
              <div class="other-title">甲方痛点</div>
              <div
                class="problem-item"
                v-for="(itemP, indexP) in item.problem"
                :key="indexP"
              >
                <div class="icon">
                  <img
                    :src="require(`@/assets/images/typical/icon_problem.svg`)"
                    :alt="item.name"
                  />
                </div>

                <div class="text">{{ itemP }}</div>
              </div>
            </div>
            <div class="other-item solution-box">
              <div class="other-title">解决方案</div>
              <div>{{ item.solution }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { caseListData } from '@/assets/data/casesInfo.js';

export default {
  name: '',
  data() {
    return {
      currentSwiperIndex: 0,
      caseListData,
      caseArr: [],
      // caseArr: [
      //   {
      //     name: '深圳某区智慧水务综合调度平台建设项目',
      //     content:
      //       '全网综合调度面向水司调度中心管理者和基层员工的应用系统，它用于接入、展示和管理水司给排水全流程的各类关键数据，并下发指令完成日常的管理指挥工作。',
      //     imgName: 'img_case_szmqzhsw',
      //     type: 'env',
      //     isBenchmark: true,
      //     problem: [
      //       '无法对给排水全流程进行统一监测',
      //       '调度指令没有数字化，调度效果无法评估，调度经验难沉淀',
      //     ],
      //     solution:
      //       '平台从实际工作场景出发，打造行政区分公司信息总览、供排水监控、直饮水保障、河道治理、应急指挥、防洪排涝等主题，为行政区分公司给排水调度指挥提供总览全局、决策指挥的条件，为各业务部门提供协同作战、高效处置的作战平台。',
      //   },
      //   {
      //     name: '山东某市智慧环保综合监管平台建设项目',
      //     content:
      //       '为确保空气质量改善目标，按照国家生态环境建设指示精神，结合市实际情况，以提高监测能力、消除监管盲区、明确考核责任、分解传递压力为目标，重点围绕亟待解决的大气环境污染问题，在前期调研考察外地市基础上，先行建设大气综合监管平台。',
      //     imgName: 'img_case_zzzhhb',
      //     type: 'env',
      //     isBenchmark: true,
      //     problem: [
      //       '污染源存在属地不清、行业不清、业主不清的情况，污染问题责任主体和监管部门不明晰，难以管理',
      //       '环境监管“点多、面广、人手不足”的矛盾日益突出，污染问题不能及时发现、尽早处置',
      //     ],
      //     solution:
      //       '通过建立大气综合监管平台、网格化环境监管平台，实现用数据说话、用数据管理，把实时的数据监测、预警的问题处理和网格化管理相结合，以此为依据推动精准治污手段，有效提高环境管理能力。',
      //   },
      //   {
      //     name: '青岛某园区建设项目',
      //     content:
      //       '围绕“蓝色、高端、新兴”的产业定位，以突出发展科技服务业为支撑，加快壮大软件与信息技术、高端智能制造、蓝色生物医药、海工装备制造、节能与新材料五大支柱产业，形成特色鲜明的“1+5”产业体系。',
      //     imgName: 'img_case_qdhqzhyq',
      //     type: 'env',
      //     isBenchmark: true,
      //     problem: [
      //       '风险源与重大危险源多，难以全面掌控',
      //       '各部门管理数据不共享，跨业务管理困难',
      //     ],
      //     solution:
      //       '“人防+技防”双管齐下，构建“自动化、智能化、立体化”，建立“点、面、区”立体监测的园区监测监控体系，以园区考核评价体系、园区安全评估体系、园区环境质量评价为目标，建设园区安环一体化管理信息化平台。',
      //   },
      // ],
      // swiper距离左边的距离 动态计算
      swiperLeft: 0,
      swiperOption: {
        slidesPerView: 'auto',
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        // slider之间的间隙
        // spaceBetween: 110,
        // 循环方式：无限循环
        loop: true,
        // effect: 'fade',
        // 自动滚动
        // autoplay: {
        //   delay: 5000,
        //   disableOnInteraction: false,
        // },
      },
    };
  },
  created() {
    this.initData();
  },
  computed: {
    getCurrentSwiperIndex() {
      return this.$refs.mySwiper.swiper.realIndex;
    },
  },
  mounted() {
    this.setSwiperLeft();
    // 窗口变化 实时获取swiper距离左边的距离
    window.onresize = () => {
      this.setSwiperLeft();
    };
  },
  methods: {
    initData() {
      this.caseArr = this.getCaseList(this.caseListData);
    },
    /** 设置标杆案例数据 */
    getCaseList(array) {
      const result = array.filter(caseInfo => {
        return caseInfo.isBenchmark;
      });
      return result;
    },
    /** 格式化数字：2位 */
    formatData(num) {
      let result = num;
      if (num < 10) {
        result = `0${num}`;
      }
      return result;
    },
    /** swiper切换：设置当前index */
    transChange(index) {
      this.currentSwiperIndex = index;
    },
    /** 获取swiper距离左边的距离 */
    setSwiperLeft() {
      // 不带滚动条的窗口宽度
      const scrollWidth = document.documentElement.scrollWidth;
      // 带滚动条的窗口宽度
      // const innerWidth = window.innerWidth;
      const contentWith = 1440;
      // swiper距离左边的距离
      this.swiperLeft = (scrollWidth - contentWith) / 2;
    },
    /** 项目标题 */
    getTitle() {
      return this.caseArr[this.currentSwiperIndex].name;
    },
  },
};
</script>

<style lang="less" scoped>
@import '~@/assets/css/global';
.cases {
}
.cases-bg {
  height: 720px;
  background: url('~@/assets/images/typical/typical_bg_1.png') center no-repeat;
  background-size: cover;
}
.section-container {
  padding-top: @section-title-top;
  height: 100%;
  .section-title {
    display: flex;
    justify-content: space-between;
    width: 1440px;
    margin: 0 auto;
    .title {
      font-size: 28px;
      color: #000000;
    }
    .progress-box {
      width: 112px;
      border-bottom: 1px solid #343434;
      font-size: 14px;
      color: #343434;
      line-height: 19px;
      .number {
        display: inline-block;
        height: 100%;
        border-bottom: 3px solid #2439b2;
      }
      .current {
        color: #000000;
        margin-right: 14px;
      }
      .total {
        margin-left: 6px;
      }
    }
  }
  .swiper-box {
    height: 480px;
    margin-top: 91px;
    .hc-swiper-container {
      height: 100%;
      .swiper-slide {
        width: 80%;
        height: 420px;
        transform: scale(0.8);
        background: #ffffff;
      }
      .swiper-slide-active {
        transform: scale(1);
        transition: all 0.5s ease-in-out;
        position: relative;
        z-index: 5;
        &::after {
          content: '';
          width: 99%;
          height: 30px;
          background: linear-gradient(
            270deg,
            rgba(0, 0, 0, 0.05) 0%,
            rgba(0, 0, 0, 0.35) 49%,
            rgba(0, 0, 0, 0.05) 100%
          );
          filter: blur(10px);
          opacity: 0.7;
          position: absolute;
          bottom: -40px;
          left: 0;
          transform: translateX(90deg);
          z-index: 1;
        }
      }
      // .swiper-slide:nth-child(2n) {
      //   width: 60%;
      // }
      // .swiper-slide:nth-child(3n) {
      //   width: 40%;
      // }
    }
    .case-item {
      display: flex;
      align-items: center;

      .case-img {
        width: 660px;
        height: 420px;
        overflow: hidden;
        img {
          display: block;
          width: 660px;
          height: 420px;
          transition: all 0.5s ease-in-out;
        }
      }
      &:hover {
        .case-img {
          overflow: hidden;
          img {
            transform: scale(1.1);
          }
        }
      }
      .case-content {
        // width: 560px;
        flex: 1;
        padding: 0 41px 0 46px;
        .desc {
          font-size: 12px;
          color: #343434;
          line-height: 20px;
          margin-bottom: 60px;
        }
        .content-box {
          display: flex;
          .content-item {
            font-size: 12px;
            color: #343434;
            line-height: 20px;
            flex: 1;
            text-align: justify;
            &:first-child {
              margin-right: 20px;
            }

            &.problem {
              width: 237px;
              .problem-box {
                .problem-item {
                  display: flex;
                  align-items: baseline;

                  .icon {
                    width: 12px;
                    height: 12px;
                    margin-right: 10px;
                  }
                }
              }
            }
          }
        }
        .content-title {
          font-size: 16px;
          color: #000000;
          margin-bottom: 20px;
          font-weight: bold;
        }
        .content-desc {
          text-align: justify;
        }
      }
    }
  }
}
.cases-container {
  width: 1440px;
  margin: 0 auto;
  .case-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 0 59px;
    border-bottom: 1px solid #f0f1f5;
    line-height: 20px;
    .case-img {
      width: 660px;
      height: 420px;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        height: 100%;
        transition: all 0.5s ease-in-out;
      }
    }
    &:hover {
      .case-img {
        overflow: hidden;
        img {
          transform: scale(1.1);
        }
      }
    }

    .case-content {
      width: 734px;
      text-align: justify;
      color: #343434;
      font-size: 12px;
      .case-title {
        font-size: 28px;
        color: #000000;
        margin-bottom: 60px;
      }
      .desc-title {
        font-size: 16px;
        color: #000000;
        margin-bottom: 20px;
        font-weight: bold;
      }
      .case-other {
        margin-top: 60px;
        display: flex;
        justify-content: space-between;
        .other-item {
          .other-title {
            font-weight: bold;
            font-size: 16px;
            color: #000000;
            position: relative;
            z-index: 5;
            display: inline-block;
            margin-bottom: 19px;
            &::before {
              content: '';
              width: 100%;
              height: 8px;
              background: rgba(36, 57, 178, 0.25);
              position: absolute;
              left: 0;
              bottom: 0;
            }
          }
        }

        .problem-item {
          display: flex;
          align-items: baseline;
          margin-bottom: 20px;
          &:last-child {
            margin-bottom: 0;
          }
          .icon {
            width: 13px;
            height: 12px;
            img {
              display: block;
              width: 100%;
              height: 100%;
              margin-top: 2px;
            }
          }
          .text {
            width: 320px;
            margin-left: 10px;
          }
        }
        .solution-box {
          width: 360px;
        }
      }
    }
  }
}
</style>
