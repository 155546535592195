<!--
 * Description: 新闻详情.
 * Created Date: 2021-01-21 4:58:08 PM
 * Author: Liu Man <lm@hcttop.com>
 * -----
 * Last Modified: 2021-03-18, 11:00:36 AM
 * Modified By: Liu Man <lm@hcttop.com>
 -->

<template>
  <div class="news-container">
    <banner-news></banner-news>
    <div class="container">
      <div style="flex: 1"></div>
      <div style="display: flex">
        <div class="news-box">
          <div :class="['news-logo', logoFixed]" ref="logoBox">
            <img :src="require('@/assets/images/icon-news-logo.png')" alt="" />
          </div>
          <div class="new-title">
            <h1>{{ newsDetail.title }}</h1>
            <div class="news-info">
              <div class="info-item">
                <svg-icon icon-class="icon-source" class="icon-size"></svg-icon>
                <div class="">来源：{{ newsDetail.source }}</div>
              </div>
              <div class="info-item">
                <svg-icon icon-class="icon-views"></svg-icon>
                <div class="">
                  浏览量：{{
                    getViewCount(
                      newsDetail.baseViewCount,
                      newsDetail.realViewCount
                    )
                  }}
                </div>
              </div>
              <div class="info-item">
                <svg-icon icon-class="icon-time" class="icon-size"></svg-icon>
                <div class="">发布时间：{{ newsDetail.publishTime }}</div>
              </div>
            </div>
          </div>
          <div class="news-content" ref="container">
            <div class="top">
              <div
                class="content-box ql-editor"
                v-html="newsDetail.htmlContent"
              ></div>

              <div
                class="swipers-container"
                v-if="newsDetail.pictureList && newsDetail.pictureList.length"
              >
                <imagePreview :imageList="newsDetail.pictureList">
                </imagePreview>
              </div>
            </div>
          </div>
          <!-- <div class="back-box">
        <div class="back" @click="goBack">返回列表</div>
      </div> -->
        </div>
        <div class="news-recommend">
          <div
            :class="['other-news', otherFixed]"
            :style="{ left: fixedLeft, top: fixedTop }"
          >
            <p class="other-title">其它新闻：</p>
            <template v-for="v in newsList">
              <div
                class="other-news-item"
                v-if="v.id != id"
                :key="v.id"
                @click="queryNewsDetailById(v.id)"
              >
                {{ v.title }}
              </div>
            </template>
          </div>
        </div>
      </div>
      <div style="flex: 1" ref="right"></div>
    </div>
  </div>
</template>

<script>
import BannerNews from '../news/components/BannerNews.vue';
import { getImgUrl, getViewCount } from '../news/common';
import { getNewsDetail, getNewsList } from '@/api/news.js';
import imagePreview from './components/imagePreview';
export default {
  components: { BannerNews, imagePreview },
  name: 'NewsDetail',
  data() {
    return {
      getImgUrl,
      getViewCount,
      newsDetail: {},
      newsContent: {
        title: '新晋品牌抢市场，消费者变得更挑剔，潮玩欲成主流该怎么做？',
        source: 'ZAKER',
        realViewCount: '2',
        baseViewCount: '100',
        publishTime: '2021-01-03',
        htmlContent:
          '<p>继“盲盒第一股”泡泡玛特登陆港交所后，国内潮流玩具市场又迎来新的玩家。近日，潮玩新品牌TOP TOY在广州开设其全球首家旗舰店，开业前3天营业额超108万元，平均客单价高达325元。潮玩从追求流量的业态变成吸引流量的业态，只用了两年时间。随着市场热度不断升级，越来越多的头部零售公司开始入场。但如何让行业从小众真正走向主流，避免成为“昙花一现”的风口，潮玩企业还要进一步读懂年轻人的心。</p>',
      },
      recommendList: [
        {
          title: '跨境物流为什么越来越快了？',
          url: '',
        },
        {
          title: '跨境电商意外爆红，卖家富跨境电商意外爆红，卖家富',
          url: '',
        },
      ],
      newsList: [],
      id: null, // 新闻id
      imgList: [
        {
          id: 1,
          src:
            'https://image.baidu.com/search/detail?ct=503316480&z=undefined&tn=baiduimagedetail&ipn=d&word=%E5%9B%BE%E7%89%87&step_word=&ie=utf-8&in=&cl=2&lm=-1&st=undefined&hd=undefined&latest=undefined&copyright=undefined&cs=2827471950,3102544597&os=1633744961,3966995079&simid=3384595574,325448062&pn=4&rn=1&di=168740&ln=1816&fr=&fmq=1621674317062_R&fm=&ic=undefined&s=undefined&se=&sme=&tab=0&width=undefined&height=undefined&face=undefined&is=0,0&istype=0&ist=&jit=&bdtype=0&spn=0&pi=0&gsm=0&hs=2&objurl=https%3A%2F%2Fgimg2.baidu.com%2Fimage_search%2Fsrc%3Dhttp%253A%252F%252Fi2.w.yun.hjfile.cn%252Fdoc%252F201303%252Fd5547c74-d9ad-4625-bd93-41c2817f1dff_03.jpg%26refer%3Dhttp%253A%252F%252Fi2.w.yun.hjfile.cn%26app%3D2002%26size%3Df9999%2C10000%26q%3Da80%26n%3D0%26g%3D0n%26fmt%3Djpeg%3Fsec%3D1624266316%26t%3D8fdd80978de33a80eb5c29a74faf80e4&rpstart=0&rpnum=0&adpicid=0&force=undefined',
          note: '1a',
        },
        {
          id: 2,
          src:
            'https://image.baidu.com/search/detail?ct=503316480&z=undefined&tn=baiduimagedetail&ipn=d&word=%E5%9B%BE%E7%89%87&step_word=&ie=utf-8&in=&cl=2&lm=-1&st=undefined&hd=undefined&latest=undefined&copyright=undefined&cs=3206689113,2237998950&os=2396428804,3037150785&simid=3393195248,12395907&pn=7&rn=1&di=87010&ln=1816&fr=&fmq=1621674317062_R&fm=&ic=undefined&s=undefined&se=&sme=&tab=0&width=undefined&height=undefined&face=undefined&is=0,0&istype=0&ist=&jit=&bdtype=0&spn=0&pi=0&gsm=0&hs=2&objurl=https%3A%2F%2Fgimg2.baidu.com%2Fimage_search%2Fsrc%3Dhttp%253A%252F%252Fyouimg1.c-ctrip.com%252Ftarget%252Ftg%252F004%252F531%252F381%252F4339f96900344574a0c8ca272a7b8f27.jpg%26refer%3Dhttp%253A%252F%252Fyouimg1.c-ctrip.com%26app%3D2002%26size%3Df9999%2C10000%26q%3Da80%26n%3D0%26g%3D0n%26fmt%3Djpeg%3Fsec%3D1624266316%26t%3Defcedf1636840c304c86badd3d6304e2&rpstart=0&rpnum=0&adpicid=0&force=undefined',
          note: '2123',
        },
        {
          id: 3,
          src:
            'https://image.baidu.com/search/detail?ct=503316480&z=undefined&tn=baiduimagedetail&ipn=d&word=2020%E5%B9%B4%E6%88%90%E7%86%9F%E5%A5%8B%E6%96%97%E5%9B%BE%E7%89%87&step_word=&ie=utf-8&in=&cl=2&lm=-1&st=-1&hd=undefined&latest=undefined&copyright=undefined&cs=4173046435,619913016&os=1191252112,1783017520&simid=0,0&pn=2&rn=1&di=100210&ln=1444&fr=&fmq=1621674401404_R&fm=rs1&ic=undefined&s=undefined&se=&sme=&tab=0&width=undefined&height=undefined&face=undefined&is=0,0&istype=0&ist=&jit=&bdtype=0&spn=0&pi=0&gsm=0&hs=2&oriquery=2020%E5%A5%BD%E5%A5%BD%E7%88%B1%E8%87%AA%E5%B7%B1%E5%9B%BE%E7%89%87&objurl=https%3A%2F%2Fgimg2.baidu.com%2Fimage_search%2Fsrc%3Dhttp%253A%252F%252Finews.gtimg.com%252Fnewsapp_bt%252F0%252F11103994149%252F1000.jpg%26refer%3Dhttp%253A%252F%252Finews.gtimg.com%26app%3D2002%26size%3Df9999%2C10000%26q%3Da80%26n%3D0%26g%3D0n%26fmt%3Djpeg%3Fsec%3D1624266400%26t%3D9c5d2fca37d81bd69f889528e5cc724f&rpstart=0&rpnum=0&adpicid=0&force=undefined',
          note: '2123',
        },
        {
          id: 4,
          src:
            'https://image.baidu.com/search/detail?ct=503316480&z=undefined&tn=baiduimagedetail&ipn=d&word=2020%E5%B9%B4%E6%88%90%E7%86%9F%E5%A5%8B%E6%96%97%E5%9B%BE%E7%89%87&step_word=&ie=utf-8&in=&cl=2&lm=-1&st=-1&hd=undefined&latest=undefined&copyright=undefined&cs=454448312,2919148074&os=2837484395,4107629093&simid=0,0&pn=281&rn=1&di=2200&ln=1444&fr=&fmq=1621674401404_R&fm=rs1&ic=undefined&s=undefined&se=&sme=&tab=0&width=undefined&height=undefined&face=undefined&is=0,0&istype=0&ist=&jit=&bdtype=0&spn=0&pi=0&gsm=f0&hs=2&oriquery=2020%E5%A5%BD%E5%A5%BD%E7%88%B1%E8%87%AA%E5%B7%B1%E5%9B%BE%E7%89%87&objurl=https%3A%2F%2Fgimg2.baidu.com%2Fimage_search%2Fsrc%3Dhttp%253A%252F%252Fwww.gzw.net%252Fuploadfile%252F2020%252F0110%252F20200110041742639.png%26refer%3Dhttp%253A%252F%252Fwww.gzw.net%26app%3D2002%26size%3Df9999%2C10000%26q%3Da80%26n%3D0%26g%3D0n%26fmt%3Djpeg%3Fsec%3D1624266417%26t%3Df32a045c48aad9a55f405d538a6a76a9&rpstart=0&rpnum=0&adpicid=0&force=undefined',
          note: '2123',
        },
      ], // 图片列表
      swiper: null,
      logoFixed: '',
      otherFixed: '',
      fixedLeft: '',
      fixedTop: '',
    };
  },
  methods: {
    goBack() {
      this.$router.push({ path: '/news' });
    },
    queryNewsDetail(params) {
      getNewsDetail(params).then(res => {
        if (res.data.code == 200) {
          this.newsDetail = res.data.data;
        } else {
          // TODO 异常处理
        }
      });
    },
    /** id改变查询内容 */
    queryNewsDetailById(id) {
      if (!id) {
        return;
      }
      if (id == this.$route.query.id) {
        return;
      }

      this.$router.push({ path: '/news/news-detail', query: { id } });
      const params = {
        id,
      };
      this.id = id;
      this.queryNewsDetail(params);
      this.queryNewsData({
        current: 1,
        size: 10,
        type: '',
      });
    },
    /** 请求数据 */
    queryNewsData(params) {
      getNewsList(params).then(res => {
        if (res.data.code == '200') {
          const list = res.data.data.records;
          for (let i = 0; i < list.length; i += 1) {
            list[i].checked = false;
          }
          const news = list.filter(i => i.id != this.id);
          if (news.length >= 5) {
            this.newsList = news.slice(0, 4);
          } else {
            this.newsList = news;
          }
        } else {
          // TODO 异常处理
        }
      });
    },
    addClass() {},
    removeClass() {},
  },
  computed: {
    getTotalCount() {
      return (
        parseInt(this.newsContent.realViewCount || 0) +
        parseInt(this.newsContent.baseViewCount || 0)
      );
    },
  },
  mounted() {
    window.addEventListener('scroll', () => {
      if (window.scrollY >= 560) {
        this.logoFixed = 'logo-fixed';
      } else {
        this.logoFixed = '';
      }

      if (window.scrollY >= 560) {
        this.otherFixed = 'other-fixed';
        // this.fixedTop = `${window.scrollY - 500}px`;
      } else {
        this.otherFixed = '';
      }
      if (window.scrollY >= 590) {
        this.fixedTop = `0`;
      } else {
        this.fixedTop = `0px`;
      }
      // 计算左右2边距离
    });

    window.addEventListener('resize', () => {
      const right = this.$refs.right.clientWidth;
      this.fixedLeft = `${1080 + right}px;`;
    });
  },
  created() {
    const id = this.$route.query.id;
    this.id = id;
    if (!id) {
      return;
    }
    const params = {
      id,
    };
    this.queryNewsDetail(params);
    this.queryNewsData({
      current: 1,
      size: 10,
      type: '',
    });
  },
};
</script>
<style lang="less" scoped>
.news-container {
  min-width: 1200px;
}
.container {
  display: flex;
  min-width: 1200px;
  margin: 0 auto;
  position: relative;
}
.news-box {
  width: 820px;
  margin: 20px 60px 60px 0;
  .news-logo {
    img {
      width: 200px;
    }
    height: 90px;
    line-height: 90px;
    border-bottom: 2px solid #dadada;
    width: 820px;
    z-index: 1;
  }
  .new-title {
    margin-top: 50px;
    h1 {
      font-size: 28px;
      font-weight: 500;
      color: #101010;
      margin: 0;
    }
    .news-info {
      font-size: 14px;
      color: #7b7b7b;
      display: flex;
      padding: 14px 0;
      border-top: 1px dashed #dadada;
      border-bottom: 1px dashed #dadada;
      margin-top: 20px;
      .info-item {
        margin-right: 60px;
        display: flex;
        align-items: center;
        .icon-size {
          width: 18px;
          height: 18px;
        }
        .svg-icon {
          margin: 0 6px 0 0;
        }
      }
    }
  }

  .news-content {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    padding-bottom: 72px;
    border-bottom: 1px solid #dadada;
    min-height: 650px;
    .content-box {
      line-height: 28px;
      height: auto;
      flex: 1;
      padding: 0;
      font-size: 16px;
      ::v-deep {
        p {
          padding: 0;
          margin: 0;
          font-size: 16px;
        }
      }
      ::v-deep img {
        max-width: 100%;
      }
      ::v-deep pre {
        white-space: pre-wrap; /* css3.0 */
        white-space: -moz-pre-wrap; /* Firefox */
        white-space: -pre-wrap; /* Opera 4-6 */
        white-space: -o-pre-wrap; /* Opera 7 */
        word-wrap: break-word; /* Internet Explorer 5.5+ */
      }
    }
    .swipers-container {
      height: calc(380px + 88px + 40px);
    }
  }

  .back-box {
    display: flex;
    justify-content: flex-end;
    .back {
      width: 128px;
      height: 40px;
      line-height: 40px;
      background: #2439b2;
      font-size: 16px;
      color: #ffffff;
      text-align: center;
      margin-top: 74px;
      margin-right: 0;
      cursor: pointer;
    }
  }
}
.news-recommend {
  min-width: 320px;
}
.other-news {
  width: 320px;
  bottom: 152px;
  margin-top: 90px;
  .other-title {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .other-news-item {
    font-size: 16px;
    color: #000000;
    font-family: PingFangSC-Regular, PingFang SC;
    margin: 10px 0;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &:hover {
      text-decoration: underline;
      color: #2439b2;
    }
  }
}
.recommend-item {
  cursor: pointer;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  color: #343434;
  line-height: 40px;

  &:hover {
    color: #2439b2;
    text-decoration: underline;
  }
  &:last-child {
    // margin-top: 20px;
  }
}
.logo-fixed {
  position: fixed;
  top: 0;
  background: #ffffff;
}
.other-fixed {
  position: fixed;
  background: #ffffff;
  top: 90px;

  right: unset;
}
p {
  font-size: 16px;
}
</style>
