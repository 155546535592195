<template>
  <div class="banner-container" :style="{ background: bannerInfo.gradient }">
    <div
      class="banner-info"
      :style="{ backgroundImage: `url(${bannerInfo.bg})` }"
    >
      <div :class="['tabs', headerActive ? 'tabs-fixed' : '']">
        <div class="tab-items">
          <div
            :class="['tab-item', scrollIndex === index ? 'active' : '']"
            v-for="(item, index) in bannerInfo.tabs"
            :key="index"
            @click="click(index)"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div class="content-box" data-aos="fade-in" data-aos-delay="100">
        <div class="title">{{ bannerInfo.title }}</div>
        <div class="desc">{{ bannerInfo.desc }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageBannerComp',
  props: {
    bannerInfo: {
      type: Object,
      default: () => ({
        title: '',
        desc: '',
        tabs: [],
        bg: '',
        gradient: '',
      }),
    },
    scrollIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      headerActive: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.getScroll);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('scroll', this.getScroll);
    });
  },
  methods: {
    click(i) {
      this.currIndex = i;
      this.scrollIntoView();
    },
    getScroll(e) {
      const scrollTop =
        e.target.documentElement.scrollTop || e.target.body.scrollTop;
      this.headerActive = scrollTop >= 417;
      this.$emit('scrollTop', scrollTop);
    },
    scrollIntoView() {
      document.getElementById(`target${this.currIndex}`).scrollIntoView({
        behavior: 'smooth', // 平滑过渡
        block: 'start', // 上边框与视窗顶部平齐。默认值
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-container {
  width: 100%;
  height: 425px;
  background: linear-gradient(to right, #d8e2ee 50%, #ecf5fe 50%);
  .banner-info {
    width: 100%;
    height: 100%;
    background-image: url('~@/assets/images/solution/zero-carbon-gov/solution_banner.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1920px 425px;
    position: relative;

    .tabs {
      width: 100%;
      position: absolute;
      bottom: -30px;
      display: flex;
      .tab-items {
        width: 1200px;
        padding: 0;
        margin: 0 auto;
        display: flex;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
        text-align: center;
        .tab-item {
          width: 400px;
          height: 80px;
          background: #ffffff;
          cursor: pointer;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 80px;
          letter-spacing: 1px;
          &:hover {
            color: #2750d6;
          }
        }
        .active {
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #2750d6;
          transition: transform 0.15s linear;
        }
      }
    }

    .tabs-fixed {
      width: 100%;
      height: 80px;
      background: #ffffff;
      position: fixed;
      top: 0px;
      z-index: 999;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
      transition: transform 0.5s linear;
      .tab-items {
        box-shadow: none;
        .active {
          background: #2750d6;
          color: #ffffff;
          &:hover {
            color: #ffffff;
          }
        }
      }
    }

    .content-box {
      width: 1200px;
      padding: 0;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      .title {
        font-size: 42px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #101010;
        line-height: 32px;
        letter-spacing: 1px;
        margin-top: 115px;
        margin-bottom: 60px;
      }
      .desc {
        width: 500px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #313a44;
        line-height: 24px;
        text-align: justify;
        letter-spacing: 1px;
      }
    }
  }
}
</style>
