<!--
 * Description:典型案例.
 * Created Date: 2021-01-19 11:33:45 AM
 * Author: Liu Man <lm@hcttop.com>
 * -----
 * Last Modified: 2021-01-19, 11:36:00 AM
 * Modified By: Liu Man <lm@hcttop.com>
 -->

<template>
  <div>
    <page-header></page-header>
    <div class="typical">
      <banner-cases></banner-cases>
      <cases-list></cases-list>
      <!-- <services-info></services-info> -->
    </div>
    <page-footer></page-footer>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageFooter from '@/components/PageFooter';
import BannerCases from './components/BannerCases';
import CasesList from './components/CasesList';
// import ServicesInfo from './components/ServicesInfo';

export default {
  name: '',
  components: { PageHeader, PageFooter, BannerCases, CasesList },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.typical {
  min-width: 1480px;
}
</style>
