/**
 * Description: reauired, the file description, reference link, etc.
 * Created Date: 2021-01-27 4:42:26 PM
 * Author: Zhao Boxing <zbx@hcttop.com>
 * -----
 * Last Modified: 2021-01-27, 4:42:26 PM
 * Modified By: Zhao Boxing <zbx@hcttop.com>
 */

import { debounce } from '@/utilities';

// TODO 学习混入(mixins)

export default {
  data() {
    return {
      $_sidebarElm: null,
      $_resizeHandler: null,
    };
  },
  mounted() {
    this.initListener();
  },
  activated() {
    if (!this.$_resizeHandler) {
      // avoid duplication init
      this.initListener();
    }

    // when keep-alive chart activated, auto resize
    this.resize();
  },
  beforeDestroy() {
    this.destroyListener();
  },
  deactivated() {
    this.destroyListener();
  },
  methods: {
    // use $_ for mixins properties
    // https://vuejs.org/v2/style-guide/index.html#Private-property-names-essential
    $_sidebarResizeHandler(e) {
      if (e.propertyName === 'width') {
        this.$_resizeHandler();
      }
    },
    initListener() {
      this.$_resizeHandler = debounce(() => {
        this.resize();
      }, 100);
      window.addEventListener('resize', this.$_resizeHandler);

      //   this.$_sidebarElm = document.getElementsByClassName(
      //     'sidebar-container'
      //   )[0];
      //   this.$_sidebarElm &&
      //     this.$_sidebarElm.addEventListener(
      //       'transitionend',
      //       this.$_sidebarResizeHandler
      //     );
    },
    destroyListener() {
      window.removeEventListener('resize', this.$_resizeHandler);
      this.$_resizeHandler = null;

      //   this.$_sidebarElm &&
      //     this.$_sidebarElm.removeEventListener(
      //       'transitionend',
      //       this.$_sidebarResizeHandler
      //     );
    },
    resize() {
      const { chart } = this;
      if (chart) {
        chart.resize();
      }
    },
  },
};
