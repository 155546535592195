<!--
 * Description: 组件：方案架构
 * Created Date: 2021-01-22 9:02:46 AM
 * Author: Zhao xiaoqin <zxq@hcttop.com>
 * -----
 * Last Modified: 2021-01-22, 3:30:45 PM
 * Modified By: Liu Man <lm@hcttop.com>
 -->

<template>
  <div class="scheme-architecture" id="scheme-architecture">
    <div class="content architecture">
      <div class="title">方案架构</div>
      <div class="architecture-tab">
        <div
          :class="['item', { active: activeIndex == index }]"
          @click="changeTabIndex(index)"
          v-for="(item, index) in tabList"
          :key="index"
        >
          <div class="tab-icon">
            <img :src="item.icon" alt="" />
          </div>
          <div :class="['tab-name']">
            {{ item.name }}
          </div>
        </div>
        <div
          class="line"
          :class="[activeIndex == 0 ? 'line-l' : 'line-r']"
        ></div>
      </div>
      <div class="content architecture-content">
        <img
          class="jiagou"
          v-if="activeIndex == 0"
          :src="tabList[activeIndex].img"
          alt=""
        />
        <img class="yewu" v-else :src="tabList[activeIndex].img" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SchemeArchitecture',
  props: ['archData'],
  data() {
    return {
      activeIndex: 0,
      tabList: [
        // {
        //   name: '顶层架构',
        //   icon: require('@/assets/images/product/img_dcjg.svg'),
        // },
        // {
        //   name: '业务图',
        //   icon: require('@/assets/images/product/img_fatd.svg'),
        // },
      ],
    };
  },
  methods: {
    // tab点击事件
    changeTabIndex(index) {
      if (this.activeIndex !== index) {
        this.activeIndex = index;
      }
    },
    setTabList(data) {
      if (data.jiagouImg) {
        this.tabList.push({
          name: '顶层架构',
          icon: require('@/assets/images/product/img_dcjg.svg'),
          img: data.jiagouImg,
        });
      }
      if (data.yewuImg) {
        this.tabList.push({
          name: '业务图',
          icon: require('@/assets/images/product/img_fatd.svg'),
          img: data.yewuImg,
        });
      }
    },
  },
  watch: {
    archData: {
      handler(nv) {
        this.setTabList(nv);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="less">
@import '../../../assets/css/global';
@import '../../../assets/css/product';
</style>
