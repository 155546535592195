/*
 * @Author: Zhang SiMin
 * @Date: 2021-05-21 16:40:03
 * @LastEditors: Zhang SiMin
 * @LastEditTime: 2021-05-25 11:20:37
 * @Description: edit this file descrition
 */
/**
 * Description: reauired, the file description, reference link, etc.
 * Created Date: 2021-01-27 2:07:15 PM
 * Author: Hu Yongjun <hyj@hcttop.com>
 * -----
 * Last Modified: 2021-02-07, 6:26:30 PM
 * Modified By: Zhao Boxing <zbx@hcttop.com>
 */

import Vue from 'vue';

import 'swiper/dist/css/swiper.min.css';
// import 'swiper/css/swiper.css';
import VueAwesomeSwiper from 'vue-awesome-swiper'; // 轮播
Vue.use(VueAwesomeSwiper /* { default global options } */);
