<!--
 * Description: reauired, the file description, reference link, etc.
 * Created Date: 2021-01-14 3:17:12 PM
 * Author: Zhao xiaoqin <zxq@hcttop.com>
 * -----
 * Last Modified: 2020-06-24, 7:30:23 PM
 * Modified By: Liu Man <lm@hcttop.com>
 -->

<template>
  <!-- <div
    :class="['hc-header-box', { active: headerActive || isEnter }]"
    @mouseenter="changeLogo(true)"
    @mouseleave="changeLogo(false)"
  > -->
  <div class="hc-header-box">
    <div class="hc-header">
      <div class="hc-logo">
        <router-link to="/">
          <img
            src="@/assets/images/logo_nav_en_black.svg"
            alt="hcttop logo"
            title="虹川科技"
          />
          <!-- <img
            src="@/assets/images/logo_nav_en_black.svg"
            alt="hcttop logo"
            title="虹川科技"
            v-if="headerActive || isEnter"
          />
          <img
            src="@/assets/images/logo_nav_en_white.svg"
            alt="hcttop logo"
            title="虹川科技"
            v-else
          /> -->
        </router-link>
      </div>
      <div class="hc-nav" ref="hcNav">
        <!-- :class="[
            'nav-link-wrap',
            nav.name !== productKeyWords ? 'relative-pos' : '',
          ]" -->
        <div
          class="nav-link-wrap"
          v-for="(nav, index) in headerNav"
          :key="index"
          @mouseover="toggle(index)"
          @mouseleave="active = -1"
          :ref="getRefName(index)"
        >
          <!-- 只有一级菜单的：首页 新闻中心 标杆案例 -->
          <router-link
            v-if="nav.direction"
            :exact="index === 0"
            :class="[
              'main-nav-link',
              activeCurNav ? '1' : '2',
              activeCurNav.indexOf(nav.name) > -1 ? 'a-active' : '',
            ]"
            :to="{ path: `${nav.direction}` }"
            >{{ nav.title }}</router-link
          >
          <!-- 有二级菜单的：关于虹川 无href 不能点击 -->
          <!-- <a
            v-else-if="nav.name === aboutKeyWords"
            :exact="index === 0"
            :class="[
              'main-nav-link',
              activeCurNav ? '1' : '2',
              nav.name.indexOf(activeCurNav) > -1 ? 'a-active' : '',
            ]"
            >{{ nav.title }}</a
          > -->
          <!-- 有二级菜单的：解决方案 无href 不能点击 -->
          <a
            v-else
            :exact="index === 0"
            :class="[
              'main-nav-link',
              activeCurNav ? '1' : '2',
              activeCurNav.indexOf(nav.name) > -1 ? 'a-active' : '',
            ]"
            >{{ nav.title }}</a
          >
          <!-- 关于虹川的下拉菜单 -->
          <!-- <CollapseTransition>
            <drop-down-menu
              v-show="active === index && nav.name === aboutKeyWords"
              :menu-items="aboutMenuItems"
              @hideOnClick="hideOnClick"
              :drop-offset-left="aboutDropOffsetLeft"
              :nav-width="navWidth"
              :nav-offset-left="navOffsetLeft"
            ></drop-down-menu>
          </CollapseTransition> -->

          <!-- 解决方案下拉菜单 -->
          <!-- <transition name="fade"> -->
          <CollapseTransition>
            <solution-navigation-box
              v-show="active === 2 && nav.name === solutionKeyWords"
            ></solution-navigation-box>
          </CollapseTransition>
          <!-- 产品中心下拉菜单 -->
          <CollapseTransition>
            <product-navigation-box
              :currActive="active"
              v-show="active === 3 && nav.name === caseKeyWords"
            ></product-navigation-box>
          </CollapseTransition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
// import DropDownMenu from './DropDownMenu';
import ProductNavigationBox from './product/ProductNavigationBox';
import SolutionNavigationBox from './product/SolutionNavigationBox';
export default {
  name: 'Header',
  components: {
    // DropDownMenu,
    ProductNavigationBox,
    SolutionNavigationBox,
    CollapseTransition,
  },
  data() {
    // 关于虹川的关键字，用于生成active样式
    const aboutKeyWords = 'about';
    // 解决方案的关键字，用于生成active样式
    const solutionKeyWords = 'product';
    // 产品中心的关键字，用于生成active样式
    const caseKeyWords = 'case';
    return {
      // 关于虹川 菜单距离左侧的距离 用于展示关于虹川子菜单的宽度V1.0
      aboutDropOffsetLeft: 0,
      // 导航栏宽度 用于展示关于虹川子菜单的宽度V1.1
      navWidth: 0,
      // 导航栏宽度 用于展示关于虹川子菜单的宽度V1.2
      navOffsetLeft: 0,
      aboutKeyWords,
      solutionKeyWords,
      caseKeyWords,
      // 鼠标是否滚动到指定位置
      headerActive: false,
      // 鼠标是否移入
      isEnter: false,
      showSolutionNav: false,
      showProductNavBox: false,
      active: -1,
      activeCurNav: '',
      headerNav: [
        { title: '首页', name: 'index', direction: '/' },
        { title: '关于虹川', name: 'about', direction: '/about' },
        // {
        //   title: '解决方案',
        //   name: 'solution',
        //   direction: '',
        // },,
        { title: '解决方案', name: solutionKeyWords, direction: '' },
        // { title: '广纳贤才', name: 'careers', direction: '/careers' },
        { title: '产品中心', name: caseKeyWords, direction: '' },
        { title: '新闻资讯', name: 'news', direction: '/news' },
      ],
      // 关于虹川
      // aboutMenuItems: [
      //   {
      //     direction: '/about',
      //     name: '/about',
      //     title: '公司介绍',
      //   },
      //   {
      //     direction: '/contact',
      //     name: '/contact',
      //     title: '联系我们',
      //   },
      //   {
      //     direction: '/join-us',
      //     name: '/join-us',
      //     title: '加入我们',
      //   },
      // ],
      solutionMenuItems: [
        {
          direction: '/wisdom-city-solution',
          name: 'city-solution',
          title: '智慧城市',
        },
        {
          direction: '/wisdom-env-solution',
          name: 'env-solution',
          title: '智慧环保',
        },
        {
          direction: '/wisdom-water-solution',
          name: 'water-solution',
          title: '智慧水务',
        },
        // {
        //   direction: '/wisdom-park-solution',
        //   name: 'park-solution',
        //   title: '智慧园区',
        // },
        {
          direction: '/wisdom-zero-carbon-product',
          name: 'zero-carbon-solution',
          title: '智慧零碳',
        },
      ],
    };
  },
  mounted() {
    this.activeNav();
    window.addEventListener('scroll', this.getScroll);

    this.getAboutDropDownOffsetLeft();
    this.getNavWidth();
    this.getNavOffsetLeft();
    window.onresize = () => {
      this.getAboutDropDownOffsetLeft();
      this.getNavWidth();
      this.getNavOffsetLeft();
    };
  },
  destroyed() {
    window.removeEventListener('scroll', this.getScroll);
  },
  methods: {
    /** 设置ref名称 */
    getRefName(index) {
      let name = '';
      switch (index) {
        case 0:
          name = 'home';
          break;
        case 1:
          name = 'about';
          break;
        default:
          name = '';
      }
      return name;
    },
    /** 关于虹川 菜单距离左侧的距离 */
    getAboutDropDownOffsetLeft() {
      const aboutDropOffsetLeft = this.$refs.about[0].offsetLeft;
      this.aboutDropOffsetLeft = aboutDropOffsetLeft;
      return aboutDropOffsetLeft;
    },
    /** 获取导航栏的宽度 */
    getNavWidth() {
      const navWidth = this.$refs.hcNav.offsetWidth;
      this.navWidth = navWidth;
      return navWidth;
    },
    /** 获取导航栏的距离左侧的宽度 */
    getNavOffsetLeft() {
      const navOffsetLeft = this.$refs.hcNav.offsetLeft;
      this.navOffsetLeft = navOffsetLeft;
      return navOffsetLeft;
    },

    /**监听滚动 */
    getScroll(e) {
      const scrollTop =
        e.target.documentElement.scrollTop || e.target.body.scrollTop;
      if (this.activeCurNav == 'index') {
        // 首页 超过720-80时导航栏背景色变化
        if (scrollTop >= 640) {
          this.headerActive = true;
        } else {
          this.headerActive = false;
        }
      } else {
        // 其他页面 超过500-80时导航栏背景色变化
        if (scrollTop >= 420) {
          // active
          this.headerActive = true;
        } else {
          this.headerActive = false;
        }
      }
    },
    changeLogo(isActive) {
      this.isEnter = isActive;
    },
    hideOnClick() {
      this.active = -1;
    },
    hideProductNavBox(isVisible) {
      this.showProductNavBox = isVisible;
    },
    toggle(index) {
      this.active = index;
      localStorage.clear();
    },
    activeNav() {
      // 判断是否当前已激活的导航
      const pathName = window.location.pathname.replace(/^\/|\.html$|\/$/g, '');
      this.activeCurNav = pathName ? pathName : 'index';
    },
  },
};
</script>

<style lang="less" scoped>
@font-color: #1c1c1c;
@font-color-hover: #2750d6;
@font-color-active: #2750d6;
@nav-height: 72px;

@hc-transition: all 0.5s ease-in-out;

@nva-item-height: 27px;

.fade-enter-active {
  transition: opacity 0.6s;
}
.fade-enter {
  opacity: 0;
}

// .nav-link-wrap.relative-pos {
//   position: relative;
// }
.hc-header-box {
  height: @nav-height;
  color: @font-color;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #f5f8fa;
  // background: linear-gradient(0, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.4));
  z-index: 99;
  // transition: @hc-transition;
  &::after {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    // background: rgba(149, 171, 216, 0);
    // transition: @hc-transition;
  }

  .hc-header {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .hc-logo {
      width: 93px;
      height: @nva-item-height;
      margin-left: 12px;
    }
    .hc-nav {
      align-self: center;
      .nav-link-wrap {
        display: inline-block;
        padding: 0 40px;
        cursor: pointer;
        // &:hover {
        //   a.main-nav-link {
        //     color: @font-color-active;
        //   }
        // }
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
      a.main-nav-link {
        display: inline-block;
        height: @nav-height;
        line-height: @nav-height;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;

        text-decoration: none;
        // color: @font-color;
        position: relative;

        &:last-child {
          margin-right: 0;
        }
      }

      a.a-exact-active,
      a.a-active {
        color: @font-color-active;
        &::after {
          content: '';
          width: 100%;
          height: 2px;
          // background: @font-color;
          position: absolute;
          left: 0;
          bottom: 22px;
        }
      }
    }
  }
  &:hover,
  &.active {
    // background: #fff;
    // &::after {
    //   background: rgba(149, 171, 216, 0.2);
    // }

    .hc-header {
      a.main-nav-link {
        &:hover {
          color: @font-color-hover;
        }
      }
      a.a-exact-active,
      a.a-active {
        &::after {
          content: '';
          width: 100%;
          height: 2px;
          // background: @font-color-hover;
          position: absolute;
          left: 0;
          bottom: 22px;
        }
      }
    }
  }
}

/* 1440px 以下的屏幕适配  加上padding40px*/
@media only screen and (max-width: 1480px) {
  .nav-link-wrap {
    &:last-child {
      padding-right: 40px !important;
    }
  }
}
@media screen and (max-width: 1200px) {
  .hc-header {
    justify-content: unset !important;
    .hc-nav {
      margin-left: 150px;
      .nav-link-wrap {
        padding: 0 20px !important;
      }
    }
  }
}
</style>
