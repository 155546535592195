<template>
  <!-- 新闻资讯 -->
  <div class="news-container">
    <div class="news-left"></div>
    <div class="news-center">
      <PageTitle :title="title" :describe="describe" class="page-title" />
      <div class="news-nav">
        <div
          :class="['nav-item', { active: currNav === index }]"
          v-for="(item, index) in navList"
          :key="index"
          @click="currNav = index"
        >
          {{ item.label }}
        </div>
      </div>
      <div class="more" @click="goNews">MORE</div>
    </div>
    <div class="news-right">
      <div class="animation-list">
        <div
          :class="[index === i ? 'animation-c' : 'animation-p']"
          v-for="(v, i) in newsList"
          :key="i"
          @mouseover="handleMouse(i)"
        >
          <div class="top-detail">
            <div class="title-msg">{{ v.title }}</div>
            <div class="timer">
              {{ `${v.publishTime} | ${v.source}` }}
            </div>
            <div class="msg-describe">
              {{ v.htmlContent }}
            </div>
            <div class="more-btn" @click="pageTo(v)">
              MORE
              <span
                class="more-icon"
                v-if="index === i"
                style="top: 5px"
              ></span>
            </div>
          </div>
          <div
            class="bottom-detail"
            :style="{ backgroundImage: `url(${imgUrl(v.coverImage)})` }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewsList } from '@/api/news.js';
import PageTitle from '@/components/pageTitle/index';
import { newsTypeKey } from '@/assets/data/constant';

export default {
  components: { PageTitle },
  name: '',
  data() {
    return {
      title: '新闻资讯',
      describe: 'News Center',
      navList: [
        {
          key: newsTypeKey.all,
          label: '全部',
        },
        {
          key: newsTypeKey.qydt,
          label: '公司动态',
        },
        {
          key: newsTypeKey.hyzx,
          label: '行业资讯',
        },
      ],
      currNav: 0,
      index: 0,
      currentPage: 1,
      pageSize: 3,
      newsList: [],
    };
  },
  created() {
    this.queryNewsData();
  },
  mounted() {},
  methods: {
    handleMouse(i) {
      if (this.index === i) return;
      this.index = i;
    },
    queryNewsData() {
      let escape2Html = str => {
        return str
          .replace(/<.>/g, '')
          .replace(/<p (.*?)>/gi, '')
          .replace(/<\/p>/gi, '')
          .replace(/<span (.*?)>/gi, '')
          .replace(/<\/span>/gi, '')
          .replace(/<br>/gi, '');
      };
      getNewsList({
        current: this.currentPage,
        size: this.pageSize,
        type: this.navList[this.currNav].key || '',
      }).then(res => {
        if (res.data.code == '200' && res.data) {
          this.newsList = res.data.data.records.map(e => {
            return { ...e, htmlContent: escape2Html(e.htmlContent) };
          });
        }
      });
    },
    pageTo(v) {
      if (v.id) {
        const { href } = this.$router.resolve({
          path: '/news/news-detail',
          query: {
            id: v.id,
          },
        });
        window.open(href, '_blank');
      }
    },
    goNews() {
      this.$router.push('/news');
    },
  },
  computed: {
    imgUrl() {
      return url => {
        return `${process.env.VUE_APP_URL_ADDRESS}/hcttop-website${url}`;
      };
    },
  },
  watch: {
    currNav: {
      handler() {
        this.queryNewsData();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep p {
  line-height: 30px !important;
}

::v-deep .animation-c .msg-describe {
  color: #2750d6 !important;
}

@keyframes shrink-line {
  0% {
    position: absolute;
    top: 0px;
    height: 200px;
  }
  25% {
    top: 25px;
    height: 150px;
  }
  50% {
    top: 60px;
    height: 100px;
  }
  75% {
    top: 90px;
    height: 50px;
  }
  100% {
    top: 118px;
    height: 0px;
  }
}

@keyframes expand-line {
  0% {
    top: 118px;
    height: 10px;
  }
  25% {
    top: 90px;
    height: 60px;
  }
  50% {
    top: 60px;
    height: 120px;
  }
  75% {
    top: 30px;
    height: 180px;
  }
  100% {
    top: 20px;
    height: 200px;
  }
}

.news-container {
  min-width: 1200px;
  height: 720px;
  background: #ffffff;
  display: flex;
  align-items: center;
  position: relative;
  .news-left {
    width: 100%;
    height: 100%;
  }
  .news-center {
    position: absolute;
    left: calc((100% - 1200px) / 2);
    width: 249px;
    height: 100%;
    display: flex;
    flex-direction: column;
    .page-title {
      margin-top: 120px;
      margin-bottom: 66px;
    }
    .news-nav {
      display: flex;
      flex-direction: column;
      .nav-item {
        width: 128px;
        height: 40px;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #343434;
        line-height: 40px;
        cursor: pointer;
        transition: all 0.5s ease;
        &:hover,
        &.active {
          color: #ffffff;
          background: #2750d6;
          text-align: center;
          vertical-align: middle;
          transition: all 0.5s ease;
        }
        &:nth-child(2) {
          margin-top: 25px;
        }
        &:nth-child(3) {
          margin-top: 30px;
        }
      }
    }
    .more {
      cursor: pointer;
      width: 100%;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #49525c;
      line-height: 24px;
      margin-top: 114px;
      position: relative;
      &::after {
        content: url('~@/assets/images/home/arrows-r-b.svg');
        width: 41px;
        height: 24px;
        background-size: 100% 100%;
        padding-left: 10px;
        position: absolute;
        top: 5px;
        opacity: 0;
      }
      &:hover {
        color: #2750d6;
        &::after {
          opacity: 1;
        }
      }
    }
  }
  .news-right {
    position: absolute;
    left: calc((100% - 1200px) / 2 + 249px);
    width: calc((100% - 1200px) / 2 + 951px);
    height: 100%;
    .animation-list {
      display: flex;
      width: 100%;
      .top-detail {
        height: 212px;
        position: relative;
        .title-msg {
          height: 28px;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #313a44;
          line-height: 28px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .timer {
          position: absolute;
          top: 66px;
          height: 26px;
          line-height: 26px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #49525c;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .msg-describe {
          height: 40px;
          overflow: hidden;
          position: absolute;
          top: 120px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #49525c;
          line-height: 20px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .more-btn {
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          display: flex;
          position: absolute;
          top: 188px;
          align-items: flex-end;
          .more-icon {
            width: 41px;
            height: 24px;
            background: url('~@/assets/images/home/arrows-r-b.svg');
            background-size: 100% 100%;
            margin-left: 19px;
            position: relative;
            top: 5px;
          }
        }
      }
      .bottom-detail {
        height: 150px;
        background: center no-repeat;
        background-size: cover;
        margin-top: 50px;
      }
      .animation-c {
        cursor: pointer;
        width: 47%;
        // flex-shrink: 0;
        height: 720px;
        padding-top: 118px;
        padding-left: 30px;
        padding-right: 40px;
        border-left: 1px solid #d7dce1;
        color: #2750d6;
        transition: all 0.2s linear;
        .top-detail {
          position: relative;
          &::after {
            content: '';
            position: absolute;
            height: 200px;
            left: -31px;
            top: 0;
            border-left: 1px solid #2750d6;
            animation: expand-line 0.5s linear;
          }
          .title-msg,
          .timer {
            color: #2750d6;
          }
        }
        .bottom-detail {
          height: 300px;
          transition: all 0.2s linear;
        }
      }
      .animation-p {
        cursor: pointer;
        width: 33.333%;
        height: 720px;
        padding-top: 118px;
        padding-left: 30px;
        padding-right: 35px;
        border-left: 1px solid #d7dce1;
        transition: all 0.2s linear;
        .top-detail {
          position: relative;
          &::before {
            content: '';
            position: absolute;
            border-left: 1px solid #2750d6;
            left: -31px;
            animation: shrink-line 0.5s ease;
          }
        }
      }
    }
  }
}
</style>
