<template>
  <!-- 商务咨询 -->
  <div class="solution-consultation">
    <div class="solution-bg"></div>
    <div class="solution-box">
      <div class="solution-title">立即获取解决方案</div>
      <div @click="goContactUs" class="solution-btn">
        <div class="btn-name">商务咨询</div>
        <img
          class="btn-icon"
          :src="require('@/assets/images/product/icon_arrow_right_blue.png')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import resize from '@/mixins/resize';

export default {
  mixins: [resize],
  name: 'BusinessConsulting',
  data() {
    return {};
  },
  mounted() {},
  methods: {
    goContactUs() {
      const { fullPath } = this.$router.currentRoute;
      sessionStorage.setItem('PRE_ROUTE', fullPath);
      localStorage.setItem('CURRENT_ANCHOR', 'get-service');
      this.$router.push('/contact');
    },
  },
};
</script>

<style lang="scss" scoped>
.solution-consultation {
  min-width: 1200px;
  height: 200px;
  overflow: hidden;
  background: #0041b1;
  position: relative;
  .solution-bg {
    transition: all 0.5s ease-in-out;
    width: 100%;
    height: 100%;
    background-image: url('~@/assets/images/solution/solution_bg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1920px 200px;
  }
  &:hover {
    .solution-bg {
      transform: scale(1.15);
      transition: all 0.5s ease;
    }
  }
  .solution-box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .solution-title {
      font-size: 28px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #ffffff;
      line-height: 40px;
      margin-top: 38px;
    }
    .solution-btn {
      width: 128px;
      height: 40px;
      background: #ffffff;
      margin-top: 35px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .btn-name {
        flex: 1;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #2439b2;
        line-height: 20px;
      }
      .btn-icon {
        width: 23px;
        height: 9px;
        right: 30px;
        opacity: 0;
        position: absolute;
      }
      &:hover {
        .btn-name {
          transition: all 0.3s linear;
          padding-right: 25px;
        }
        .btn-icon {
          transition: all 0.3s ease-in;
          opacity: 1;
          right: 15px;
        }
      }
    }
  }
}
</style>
