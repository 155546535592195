<template>
  <div class="solution-banner" :style="{ background: info.gradient }">
    <div class="top-banner" :style="{ backgroundImage: `url(${info.img})` }">
      <div v-if="isText" class="banner-text">
        <div class="text">{{ info.text }}</div>
        <div class="sub-text" v-html="info.subText"></div>
      </div>
      <div v-else class="banner-title">
        <img
          :src="info.title.img"
          :style="{ width: info.title.width, height: info.title.height }"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SolutionBanner',
  props: {
    info: {
      type: Object,
      default: () => ({
        text: '',
        subText: '',
        img: '',
        title: {
          img: '',
          with: '',
          height: '',
        },
        gradient: '',
      }),
    },
    isText: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.solution-banner {
  min-width: 1200px;
  height: 425px;
  background: linear-gradient(to right, #d8e2ee 50%, #ecf5fe 50%);
  .top-banner {
    width: 100%;
    height: 100%;
    background-image: url('~@/assets/images/solution/zero-carbon/zero_carbon_banner.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1920px 425px;
    display: flex;
    .banner-text {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      text-align: left;
      white-space: nowrap;
      align-items: center;
      .text {
        width: 224px;
        height: 78px;
        font-size: 56px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1c1c1c;
        line-height: 78px;
      }
      .sub-text {
        width: 444px;
        height: 66px;
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #313a44;
        line-height: 33px;
        letter-spacing: 1px;
        margin-left: 60px;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          left: -30px;
          width: 1px;
          height: 60px;
          background: #313a44;
        }
      }
    }
    .banner-title {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      img {
        width: 729px;
        height: 78px;
      }
    }
  }
}
</style>
