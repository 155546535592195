<!--
 * Description: 置顶新闻: 在首页和新闻中心有使用.
 * Created Date: 2021-01-20 3:51:37 PM
 * Author: Liu Man <lm@hcttop.com>
 * -----
 * Last Modified: 2021-01-20, 3:51:37 PM
 * Modified By: Liu Man <lm@hcttop.com>
 -->

<template>
  <!-- 置顶新闻 -->
  <div class="hot-topic">
    <div
      class="news-item"
      v-for="(item, index) in newsTopicArr"
      :key="index"
      @click="toDetail(item.id)"
    >
      <div class="item-img">
        <!-- <img :src="imgUrl(item.coverImage)" /> -->
        <el-image
          :src="imgUrl(item.coverImage)"
          alt="新闻图片"
          fit="cover"
          class="hc-img"
        ></el-image>
      </div>
      <div class="item-title">
        {{ item.title }}
      </div>
      <div class="item-time">{{ item.publishTime }}</div>
    </div>
  </div>
</template>
<script>
import { getTopArticle } from '@/api/news.js';

export default {
  data() {
    return {
      // 置顶的新闻
      newsTopicArr: [],
    };
  },
  created() {
    this.queryData();
  },
  computed: {
    imgUrl() {
      return url => {
        return `${process.env.VUE_APP_URL_ADDRESS}/hcttop-website${url}`;
      };
    },
  },
  methods: {
    toDetail(id) {
      const { href } = this.$router.resolve({
        path: '/news/news-detail',
        query: {
          id,
        },
      });
      window.open(href, '_blank');
    },
    queryData() {
      const params = {};
      getTopArticle(params).then(res => {
        if (res.data.code === 200) {
          this.newsTopicArr = res.data.data;
        } else {
          // TODO 异常处理
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
// 置顶新闻
.hot-topic {
  display: flex;
  justify-content: space-between;
  ::v-deep {
    img {
      height: inherit;
    }
  }
  .news-item {
    width: 380px;
    cursor: pointer;
    .hc-img {
      width: 100%;
      height: 100%;
      transition: all 0.5s ease-in-out;
    }
    &:hover {
      .item-title {
        color: #2439b2;
        text-decoration: underline;
      }
      .hc-img {
        transform: scale(1.1);
      }
    }
  }
  .item-img {
    width: 100%;
    height: 300px;
    background: chocolate;
    overflow: hidden;
  }
  .item-title {
    font-size: 18px;
    color: #343434;
    letter-spacing: 1px;
    margin: 40px 0 15px 0;
    // 超出2行显示...
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: 600;
  }
  .item-time {
    font-size: 14px;
    color: #343434;
  }
}
</style>
