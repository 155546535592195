<!--
 * Description: 组件：案例
 * Created Date: 2021-01-22 9:02:46 AM
 * Author: Zhao xiaoqin <zxq@hcttop.com>
 * -----
 * Last Modified: 2021-01-22, 3:30:45 PM
 * Modified By: Liu Man <lm@hcttop.com>
 -->

<template>
  <div class="case">
    <div v-if="caseList.length >= 4" class="content case-box">
      <div class="case-info">
        <div class="info-left">{{ caseList[activeIndex].name }}</div>
        <div class="info-right">
          <div class="case-label">案例简介</div>
          <div class="case-detail">
            {{ caseList[activeIndex].content }}
          </div>
        </div>
      </div>
      <div class="swiper-box">
        <swiper
          class="case-swiper-container"
          :options="swiperOption"
          ref="swiper"
          @click.native="clickSlide"
        >
          <swiper-slide
            v-for="(item, index) in caseList"
            :key="index"
            :class="[activeIndex == index ? 'active' : '']"
          >
            <div class="swiper-item">
              <div class="slide-img">
                <img
                  :src="require(`@/assets/images/cases/${item.imgName}.png`)"
                  :alt="item.name"
                  fit="cover"
                  class="hc-img"
                />
              </div>
              <div :class="['name']">
                {{ item.name }}
              </div>
            </div>
          </swiper-slide>
          <div
            v-show="caseList.length > 4"
            style="background-image: none;
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align:center;
            color:#fff;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.45);"
            class="swiper-button-prev el-icon-arrow-left"
            slot="button-prev"
          ></div>
          <div
            v-show="caseList.length > 4"
            class="swiper-button-next el-icon-arrow-right"
            style="background-image: none;
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align:center;
            color:#fff;
            border-radius: 50%;
            background: rgba(0, 0, 0, 0.45);"
            slot="button-next"
          ></div>
        </swiper>
      </div>
    </div>
    <!-- 园区单独显示 -->
    <div v-else class="park-content">
      <div class="park-title">服务案例</div>
      <div class="list">
        <div v-for="(item, index) in caseList" :key="index" class="item">
          <div class="park-case-img">
            <img
              :src="require(`@/assets/images/cases/${item.imgName}.png`)"
              :alt="item.name"
              fit="cover"
            />
            <!-- <el-image :src="item.img" :alt="item.name" fit="cover"></el-image> -->
          </div>
          <div class="park-detail">
            <div class="name">{{ item.name }}</div>
            <div class="label">案例简介</div>
            <div class="desc">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'case',
  props: ['caseList'],
  data() {
    return {
      // 轮播图配置
      activeIndex: 0,
      swiperOption: {
        slidesPerView: 4,
        // slider之间的间隙
        spaceBetween: 40,
        watchSlidesVisibility: true,
        // 循环方式：无限循环
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.swiper.swiper;
    },
  },
  methods: {
    clickSlide() {
      const { swiper } = this;

      if (swiper.clickedSlide) {
        const currentIndex = swiper.clickedSlide.getAttribute(
          'data-swiper-slide-index'
        );

        const dom = document.getElementsByClassName('swiper-slide');
        const domList = Array.from(dom);

        domList.forEach(item => {
          if (item.classList.contains('active')) {
            item.classList.remove('active');
          }
          if (item.getAttribute('data-swiper-slide-index') === currentIndex) {
            item.classList.add('active');
          }
        });
        this.activeIndex = Number(currentIndex);
      }
    },
  },
};
</script>

<style lang="less">
@import '~@/assets/css/global';
@import '~@/assets/css/product';
</style>
