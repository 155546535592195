<template>
  <!-- 技术优势 -->
  <div class="technology-wrapper">
    <div class="technology-container">
      <PageTitle :title="title" :describe="describe" class="title" />
      <div class="technology-detail">
        <div v-for="(v, i) in technologyList" :key="i" class="technology-c">
          <div
            class="animate-bg"
            :style="{ backgroundImage: `url(${v.imgHover})` }"
          ></div>
          <div class="bg" :style="{ backgroundImage: `url(${v.img})` }"></div>
          <div class="title">{{ v.title }}</div>
          <div class="desc">{{ v.msg }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/pageTitle/index';
export default {
  components: { PageTitle },
  name: '',
  data() {
    return {
      index: null,
      title: '技术优势',
      describe: 'Technical advantages',
      technologyList: [
        {
          img: require('@/assets/images/home/technology/data_bg.png'),
          imgHover: require('@/assets/images/home/technology/data.png'),
          title: '大数据',
          msg:
            '支撑海量数据的存储，支持各类异构数据源，提供灵活的数据标准化服务。',
        },
        {
          img: require('@/assets/images/home/technology/iot_bg.png'),
          imgHover: require('@/assets/images/home/technology/iot.png'),
          title: '物联网',
          msg:
            '支持分布式架构，私有云等部署，支持主流通信协议接入，强大的并发处理能力。',
        },
        {
          img: require('@/assets/images/home/technology/ai_bg.png'),
          imgHover: require('@/assets/images/home/technology/ai.png'),
          title: 'AI智能',
          msg:
            '基于神经网络模型、大数据中心等核心技术，实现图像、视频、音频识别，行业场景预测、智能分析等创新应用。',
        },
        {
          img: require('@/assets/images/home/technology/gis_bg.png'),
          imgHover: require('@/assets/images/home/technology/gis.png'),
          title: '3D-GIS',
          msg:
            '自研3D-GIS服务平台，实现私有化地理图层、建筑内部分层结构等，打造专属行业空间地理服务。',
        },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
@keyframes animate {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 -5760px;
  }
}

.technology-wrapper {
  width: 100%;
  height: 740px;
  .technology-container {
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    .title {
      margin-top: 79px;
      margin-bottom: 53px;
    }
    .technology-detail {
      margin-top: 60px;
      width: 1200px;
      height: 480px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .technology-c {
        cursor: pointer;
        width: 600px;
        height: 240px;
        position: relative;
        .animate-bg,
        .bg {
          width: 600px;
          height: 240px;
          position: absolute;
          bottom: 0;
          background-position: center;
          background-repeat: no-repeat;
          background-size: 100%;
        }
        .animate-bg {
          opacity: 0;
          transition: all 0.5s ease;
        }
        .title {
          position: absolute;
          top: -10px;
          left: 30px;
          font-size: 28px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #313a44;
          transition: all 0.5s ease;
        }
        .desc {
          width: 250px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          position: absolute;
          text-align: justify;
          top: 120px;
          left: 30px;
          transition: all 0.5s ease;
        }

        &:hover {
          .bg {
            opacity: 0;
            transition: all 0.5s ease;
          }
          .animate-bg {
            opacity: 1;
            animation: animate 2s steps(24) infinite;
          }
          .title {
            color: #0e93ff;
            position: absolute;
            top: -50px;
            left: 30px;
            transition: all 0.5s ease;
          }
          .desc {
            position: absolute;
            top: 80px;
            left: 30px;
            color: #0e93ff;
            transition: all 0.5s ease;
          }
        }
      }
    }
  }
}
</style>
