<!--
 * Description: reauired, the file description, reference link, etc.
 * Created Date: 2021-01-18 3:17:12 PM
 * Author: Zhao xiaoqin <zxq@hcttop.com>
 * -----
 * Last Modified: 2020-06-24, 7:30:23 PM
 * Modified By: Liu Man <lm@hcttop.com>
 -->

<template>
  <div class="hc-footer-box">
    <div class="footer-content">
      <div class="item" v-for="(item, index) in infoList" :key="index">
        <div class="label">{{ item.label }}</div>
        <router-link
          class="link"
          v-for="(it, linkIndex) in item.links"
          :key="linkIndex"
          :to="{ path: `${it.url}` }"
          >{{ it.name }}</router-link
        >
        <!-- a标签跳转会刷新整个页面，用router更好 -->
        <!-- <a
          :href="it.url"
          class="link"
          v-for="(it, linkIndex) in item.links"
          :key="linkIndex"
        >
          {{ it.name }}
        </a> -->
      </div>
      <div class="item information">
        <div class="label spe">关注或联系我们</div>
        <div class="infos">
          <div class="qrcode">
            <img src="@/assets/images/footer/qrcode.png" alt="" />
          </div>
          <div class="infos-item">
            <div class="box" v-for="(v, i) in infos" :key="i">
              <div class="icon" :style="{ height: v.iconH }">
                <img :src="v.icon" alt="" />
              </div>
              <div class="number">{{ v.text }}</div>
            </div>
          </div>
        </div>
        <div class="address">
          公司地址: 深圳市南山区科技园中区国人通信大厦A座13层
        </div>
      </div>
    </div>
    <footer class="foot">
      <div class="foot-left">
        <span
          >© 2019~2022 虹川科技Hong Chuan Technology. All Rights Reserved</span
        >
        <span><a href="https://beian.miit.gov.cn">粤ICP备19122684号</a></span>
        <span
          ><a href="https://beian.miit.gov.cn">
            <img
              class="icon-police"
              src="@/assets/images/footer/police.png"
              alt=""
            />
            粤公网安备 44030502004701号
          </a>
        </span>
      </div>
      <div class="foot-right">
        保密声明
        <span>|</span>
        服务协议
        <span>|</span>
        网站地图
      </div>
    </footer>
  </div>
</template>

<script>
import { newsTypeKey } from '@/assets/data/constant';

export default {
  name: 'Footer',
  data() {
    return {
      infoList: [
        {
          label: '关于我们',
          links: [
            {
              name: '企业文化',
              url: '/about?target=1',
            },
            {
              name: '企业资质',
              url: '/about?target=2',
            },
            {
              name: '对外合作',
              url: '/about?target=3',
            },
            {
              name: '加入我们',
              url: '/about?target=4',
            },
            {
              name: '联系我们',
              url: '/contact',
            },
          ],
        },
        {
          label: '智慧零碳',
          links: [
            {
              name: '政府零碳监管平台',
              url: '/solution/solution-detail?type=zero&name=zfltjg',
            },
            {
              name: '行业零碳监管平台',
              url: '/wisdom-zero-carbon-product#1',
            },
            {
              name: '公众智慧零碳平台',
              url: '/wisdom-zero-carbon-product#2',
            },
            {
              name: '碳金融服务平台',
              url: '/wisdom-zero-carbon-product#3',
            },
          ],
        },
        {
          label: '智慧水务',
          links: [
            {
              name: '供水一体化',
              url: '/solution/solution-detail?type=water&name=zhswgs',
            },
            {
              name: '排水一体化',
              url: '/solution/solution-detail?type=water&name=zhswps',
            },
            {
              name: '应急一体化',
              url: '/solution/solution-detail?type=water&name=zhswyj',
            },
            {
              name: '水环境一体化',
              url: '/solution/solution-detail?type=water&name=zhswshj',
            },
          ],
        },
        {
          label: '新闻资讯',
          links: [
            {
              name: '企业动态',
              url: `/news?type=${newsTypeKey.qydt}`,
            },
            {
              name: '行业资讯',
              url: `/news?type=${newsTypeKey.hyzx}`,
            },
          ],
        },
      ],
      infos: [
        {
          icon: require('@/assets/images/footer/footer_phone.svg'),
          iconH: '20px',
          text: '0755 22218622 /22218633',
        },
        {
          icon: require('@/assets/images/footer/footer_fax.svg'),
          iconH: '18px',
          text: '0755 22202959',
        },
        {
          icon: require('@/assets/images/footer/footer_email.svg'),
          iconH: '18px',
          text: 'business@hcttop.com',
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
@import '~@/assets/css/global.less';
.hc-footer-box {
  min-width: 1200px;
  height: 540px;
  background: #1b1c20;
  .footer-content {
    width: 1200px;
    margin: 0 auto;
    height: 435px;
    padding: 120px 0;
    display: flex;
    justify-content: space-between;
    .item {
      display: flex;
      flex-direction: column;
      &:first-child {
        padding-left: 0;
        align-items: flex-start;
      }
      &:last-child {
        padding-right: 0;
        justify-content: flex-start;
      }
      .label {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 22px;
        margin-bottom: 25px;
        &.spe {
          margin-bottom: 35px;
        }
      }
      .link {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #989898;
        line-height: 36px;
        &:hover {
          color: #ffffff;
          text-decoration: underline;
        }
      }
      .qr-code {
        width: 150px;
        height: 150px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .information {
      min-width: 355px;
      .infos {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        .qrcode {
          width: 110px;
          height: 110px;
          margin-right: 10px;
        }
        .infos-item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 110px;
          .box {
            width: 100%;
            display: flex;
            align-items: center;
            .icon {
              width: 18px;
              height: 18px;
              margin-right: 8px;
            }
            .number {
              flex: 1;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #878787;
              line-height: 28px;
              white-space: nowrap;
              text-decoration: none;
            }
          }
        }
      }
      .address {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #878787;
        line-height: 36px;
        white-space: nowrap;
      }
    }
  }
  .foot {
    width: 1200px;
    height: 100px;
    margin: 0 auto;
    border-top: 1px solid #2a2a2a;
    color: #6e6e6e;
    font-family: Georgia;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .foot-left {
      display: flex;
      align-items: center;
      span {
        height: 24px;
        line-height: 24px;
        margin-right: 32px;
        font-size: 12px;
        font-weight: 400;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #6e6e6e;
        a {
          color: #6e6e6e;
        }
        .icon-police {
          width: 14px;
          height: 15.4px;
        }
      }
    }
    .foot-right {
      width: 332px;
      height: 24px;
      line-height: 24px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6e6e6e;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // display: none;
    }
  }
}
</style>
