<template>
  <div class="core-app">
    <div class="core-content">
      <div class="core-title">
        {{ info.title }}
      </div>
      <div class="core-tabs">
        <div
          :class="['tab-item', activeIndex === index ? 'active' : '']"
          :style="{ width: `${item.width}px` }"
          v-for="(item, index) in info.tabs"
          :key="index"
          @click="click(index)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="core-items">
        <div class="core-img">
          <img :src="isMultiple ? filterMultiple.img : filterList.img" alt="" />
        </div>
        <!-- 单项 -->
        <div class="core-item" v-if="!isMultiple">
          <div class="title">{{ filterList.title }}</div>
          <div class="sub-title">{{ filterList.name }}</div>
          <div class="desc">
            <div
              v-for="(v, i) in filterList.value"
              :key="i"
              :style="{ width: filterList.single ? '100%' : '50%' }"
            >
              {{ v }}
            </div>
          </div>
        </div>
        <!-- 多项 -->
        <div class="core-item" v-else>
          <div
            class="multi-line"
            v-for="(item, index) in filterMultiple.value"
            :key="index"
          >
            <div class="title">
              {{ item.title }}
            </div>
            <div class="desc">
              <div v-for="(v, i) in item.text" :key="i" style="width: 100%">
                {{ v }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CoreApplicationComp',
  props: {
    info: {
      type: Object,
      default: () => ({
        title: '',
        titleStyle: {
          mt: '80px',
          mb: '60px',
        },
        tabs: [],
        name: '',
        rows: [],
        multiple: false,
        infoList: [],
      }),
    },
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  computed: {
    filterList() {
      if (this.info.infoList) {
        return {
          ...this.info.infoList[this.activeIndex],
          single: this.info.rows[this.activeIndex] === 1,
          name: this.info.name,
        };
      }
      return {};
    },
    isMultiple() {
      return this.info?.multiple || false;
    },
    filterMultiple() {
      return this.info?.infoList[this.activeIndex] || [];
    },
  },
  methods: {
    click(i) {
      this.activeIndex = i;
    },
  },
};
</script>

<style lang="scss" scoped>
.core-app {
  width: 100%;
  height: 632px;
  background: #f7f7f7;
  display: flex;
  flex-direction: column;

  .core-content {
    width: 1200px;
    margin: 0 auto;
    .core-title {
      font-size: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #1c1c1c;
      line-height: 32px;
      margin-top: 80px;
      margin-bottom: 60px;
      text-align: center;
    }
    .core-tabs {
      margin-top: 40px;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      .tab-item {
        width: 240px;
        height: 45px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #343434;
        line-height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          color: #2750d6;
        }
      }
      .active {
        background: rgba(39, 80, 214, 0.06);
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #2750d6;
        line-height: 25px;
      }
    }
    .core-items {
      display: flex;
      background: #ffffff;
      .core-img {
        width: 600px;
        height: 340px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .core-item {
        width: 600px;
        height: 340px;
        padding: 30px;
        display: flex;
        flex-direction: column;
        .title {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #343434;
          line-height: 28px;
          margin-bottom: 30px;
          text-align: justify;
        }
        .multi-line {
          &:nth-child(n + 2) {
            margin-top: 20px;
          }
          .title {
            margin-bottom: 10px;
          }
          .desc div {
            margin-bottom: 10px;
          }
        }
        .sub-title {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #343434;
          line-height: 22px;
          margin-bottom: 20px;
        }
        .desc {
          width: 550px;
          display: flex;
          flex-wrap: wrap;
          div {
            min-width: 270px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #343434;
            line-height: 20px;
            margin-bottom: 20px;
            padding-left: 10px;
            list-style: none;
            position: relative;
            &:before {
              content: ' ';
              position: absolute;
              left: 0px;
              top: 8px;
              width: 4px;
              height: 4px;
              background: #343434;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
}
</style>
