<template>
  <!-- 解决方案-智慧水务(v2.1) -->
  <div class="solution-wrapper">
    <page-header></page-header>
    <solution-banner :info="bannerInfo"></solution-banner>
    <!-- 应用场景 -->
    <application-scenario
      title="应用场景"
      :list="listData"
      typeInfo="water"
    ></application-scenario>
    <!-- 解决方案全景图 -->
    <div class="water-wrapper">
      <div class="water-title">解决方案全景图</div>
      <div class="water-info">
        <div class="water-info-text"></div>
      </div>
      <div class="water-box">
        <div class="water-detail">
          <div v-for="(v, i) in animateList" :key="i" class="water-item">
            <div
              :class="[`animate-${v.steps}`]"
              :style="{ backgroundImage: `url(${v.imgHover})` }"
            ></div>
            <div class="bg" :style="{ backgroundImage: `url(${v.img})` }"></div>
            <div class="title">{{ v.title }}</div>
            <div class="desc" v-html="v.desc"></div>
          </div>
        </div>
        <div class="water-animate" ref="waterAnimate"></div>
      </div>
    </div>
    <!-- 服务案例 -->
    <home-swiper :info="swiperInfo" :list="picList"></home-swiper>
    <!-- 跳转商务咨询 -->
    <business-consulting></business-consulting>
    <page-footer></page-footer>
  </div>
</template>

<script>
import lottie from 'lottie-web';
import PageHeader from '@/components/PageHeader';
import SolutionBanner from '../product/components/SolutionBanner';
import ApplicationScenario from '../product/components/ApplicationScenario';
import HomeSwiper from '../product/components/HomeSwiper';
import BusinessConsulting from '../product/components/BusinessConsulting';
import PageFooter from '@/components/PageFooter';
export default {
  components: {
    PageHeader,
    SolutionBanner,
    ApplicationScenario,
    HomeSwiper,
    BusinessConsulting,
    PageFooter,
  },
  name: '',
  data() {
    return {
      bannerInfo: {
        text: '智慧水务',
        subText: '助力政府、水务公司实现信息化升级<br/>数字化转型、智慧化变革',
        img: require('@/assets/images/solution/water/water_banner.jpg'),
        title: {
          img: require('@/assets/images/home/banner_title-03.svg'),
          with: '729px',
          height: '78px',
        },
        gradient: 'linear-gradient(to right, #d8e2ee 50%, #ecf5fe 50%)',
      },
      routeInfo: {},
      index: 0,
      rightImgText: '图0',
      listData: [
        {
          more: true, // 是否有跳转
          title: '供水一体化',
          anchor: 'zhswgs',
          msg:
            '以更加精细和精准的方式管理供水的整个生产、管理和服务流程，以更加“智慧”的方式辅助水务企业供水调配和运维，保证可靠供水，降低产销差，提升运营管控能力。',
          img: require('@/assets/images/solution/water/water_affairs-01.jpg'),
        },
        {
          more: true, // 是否有跳转
          title: '排水一体化',
          anchor: 'zhswps',
          msg:
            '实现排水设施运行工况及水位信息全天候、全方位、全时段的掌控，构建运营分析模块，推进排水管网和水质净化厂优化运行，全方位提升排水服务质量，保障城市排水机制。',
          img: require('@/assets/images/solution/water/water_affairs-02.jpg'),
        },
        {
          more: true, // 是否有跳转
          title: '应急一体化',
          anchor: 'zhswyj',
          msg:
            '在极端事故时供排水业务的协同处置和应急响应。对接在线监测数据和业务数据，通过数据分析研判极端事故的产生，自动启动应急响应，同时协调各部门联动，线上线下协同，降低极端事故造成的负面影响。',
          img: require('@/assets/images/solution/water/water_affairs-03.jpg'),
        },
        {
          more: true, // 是否有跳转
          title: '水环境一体化',
          anchor: 'zhswshj',
          msg:
            '以水质测量预警站为基础，针对水务，水利，环保，企业的需求，建立水环境质量在线监测体系，自动预警、污染溯源、信息综合分析，有效改善环境质量状况，提升居民的生活质量和舒适度。',
          img: require('@/assets/images/solution/water/water_affairs-04.jpg'),
        },
      ],
      swiperInfo: {
        title: '服务案例',
        describe: 'Service case',
        img: require('@/assets/images/home/city/home_swiper_bg.png'),
        text: '',
        height: '80px',
      },
      picList: [
        {
          img: require('@/assets/images/home/city/pic-one.jpg'),
          icon: require('@/assets/images/home/city/swiper-01.svg'),
          iconSize: '1',
          position: '深圳',
          title: '供排水一体化区域运营平台',
          name: '深圳水务集团分公司',
          hoverImg: require('@/assets/images/home/city/swiper-02-h.svg'),
          hoverIcon: require('@/assets/images/solution/hover-swiper-01.svg'),
          hoverDesc:
            '从实际工作场景出发，打造供排水监控、直饮水保障、河道治理、应急指挥、防洪排涝等主题，为供排水调度指挥提供总览全局、决策指挥、协同作战、高效处置的平台。',
        },
        {
          img: require('@/assets/images/home/city/pic-two.jpg'),
          icon: require('@/assets/images/home/city/swiper-02.svg'),
          iconSize: '2',
          position: '青岛',
          title: '区域供水综合管理系统',
          name: '青岛海润自来水集团',
          hoverImg: require('@/assets/images/home/city/swiper-01-h.svg'),
          hoverIcon: require('@/assets/images/solution/hover-swiper-02.svg'),
          hoverDesc:
            '对区域的供水管网、供水设施、计量、二次供水等相关基础设施进行改造，在区域供水设施精细化、智慧化管理方面进行有益的尝试，提升集团公司供水保障能力和服务水平。',
        },
        {
          img: require('@/assets/images/home/city/pic-three.jpg'),
          icon: require('@/assets/images/home/city/swiper-02.svg'),
          iconSize: '2',
          position: '青岛',
          title: '智慧化二次供水泵房升级改造',
          name: '青岛海润自来水集团',
          hoverImg: require('@/assets/images/home/city/swiper-01-h.svg'),
          hoverIcon: require('@/assets/images/solution/hover-swiper-02.svg'),
          hoverDesc:
            '确保居民供水“最后一公里”的供水安全，将智慧赋能于二次供水管理，实现了泵房运行、小区漏损、营收、水质提升等数据实时监测与分析、及“智慧助老”等特色功能。',
        },
        {
          img: require('@/assets/images/home/city/pic-four.jpg'),
          icon: require('@/assets/images/home/city/swiper-03.svg'),
          iconSize: '3',
          position: '滕州',
          title: '滕州智慧排水',
          name: '滕州排水有限公司',
          hoverImg: require('@/assets/images/home/city/swiper-03-h.svg'),
          hoverIcon: require('@/assets/images/solution/hover-swiper-03.png'),
          hoverDesc:
            '数字孪生、三维虚拟现实展示设备设施生产运营。依据实时监测数据通过管网模型预测分析、辅助管网运营管理。',
        },
        {
          img: require('@/assets/images/home/city/pic-five.jpg'),
          icon: require('@/assets/images/home/city/swiper-01.svg'),
          iconSize: '1',
          position: '中山',
          title: '中山智慧水务',
          name: '中山水务局',
          hoverImg: require('@/assets/images/home/city/swiper-01-h.svg'),
          hoverIcon: require('@/assets/images/solution/hover-swiper-01.svg'),
          hoverDesc:
            '结合河涌水情、设备设施实时监测、预测预警水质状况，迅速作出异常扩散态势分析，闸站联合控制，实现活水循环优化调度，科学有效的进行应急调度和人员调度。',
        },
      ],
      lottieAnimateWater: null,
      animateList: [
        {
          img: require('@/assets/images/solution/water/water_animate_01.png'),
          imgHover: require('@/assets/images/solution/water/water_animate_01_hover.png'),
          title: '水库',
          desc: '从源头保障水<br/>质安全',
          steps: '25',
        },
        {
          img: require('@/assets/images/solution/water/water_animate_02.png'),
          imgHover: require('@/assets/images/solution/water/water_animate_02_hover.png'),
          title: '水厂',
          desc: '无人/少人值守<br/>智慧化运营',
          steps: '25',
        },
        {
          img: require('@/assets/images/solution/water/water_animate_03.png'),
          imgHover: require('@/assets/images/solution/water/water_animate_03_hover.png'),
          title: '中途加压站',
          desc: '高效低碳运行',
          steps: '25',
        },
        {
          img: require('@/assets/images/solution/water/water_animate_04.png'),
          imgHover: require('@/assets/images/solution/water/water_animate_04_hover.png'),
          title: '供水管网',
          desc: '运行状态模拟<br/>评估',
          steps: '48',
        },
        {
          img: require('@/assets/images/solution/water/water_animate_05.png'),
          imgHover: require('@/assets/images/solution/water/water_animate_05_hover.png'),
          title: '河道',
          desc: '水环境持续跟<br/>踪监测治理',
          steps: '25',
        },
        {
          img: require('@/assets/images/solution/water/water_animate_06.png'),
          imgHover: require('@/assets/images/solution/water/water_animate_06_hover.png'),
          title: '用水大户',
          desc: '用水特征分析',
          steps: '25',
        },
        {
          img: require('@/assets/images/solution/water/water_animate_07.png'),
          imgHover: require('@/assets/images/solution/water/water_animate_07_hover.png'),
          title: '用水设施',
          desc: '数字化运维保障',
          steps: '25',
        },
        {
          img: require('@/assets/images/solution/water/water_animate_08.png'),
          imgHover: require('@/assets/images/solution/water/water_animate_08_hover.png'),
          title: '二供泵房',
          desc: '智慧化精细化<br/>管理',
          steps: '25',
        },
        {
          img: require('@/assets/images/solution/water/water_animate_09.png'),
          imgHover: require('@/assets/images/solution/water/water_animate_09_hover.png'),
          title: '净化厂',
          desc: '',
          steps: '25',
        },
        {
          img: require('@/assets/images/solution/water/water_animate_10.png'),
          imgHover: require('@/assets/images/solution/water/water_animate_10_hover.png'),
          title: '排水泵站',
          desc: '厂站网联合调度，截污控源',
          steps: '48',
        },
        {
          img: require('@/assets/images/solution/water/water_animate_11.png'),
          imgHover: require('@/assets/images/solution/water/water_animate_11_hover.png'),
          title: '污水管网',
          desc: '',
          steps: '25',
        },
        {
          img: require('@/assets/images/solution/water/water_animate_12.png'),
          imgHover: require('@/assets/images/solution/water/water_animate_12_hover.png'),
          title: 'DMA小区',
          desc: '区域漏损分析',
          steps: '25',
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.startAminate();
  },
  methods: {
    goToOtherPage(nav) {
      localStorage.setItem('CURRENT_ANCHOR', nav.anchor);
      this.$router.push(
        '/solution/solution-detail?type=water&name=' + nav.anchor
      );
    },
    startAminate() {
      this.lottieAnimateWater = lottie.loadAnimation({
        container: this.$refs.waterAnimate,
        renderer: 'svg',
        loop: true,
        autoplay: false,
        path: 'water.json',
      });
      setTimeout(() => {
        this.lottieAnimateWater.setSpeed(1);
        this.lottieAnimateWater.play();
      }, 2000);
    },
  },
  computed: {},
  watch: {},
  beforeRouteEnter(to, from, next) {
    next(vm => {
      Object.assign(vm.routeInfo, { to, from });
    });
  },
};
</script>

<style lang="scss" scoped>
@keyframes animate25 {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 -3000px;
  }
}
@keyframes animate48 {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 -5760px;
  }
}
.solution-wrapper {
  min-width: 1200px;
  background: #ffffff;
  margin-top: 72px;
  .water-wrapper {
    width: 100%;
    height: 1679px;
    background: #f6f7fa;
    position: relative;
    .water-title {
      text-align: center;
      font-size: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #343434;
      line-height: 32px;
      padding-top: 79px !important;
    }
    .water-info {
      width: 100%;
      height: 450px;
      background: url('~@/assets/images/solution/water-img.jpg') center
        no-repeat;
      background-size: cover;
      margin-top: 73px !important;

      .water-info-text {
        height: 450px;
        background: url('~@/assets/images/solution/water_text.svg');
        background-size: 100% 100%;
      }
    }
    .water-box {
      position: relative;
      height: 1000px;
      display: flex;
      justify-content: center;
      .water-detail {
        position: absolute;
        top: 125px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        z-index: 1;
        .water-item {
          cursor: pointer;
          width: 120px;
          height: 182px;
          position: relative;
          text-align: center;
          display: flex;
          flex-direction: column;
          &:nth-child(n + 5) {
            margin-top: 145px;
          }
          &:nth-child(n + 9) {
            margin-top: 100px;
          }
          &:nth-child(4n + 2) {
            margin-left: 220px;
          }
          &:nth-child(4n + 3) {
            margin-left: 200px;
          }
          &:nth-child(4n + 4) {
            margin-left: 230px;
          }
          .animate-25,
          .animate-48 {
            width: 120px;
            height: 120px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            opacity: 0;
            position: absolute;
            top: 0;
          }
          .bg {
            width: 120px;
            height: 120px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
            position: absolute;
            top: 0;
            opacity: 1;
          }
          .title {
            margin-top: 120px;
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #283780;
            line-height: 25px;
          }
          .desc {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #343434;
            line-height: 20px;
          }

          &:hover {
            .bg {
              opacity: 0;
              transition: all 0.5s ease;
            }
            .animate-25,
            .animate-48 {
              opacity: 1;
            }
            .animate-25 {
              animation: animate25 2s steps(25) infinite;
            }
            .animate-48 {
              animation: animate48 2s steps(48) infinite;
            }
          }
        }
      }
      .water-animate {
        position: absolute;
        top: 200px;
        width: 1046px;
        height: 624px;
        z-index: 0;
      }
    }
    .water-title,
    .water-box {
      width: 1200px;
      margin: 0 auto;
    }
  }
}
@media screen and (max-width: 1200px) {
  .home-swiper .swiper-bottom {
    padding-left: 0px;
  }
}

@media screen and (min-width: 1920px) {
  ::v-deep .home-swiper {
    .swiper-bottom {
      .swiper-container {
        width: 1560px;
        margin-left: 0;
      }
    }
  }
}
</style>
