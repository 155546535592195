<template>
  <div class="go-top" v-if="btnFlag" @click="backTop">
    <img src="@/assets/images/go_top.svg" alt="" />
    <div class="name">TOP</div>
  </div>
</template>

<script>
export default {
  name: 'PageBackTop',
  data() {
    return {
      btnFlag: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.scrollToTop);
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('scroll', this.scrollToTop);
    });
  },
  methods: {
    // 点击回到顶部方法，加计时器是为了过渡顺滑
    backTop() {
      const that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },

    // 为了计算距离顶部的高度，当高度大于72显示回顶部图标，小于72则隐藏
    scrollToTop() {
      const that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      that.btnFlag = that.scrollTop > 72;
    },
  },
};
</script>

<style lang="scss" scoped>
.go-top {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #2750d6;
  position: fixed;
  right: 15px;
  bottom: 80px;
  text-align: center;
  z-index: 999;
  cursor: pointer;
  img {
    width: 16px;
    height: 9px;
    margin-top: 10px;
  }
  .name {
    font-size: 12px;
    font-family: ArialMT;
    color: #ffffff;
    line-height: 14px;
    margin-top: 5px;
  }
}
</style>
