<template>
  <!-- 加入我们 -->
  <div class="join-box">
    <page-title
      :title="title"
      :describe="describe"
      data-aos="fade-down"
      data-aos-delay="100"
      class="join-title"
    ></page-title>
    <div class="join-tabs">
      <div
        :class="['item', { active: activeIndex == index }]"
        @click="changeTabIndex(index)"
        v-for="(item, index) in tabList"
        :key="index"
      >
        {{ item }}
      </div>
    </div>
    <div class="join-line" data-aos="fade-in" data-aos-delay="300"></div>
    <div class="swipper-box">
      <swiper :options="swiperOption" class="hc-swiper-container" ref="swiper">
        <swiper-slide v-for="(slide, index) in itemList" :key="index">
          <div class="swiper-center">
            <div
              class="item-bg"
              :style="`background-image: url(${itemBg(slide.bgType)})`"
              @mouseover="handleMouse(index)"
            >
              <div class="title">{{ slide.title }}</div>
              <div class="sub-title">{{ slide.subtitle }}</div>
              <div class="info" ref="jionUs">
                <div v-for="(j, k) in slide.content" :key="k">
                  <div class="text">{{ j.label }}</div>
                  <div class="sub-text" v-for="(v, i) in j.value" :key="i">
                    {{ v }}
                  </div>
                </div>
              </div>
            </div>
            <div class="item-hover"></div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </div>
  </div>
</template>
<script>
import PageTitle from '@/components/pageTitle/index';
import { joinUsList } from '../joinUsData';

export default {
  name: 'JoinUs',
  components: {
    PageTitle,
  },
  data() {
    return {
      title: '加入我们',
      describe: 'Join us',
      joinUsList,
      tabList: ['深圳', '青岛'],
      activeIndex: 0,
      pageIndex: 1,
      pageSize: 3,
      currIndex: null,
      isAnimation: -1,
      swiperOption: {
        map: null,
        initialSlide: 0,
        // slider之间的间隙
        spaceBetween: 15,
        // 可视slider的数量
        slidesPerView: 3,
        slidesPerGroup: 1,
        // 上下页切换
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        // 分页指示器
        // pagination: {
        //   el: '.swiper-pagination',
        //   clickable: true,
        // },
        // 循环方式：无限循环
        loop: false,
        // effect: 'fade',
        // 自动滚动
        // autoplay: {
        //   delay: 4000,
        //   disableOnInteraction: false,
        // },
        speed: 600,
        autoplay: false,
        centeredSlides: false,
        slidesOffsetBefore: 0,
      },
    };
  },
  mounted() {},
  computed: {
    swiper() {
      return this.$refs.swiper.swiper;
    },
    itemList() {
      return this.activeIndex === 0
        ? this.joinUsList.get('shenzhen')
        : this.joinUsList.get('qingdao');
    },
    contactList() {
      return this.itemList.slice(
        (this.pageIndex - 1) * this.pageSize,
        this.pageIndex * this.pageSize
      );
    },
    total() {
      return parseInt(this.itemList.length / 3);
    },
    itemBg() {
      return type => {
        return require(`@/assets/images/about/join/join_us_${type}.svg`);
      };
    },
  },
  methods: {
    handleMouse(i) {
      this.isAnimation = i;
    },
    changeTabIndex(index) {
      if (this.activeIndex !== index) {
        this.activeIndex = index;
      }
    },
  },
  watch: {
    activeIndex: {
      handler() {
        this.initialSlide = 0;
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 4px;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  width: 4px;
  background: transparent;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  width: 4px;
  border-radius: 5px;
  background-color: #2750d6;
}

::v-deep .swiper-slide {
  width: 390px !important;
  height: 600px;
  position: relative;
}

.swiper-container {
  width: 1200px;
  height: 700px;
  padding-top: 19px;
}

::v-deep .swiper-button-prev {
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-image: url('~@/assets/images/about/join/join_btn.svg');
  background-size: 100% 100%;
  transform: rotate(180deg);
  &:hover {
    background-image: url('~@/assets/images/about/join/join_btn_active.svg');
    transform: rotate(360deg);
  }
}

::v-deep .swiper-button-next {
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-image: url('~@/assets/images/about/join/join_btn.svg');
  background-size: 100% 100%;
  &:hover {
    cursor: pointer;
    width: 30px;
    height: 30px;
    background-image: url('~@/assets/images/about/join/join_btn_active.svg');
    transform: rotate(180deg);
  }
}

.join-box {
  min-width: 1200px;
  height: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  .join-title,
  .join-line,
  .join-tabs {
    width: 1200px;
    margin: 0 auto;
  }
  .join-title {
    margin-top: 44px;
    margin-bottom: 39px;
  }
  .join-line {
    height: 1px;
    background: #ffffff;
    border-top: 1px solid #888f97;
  }
  .join-tabs {
    height: 41px;
    display: flex;
    position: relative;
    .item {
      width: 100px;
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1c1c1c;
      line-height: 22px;
      margin-bottom: 19px;
      text-align: center;
      cursor: pointer;
    }
    .active {
      color: #2750d6;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 37px;
        left: 0;
        width: 100px;
        border-bottom: 4px solid #2750d6;
        transition: all 0.5s linear;
      }
    }
  }

  .swipper-box {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    .swiper-center {
      width: 390px;
      .item-bg {
        width: 390px;
        height: 600px;
        background-color: #ffffff;
        background-image: url('~@/assets/images/about/join/join_us_web.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        padding: 25px 10px 25px 25px;
        border: 1px solid #e5e8ed;

        .title {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #1c1c1c;
          line-height: 28px;
        }
        .info {
          width: 100%;
          height: 520px;
          overflow-y: hidden;
          box-sizing: border-box;
          padding-right: 10px;

          .sub-title {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #888f97;
            line-height: 17px;
            margin-top: 9px;
          }
          .text {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #1c1c1c;
            line-height: 24px;
            margin-top: 30px;
          }
          .sub-text {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #49525c;
            line-height: 24px;
            margin-bottom: 18px;
            text-align: justify;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .scroll-bar {
          overflow: auto;
        }
      }
      &:hover {
        position: relative;
        z-index: 0;
        .item-bg {
          border-color: #c4ccd7;
          .title {
            color: #2750d6;
          }
          .info {
            overflow-y: scroll;
            overflow-y: overlay;
          }
        }
        .item-hover {
          width: 336px;
          height: 59px;
          background: linear-gradient(
            270deg,
            rgba(39, 80, 214, 0.3) 0%,
            rgba(39, 126, 214, 0.09) 100%
          );
          border-radius: 3px;
          filter: blur(20px);
          z-index: -1;
          position: absolute;
          left: 28px;
          bottom: -10px;
        }
      }
    }
  }
}
</style>
