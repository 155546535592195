<template>
  <div :class="['solution', info.bgStyle]">
    <div class="content">
      <div :class="['title', info.titleStyle]">
        {{ info.title }}
      </div>
      <div :class="info.text.length > 0 ? 'text' : 'text-none'">
        {{ info.text }}
      </div>
      <div
        class="pic"
        :style="{ marginTop: info.mt }"
        data-aos="fade-up"
        data-aos-delay="600"
      >
        <img :src="info.img" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GraphicTextComp',
  props: {
    info: {
      type: Object,
      default: () => ({
        title: '',
        text: '',
        img: '',
        mt: '',
        bgStyle: '',
        titleStyle: '',
      }),
    },
  },
  data() {
    return {};
  },
  methods: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.solution {
  width: 100%;
  height: 1075px;
  color: '#ffffff';
  .content {
    width: 1200px;
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      height: 32px;
      font-size: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #1c1c1c;
      line-height: 32px;
      margin-top: 150px;
      margin-bottom: 60px;
    }
    .title-150-30 {
      margin-bottom: 30px;
    }
    .title-80-60 {
      margin-top: 80px;
    }
    .title-80-85 {
      margin-top: 80px;
      margin-bottom: 85px;
    }
    .title-80-160 {
      margin-top: 80px;
      margin-bottom: 160px;
    }
    .text {
      width: 800px;
      height: 106px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #49525c;
      line-height: 32px;
      text-align: center;
    }
    .text-none {
      height: 0;
    }
    .pic {
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.h830 {
  height: 830px;
}
.h900 {
  height: 900px;
}
.h920 {
  height: 920px;
}
.h1010 {
  height: 1010px;
}
.h1070 {
  height: 1070px;
}
.f7 {
  color: '#F7F7F7';
}
.mt155 {
  margin-top: 155px;
}
.mb70 {
  margin-bottom: 70px;
}
.mb80 {
  margin-bottom: 80px;
}
.mb90 {
  margin-bottom: 90px;
}
</style>
