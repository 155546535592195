<template>
  <div class="about-container">
    <page-header></page-header>
    <div class="about-target" id="target0"></div>
    <!-- 关于虹川 -->
    <about-hcttop></about-hcttop>
    <!-- 企业文化 -->
    <div class="about-target" id="target1"></div>
    <enterprise-culture></enterprise-culture>
    <!-- 企业资质 -->
    <div class="about-target" id="target2"></div>
    <enterprise-qualification></enterprise-qualification>
    <!-- 对外合作 -->
    <div class="about-target" id="target3"></div>
    <foreign-cooperation></foreign-cooperation>
    <!-- 加入我们 -->
    <div class="about-target" id="target4"></div>
    <join-us></join-us>
    <!-- 联系我们 -->
    <div class="about-target" id="target5"></div>
    <contact-us></contact-us>
    <!-- 返回顶部 -->
    <page-back-top></page-back-top>
    <!-- 底部 -->
    <page-footer></page-footer>
  </div>
</template>
<script>
import AboutHcttop from './components/AboutHcttop';
import EnterpriseCulture from './components/EnterpriseCulture';
import EnterpriseQualification from './components/EnterpriseQualification';
import ForeignCooperation from './components/ForeignCooperation';
import JoinUs from './components/JoinUs';
import ContactUs from './components/ContactUs';
import PageHeader from '@/components/PageHeader';
import PageFooter from '@/components/PageFooter';
import PageBackTop from '@/components/PageBackTop';

export default {
  name: 'about',
  components: {
    AboutHcttop,
    EnterpriseCulture,
    EnterpriseQualification,
    ForeignCooperation,
    JoinUs,
    ContactUs,
    PageHeader,
    PageFooter,
    PageBackTop,
  },
  data() {
    return {
      currIndex: 0,
    };
  },
  created() {
    const { target } = this.$router.currentRoute.query;
    this.currIndex = target ? target : 0;
  },
  mounted() {},
  methods: {
    scrollIntoView() {
      this.$nextTick(() => {
        document.getElementById(`target${this.currIndex}`).scrollIntoView({
          behavior: 'smooth', // 平滑过渡
          block: 'start', // 上边框与视窗顶部平齐。默认值
        });
      });
    },
    refTarget(v) {
      if (v) this.currIndex = v.target || 0;
    },
  },
  watch: {
    currIndex: {
      handler() {
        this.scrollIntoView();
      },
    },
    $route(v) {
      this.refTarget(v.query);
    },
  },
};
</script>
<style lang="scss" scoped>
.about-container {
  min-width: 1200px;
  margin-top: 72px;

  .about-target {
    position: relative;
    top: -80px;
    display: block;
    height: 0;
    overflow: hidden;
  }
}
</style>
