<template>
  <!-- 联系我们 -->
  <div
    class="contact-box"
    :style="`background-image: url(${activeIndex === 0 ? szImg : qdImg})`"
  >
    <div class="contact-content">
      <page-title
        :title="title"
        :describe="describe"
        data-aos="fade-down"
        data-aos-delay="100"
        :isBlack="false"
        class="contact-title"
      ></page-title>
      <div class="contact-tabs">
        <div
          :class="['item', { active: activeIndex == index }]"
          @click="changeTabIndex(index)"
          v-for="(item, index) in tabList"
          :key="index"
        >
          {{ item }}
        </div>
      </div>
      <div class="contact-line" data-aos="fade-in" data-aos-delay="300"></div>
      <div class="contact-list">
        <div class="item" v-for="(item, index) in contactList" :key="index">
          <div class="icon"><img :src="item.icon" alt="" /></div>
          <div class="info">{{ item.value }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageTitle from '@/components/pageTitle/index';

export default {
  name: 'ContactUs',
  components: {
    PageTitle,
  },
  data() {
    return {
      title: '联系我们',
      describe: 'Contact us',
      shenzhen: [
        {
          name: '电话',
          value: '0755 22218622 / 22218633',
          icon: require('@/assets/images/about/contact/icon_phone.svg'),
        },
        {
          name: '传真',
          value: '0755 22202959',
          icon: require('@/assets/images/about/contact/icon_fax.svg'),
        },
        {
          name: '邮箱',
          value: 'business@hcttop.com',
          icon: require('@/assets/images/about/contact/icon_email.svg'),
        },
        {
          name: '地址',
          value: '深圳市南山区科技园中区国人通信大厦A座13层',
          icon: require('@/assets/images/about/contact/icon_loc.svg'),
        },
      ],
      qingdao: [
        {
          name: '电话',
          value: '0532 82610059',
          icon: require('@/assets/images/about/contact/icon_phone.svg'),
        },
        {
          name: '邮箱',
          value: 'business@hcttop.com',
          icon: require('@/assets/images/about/contact/icon_email.svg'),
        },
        {
          name: '地址',
          value: '青岛市南区宁夏路288号青岛软件园1号楼1702室',
          icon: require('@/assets/images/about/contact/icon_loc.svg'),
        },
      ],
      tabList: ['深圳', '青岛'],
      activeIndex: 0,
      szImg: require('@/assets/images/about/contact/contact_us_bg.png'),
      qdImg: require('@/assets/images/about/contact/contact_us_qd_bg.png'),
    };
  },
  mounted() {},
  computed: {
    contactList() {
      return this.activeIndex === 0 ? this.shenzhen : this.qingdao;
    },
  },
  methods: {
    changeTabIndex(index) {
      if (this.activeIndex !== index) {
        this.activeIndex = index;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.contact-box {
  width: 100%;
  height: 800px;
  background-image: url('~@/assets/images/about/contact/contact_us_bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 1.5s ease-in-out;
  .contact-content {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    .contact-title {
      margin-top: 55px;
      margin-bottom: 115px;
    }
    .contact-tabs {
      height: 68px;
      display: flex;
      .item {
        width: 63px;
        font-size: 30px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.26);
        line-height: 42px;
        cursor: pointer;
        margin-right: 44px;
        &:last-child {
          margin-right: 0;
        }
      }
      .active {
        position: relative;
        color: #ffffff;
        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          width: 63px;
          border-bottom: 4px solid #ffffff;
          transition: all 0.5s linear;
        }
      }
    }
    .contact-line {
      width: 496px;
      height: 2px;
      background: #ffffff;
      border-bottom: 1px solid #888f97;
    }
    .contact-list {
      display: flex;
      flex-direction: column;
      margin-top: 31px;
      .item {
        display: flex;
        margin-bottom: 19px;
        .icon {
          width: 28px;
          height: 28px;
          margin-right: 33px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .info {
          font-size: 21px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 29px;
        }
      }
    }
  }
}
</style>
