<!--
 * Description: reauired, the file description, reference link, etc.
 * Created Date: 2021-01-20 2:38:59 PM
 * Author: Liu Man <lm@hcttop.com>
 * -----
 * Last Modified: 2021-01-20, 2:38:59 PM
 * Modified By: Liu Man <lm@hcttop.com>
 -->

<template>
  <div class="news-list-container">
    <div class="news-list-box">
      <div
        class="news-item"
        data-aos="fade-up"
        data-aos-delay="400"
        v-for="(item, index) in newsList"
        :key="index"
        @click="toNewsDetail(item.id)"
        @mouseenter="enter(index)"
        @mouseleave="leave(index)"
      >
        <div class="time">
          <div class="day">{{ getDayOfTime(item.publishTime) }}</div>
          <div class="month">{{ getYearMonthOfTime(item.publishTime) }}</div>
        </div>
        <div class="new-img">
          <el-image
            :src="getImgUrl(item.coverImage)"
            alt="新闻图片"
            fit="cover"
            class="hc-img"
          ></el-image>
        </div>
        <div class="new-content">
          <div class="title">{{ item.title }}</div>
          <div class="summary">{{ item.summary }}</div>
          <div class="news-info">
            <div class="info-item">
              <svg-icon icon-class="icon-source"></svg-icon>
              <div class="">来源：{{ item.source }}</div>
            </div>
            <div class="info-item">
              <svg-icon icon-class="icon-views"></svg-icon>
              <div class="">
                浏览量：{{
                  getViewCount(item.baseViewCount, item.realViewCount)
                }}
              </div>
            </div>
          </div>
        </div>
        <div :class="[item.checked ? 'active' : 'more']">
          <div class="seat" v-if="item.checked"></div>
          <svg-icon icon-class="icon-thenright"></svg-icon>
          <div class="text-title">了解更多</div>
        </div>
        <!-- <div class="more" v-show="!item.checked">了解更多</div> -->
      </div>
    </div>
    <div class="pagination">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[5, 10, 20, 50]"
        :page-size="pageSize"
        popper-class="select-down"
        :popper-append-to-body="false"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalPage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

import { getNewsList } from '@/api/news.js';
import { getImgUrl, getViewCount } from '../common';

export default {
  name: '',
  props: {
    newsType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      getImgUrl,
      getViewCount,
      currentPage: 1,
      pageSize: 10,
      totalPage: 1,
      newsList: [],
    };
  },
  methods: {
    /** 跳转到新闻详情页 */
    toNewsDetail(id) {
      if (id) {
        const { href } = this.$router.resolve({
          path: '/news/news-detail',
          query: {
            id,
          },
        });
        window.open(href, '_blank');
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    getDayOfTime(time) {
      return dayjs(time).format('DD');
    },
    getYearMonthOfTime(time) {
      return dayjs(time).format('YYYY-MM');
    },

    /** 请求数据 */
    queryNewsData(params) {
      getNewsList(params).then(res => {
        if (res.data.code == '200') {
          this.totalPage = res.data.data.total;
          const list = res.data.data.records;
          for (let i = 0; i < list.length; i += 1) {
            list[i].checked = false;
          }
          this.newsList = list;
        } else {
          // TODO 异常处理
        }
      });
    },
    // 鼠标移入
    enter(index) {
      for (let i = 0; i < this.newsList.length; i += 1) {
        if (this.newsList[i].checked) {
          this.newsList[i].checked = false;
        }
      }
      this.newsList[index].checked = true;
    },
    // 鼠标移出
    leave() {
      for (let i = 0; i < this.newsList.length; i += 1) {
        this.newsList[i].checked = false;
      }
    },
  },
  created() {
    const params = {
      current: this.currentPage,
      size: this.pageSize,
      type: this.newsType || '',
    };
    this.queryNewsData(params);
  },
  watch: {
    currentPage(nv) {
      const params = {
        current: nv,
        size: this.pageSize,
        type: this.newsType,
      };
      this.queryNewsData(params);
    },
    newsType(nv) {
      const params = {
        current: this.currentPage,
        size: this.pageSize,
        type: nv,
      };
      this.queryNewsData(params);
    },
  },
};
</script>

<style lang="less" scoped>
.news-list-container {
  padding-top: 80px;

  .news-list-box {
    .news-item {
      height: 236px;
      background: #f9f9fa;
      margin-bottom: 40px;
      display: flex;
      align-items: center;
      padding: 30px 0;
      cursor: pointer;
      .time {
        width: 194px;
        border-right: 1px solid #d9d9d9;
        text-align: center;
        .day {
          font-size: 42px;
          color: #6c6c6c;
        }
        .month {
          font-size: 18px;
          color: #b9b9b9;
        }
      }
      .new-img {
        margin: 0 50px;
        width: 235px;
        height: 176px;
        overflow: hidden;
        ::v-deep {
          img {
            height: inherit;
          }
        }
        .hc-img {
          width: 100%;
          height: 100%;
          transition: all 0.5s ease-in-out;
        }
      }
      .new-content {
        width: 450px;
        margin-right: 50px;
        .title {
          font-size: 20px;
          color: #000000;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          font-weight: 600;
        }
        .summary {
          height: 52px;
          margin: 10px 0 30px;
          padding: 10px 0;
          color: #343434;
          // 超出2行显示...
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .news-info {
          font-size: 14px;
          color: #7b7b7b;
          display: flex;
          .info-item {
            width: 160px;
            display: flex;
            align-items: center;
            .svg-icon {
              margin: 0 6px 0 0;
              transition-duration: 1500ms, 5s;
            }
          }
        }
      }
      .more {
        width: 104px;
        height: 40px;
        line-height: 40px;
        background: #ffffff;
        font-size: 16px;
        color: #343434;
        text-align: center;
        .svg-icon {
          position: absolute;
          left: 0px;
        }
        .text-title {
          display: block;
        }
      }
      &:hover {
        background: #ffffff;
        box-shadow: 0px 0px 20px 0px rgba(195, 193, 193, 0.5);
        .title {
          color: #2750d6;
        }
        .more {
          background: #2750d6;
          color: #fff;
        }
        .active {
          .svg-icon {
            transform: translateX(28px);
          }
        }
        .hc-img {
          transform: scale(1.1);
        }
      }
      .active {
        width: 104px;
        height: 40px;
        background: #2750d6;
        color: #fff;
        position: relative;
        .seat {
          width: 46px;
          height: 100%;
          background: #2750d6;
          position: absolute;
          z-index: 10;
          font-size: 12px;
        }
        .svg-icon {
          position: absolute;
          top: 11px;
          left: 20px;
          transition-duration: 600ms;
        }
        .text-title {
          display: none;
        }
      }
    }
  }

  .pagination {
    text-align: right;
    padding-bottom: 25px;

    ::v-deep {
      .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2750d6;
      }
    }
  }
}
</style>
<style lang="scss">
.select-down {
  border: none;
  .el-select-dropdown__item.selected {
    background: #2750d6;
    color: #fff;
  }
}
</style>
