/**
 * Description: 新闻中心的一些公共方法.
 * Created Date: 2021-01-22 4:48:57 PM
 * Author: Liu Man <lm@hcttop.com>
 * -----
 * Last Modified: 2021-01-22, 4:48:57 PM
 * Modified By: Liu Man <lm@hcttop.com>
 */

/** 拼接图片地址 */
function getImgUrl(url) {
  return `${process.env.VUE_APP_URL_ADDRESS}/hcttop-website${url}`;
}

/** 计算浏览量 */
function getViewCount(count1, count2) {
  const temp1 = count1 || 0;
  const temp2 = count2 || 0;
  return parseInt(temp1) + parseInt(temp2);
}

export { getImgUrl, getViewCount };
