<!--
 * Description: 组件：产品主页面（智慧水务/智慧环保/智慧园区）-导航列表
 * Created Date: 2020-06-24 7:30:23 PM
 * Author: Liu Man <lm@hcttop.com>
 * -----
 * Last Modified: 2021-01-22, 3:30:45 PM
 * Modified By: Liu Man <lm@hcttop.com>
 -->

<template>
  <div class="product-list" id="product-system">
    <div v-if="navList.length > 1" class="content list-content">
      <div class="pro-name">{{ navTitle }}</div>
      <div class="list-line" v-if="navList && navList.length > 0">
        <div
          class="item"
          v-for="(item, index) in navList"
          @click="activeCurNav(item)"
          :key="index"
          data-aos="fade-in"
          data-aos-delay="100"
        >
          <img class="img" :src="item.img" alt="" />
          <div class="name">{{ item.name }}</div>
          <div class="desc">{{ item.desc }}</div>
        </div>
      </div>
    </div>
    <div v-else class="function-list">
      <div class="function-title">
        <div class="title">{{ navList.name }}</div>
        <div class="sub-title" v-if="navList.subTitle">
          {{ navList.subTitle }}
        </div>
        <div class="desc">{{ navList.desc }}</div>
      </div>
      <div class="list-box">
        <div
          class="item"
          v-for="(item, index) in navList.functionList"
          :key="index"
          data-aos="fade-in"
          :data-aos-delay="index <= 2 ? '400' : '600'"
        >
          <div class="icon">
            <img :src="item.icon" alt="" />
          </div>
          <div class="info">
            <div class="name">{{ item.name }}</div>
            <div class="desc">{{ item.desc }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductContentListWater',
  props: ['navTitle', 'subTitle', 'navList'],
  data() {
    return {};
  },
  methods: {
    activeCurNav(nav) {
      this.$emit('activeCurNav', nav);
    },
  },
};
</script>

<style scoped lang="less">
@import '../../../assets/css/product';
</style>
