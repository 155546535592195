<!--
 * Description: 组件：跳转服务咨询
 * Created Date: 2021-01-25 1:48:48 PM
 * Author: Zhao xiaoqin <zxq@hcttop.com>
 * -----
 * Last Modified: 2021-02-24, 2:19:28 PM
 * Modified By: Zhao Boxing <zbx@hcttop.com>
 -->

<template>
  <!-- 跳转服务咨询 -->
  <div class="service-consultation">
    <!-- <div class="wave1"></div>
    <div class="wave2"></div>
    <div class="wave3"></div> -->
    <canvas id="canvas" ref="canvas"></canvas>
    <div class="content service-box">
      <div class="service-title">立即获取解决方案</div>
      <div @click="goContactUs" class="service-btn">
        <div class="btn-name">商务咨询</div>
        <img
          class="btn-icon"
          :src="require('@/assets/images/product/icon_arrow_right_blue.png')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import resize from '@/mixins/resize';

export default {
  mixins: [resize],
  name: 'ServiceConsultation',
  data() {
    return {};
  },
  mounted() {
    this.initAnimationWavyLine();
  },
  methods: {
    goContactUs() {
      this.$router.push('/contact');
      localStorage.setItem('CURRENT_ANCHOR', 'get-service');
    },
    initAnimationWavyLine() {
      const { CustomEase } = window;
      window.requestAnimFrame = (function() {
        return (
          window.requestAnimationFrame ||
          window.webkitRequestAnimationFrame ||
          window.mozRequestAnimationFrame ||
          window.oRequestAnimationFrame ||
          window.msRequestAnimationFrame ||
          function(/* function */ callback) {
            window.setTimeout(callback, 1000 / 60);
          }
        );
      })();

      function getRandomFloat(max) {
        return Math.random() * Math.floor(max);
      }

      // function getAmplitudeRandom(amplitude) {
      //   return amplitude * getRandomFloat(2);
      // }

      // var guiControls = new (function() {
      //   this.period = 1;
      //   this.amplitude = 25;
      //   this.waveSpeed = 5;
      // })();
      // var datGUI = new dat.GUI();

      // datGUI.add(guiControls, 'period', 1, 100);
      // datGUI.add(guiControls, 'amplitude', 1, 100);
      // datGUI.add(guiControls, 'waveSpeed', -20, 20);

      var canvas = document.getElementById('canvas');
      var ctx = canvas.getContext('2d');

      canvas.height = 200;
      canvas.width = document.body.clientWidth;

      var start = {
        x: -50,
        y: 100,
      };

      // var amplitude = 600;
      // var frequency = 6;
      var segments = 50;
      // var interval = frequency / segments;
      // var dampener = 6;
      var rippleOffset = 20;
      // var time = Date.now();
      var dateCoeff = Date.now() * 0.0000000000005;
      var amplituderef;
      var sinus = CustomEase.create(
        'sinus',
        'M0.4,181.7C292.3,67.5,380.2,1,532.2,1c215,0,266.6,156,637.2,156 s596.3-139.6,959.9-139.6C2718.8,17.4,3348,189,3792.8,189'
      );
      var sinusRef = CustomEase.create(
        'sinusRef',
        'M0.2,214.2C292.1,100,393.4,0.5,532.1,0.5s266.6,199,637.2,199s511.1-149.6,959.9-149.6 s1252.1,164.2,1759.1,164.2'
      );
      var sinusRef2 = CustomEase.create(
        'sinusRef2',
        'M0.1,0 C0,0 0.056,0.442 0.175,0.442 0.294,0.442 0.332,0 0.332,0 0.332,0 0.414,1 0.671,1 0.991,1 1,0.5 1,144'
      );

      var startBis = {
        x: -100,
        y: 100 + rippleOffset,
      };

      var startBis2 = {
        x: -50,
        y: 150 + rippleOffset,
      };

      const draw_line = function(
        i,
        amplitude,
        offset,
        start,
        lineWidth,
        dampener,
        sin
      ) {
        ctx.beginPath();
        ctx.moveTo(
          start.x,
          start.y +
            amplituderef * Math.sin(x / dateCoeff + i / getRandomFloat(4))
        );
        // ctx.moveTo( start.x, start.y + guiControls.amplitude * Math.sin( x / guiControls.period + ( i  / 5 ) ) );
        ctx.lineWidth = lineWidth || 2;
        ctx.strokeStyle = 'rgba(81, 107, 255, 1)';

        for (var x = 0; x < segments; x++) {
          var norm = 1 - x * segments;
          // var t = x / segments;
          dateCoeff = Date.now() * 0.0000000000005;

          amplituderef =
            amplitude * 1 + (Math.sin(dateCoeff) * Math.random()) / dampener;

          // console.log(sinus.getRatio(norm));
          //var y = amplituderef * Math.sin( x / guiControls.period + ( i  / 5 ) );
          // var y = (frequency * 1.7 + (dateCoeff) * 2.5) + (amplituderef / dampener * sinus.getRatio(norm));

          var y =
            amplituderef * Math.sin(x / (1 + sin(norm)) + i / 8 + sinus(norm));
          ctx.lineTo(start.x + x * segments, start.y + y);
        }

        ctx.stroke();
      };

      var i = 0;
      var j = 0;
      var k = 0;

      function render() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        draw_line(i, '60', '30', start, '2', '1', sinus);
        draw_line(j, '30', '40', startBis, '1', '5', sinusRef);
        draw_line(k, '15', '15', startBis2, '0.5', '5', sinusRef2);

        window.requestAnimFrame(render);

        i += 5 / 10;
        j += 4.5 / 10;
        k += 3.5 / 10;
      }

      render();
    },
  },
};
</script>

<style lang="less">
// 水波纹动画
@keyframes animate {
  0% {
    background-position: 0;
  }
  100% {
    background-position: 3840px;
  }
}
.service-consultation {
  height: 200px;
  background: #283780;
  position: relative;
  // 三条水波纹
  .wave1 {
    width: 100%;
    height: 175px;
    position: absolute;
    top: 18px;
    background: url('~@/assets/images/product/wave_01.png');
    background-size: auto 100%;
    animation: animate 10s linear infinite;
  }
  .wave2 {
    width: 100%;
    height: 124px;
    position: absolute;
    top: 57px;
    background: url('~@/assets/images/product/wave_02.png');
    background-size: auto 100%;
    animation: animate 13s linear infinite;
  }
  .wave3 {
    width: 100%;
    height: 146px;
    position: absolute;
    top: 16px;
    background: url('~@/assets/images/product/wave_03.png');
    background-size: auto 100%;
    animation: animate 16s linear infinite;
  }
  .service-box {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .service-title {
      height: 37px;
      margin: 38px 0;
      font-size: 28px;
      color: #ffffff;
      line-height: 37px;
    }
    .service-btn {
      width: 128px;
      height: 40px;
      cursor: pointer;
      margin-bottom: 47px;
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      position: inherit;
      position: relative;
      z-index: 10;
      .btn-name {
        flex: 1;
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        color: #2439b2;
        line-height: 40px;
      }
      .btn-icon {
        width: 23px;
        height: 9px;
        right: 30px;
        opacity: 0;
        position: absolute;
      }
      &:hover {
        .btn-name {
          transition: all 0.3s linear;
          padding-right: 35px;
        }
        .btn-icon {
          transition: all 0.3s ease-in;
          opacity: 1;
          right: 15px;
        }
      }
    }
  }
}
</style>
