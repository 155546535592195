/**
 * Description: 联系我们相关的接口
 * Created Date: 2021-01-20 5:57:19 PM
 * Author: Zhao xiaoqin <zxq@hcttop.com>
 * -----
 * Last Modified: 2021-01-20, 5:57:19 PM
 * Modified By: Zhao xiaoqin <zxq@hcttop.com>
 */

import http from '@/http';

// 联系我们
// const queryNewsListUrl = '/hcttop-website/sys/contact';
const contactUsUrl = '/contact/create';

// 联系我们
const contactUs = data => {
  return http({
    url: contactUsUrl,
    method: 'post',
    data,
  });
};

export { contactUs };
