<template>
  <div class="product-detail-container">
    <page-header></page-header>
    <page-banner-comp
      :bannerInfo="currDetail.bannerInfo"
      :scrollIndex="scrollIndex"
      @scrollTop="scrollTop"
    ></page-banner-comp>

    <!-- 产品组成 -->
    <div class="target-fix" id="target0"></div>
    <graphic-text-comp :info="currDetail.composition"></graphic-text-comp>

    <!-- 核心应用 -->
    <div class="target-fix" id="target1"></div>
    <core-application-comp
      :info="currDetail.application"
    ></core-application-comp>

    <!-- 产品价值 -->
    <div class="target-fix" id="target2"></div>
    <program-advantage-comp
      :info="currDetail.productValue"
    ></program-advantage-comp>

    <!-- 跳转商务咨询 -->
    <business-consulting></business-consulting>
    <page-footer></page-footer>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageFooter from '@/components/PageFooter';
import PageBannerComp from '../product/components/PageBannerComp';
import GraphicTextComp from '../product/components/GraphicTextComp';
import CoreApplicationComp from '../product/components/CoreApplicationComp';
import ProgramAdvantageComp from '../product/components/ProgramAdvantageComp';
import BusinessConsulting from '../product/components/BusinessConsulting';
import { waterCaseList } from './caseData';

export default {
  name: 'Detail',
  components: {
    PageHeader,
    PageFooter,
    PageBannerComp,
    GraphicTextComp,
    CoreApplicationComp,
    ProgramAdvantageComp,
    BusinessConsulting,
  },
  data() {
    return {
      scrollIndex: 0,
      targets: [],
      currDetail: {},
      currType: 'water',
    };
  },
  created() {
    const { type, name } = this.$router.currentRoute.query;
    this.currType = type;
    switch (type) {
      case 'water':
        this.currDetail = waterCaseList.get(name);
        break;
      default:
        this.currDetail = {};
        break;
    }
  },
  mounted() {
    this.getTargets();
    window.addEventListener('resize', () => {
      this.getTargets();
    });
  },
  methods: {
    getTargets() {
      this.$nextTick(() => {
        let arr = [];
        for (let i = 0; i < 3; i++) {
          let doom = document.getElementById(`target${i}`);
          arr.push(doom.offsetTop);
        }
        this.targets = arr;
      });
    },
    scrollTop(val) {
      let index = 0;
      if (val >= this.targets[0]) {
        if (val >= this.targets[1]) index = 1;
        if (val >= this.targets[2]) index = 2;
      }
      this.scrollIndex = index;
    },
  },
};
</script>

<style lang="scss" scoped>
.product-detail-container {
  min-width: 1200px;
  margin-top: 72px;
  .target-fix {
    position: relative;
    top: -80px;
    display: block;
    height: 0;
    overflow: hidden;
  }
}
</style>
