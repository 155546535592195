<!--
 * Description: 组件：解决方案主页面（智慧水务、智慧环保、智慧园区）顶部导航切换
 * Created Date: 2021-01-14 3:17:12 PM
 * Author: Zhao xiaoqin <zxq@hcttop.com>
 * -----
 * Last Modified: 2021-01-22, 3:30:45 PM
 * Modified By: Liu Man <lm@hcttop.com>
 -->

<template>
  <div class="product-nav">
    <div class="content nav-box">
      <div class="nav-left">
        <div class="nav-title">解决方案</div>
        <div class="banner-nav">
          <div
            v-for="(item, index) in navList"
            :key="index"
            :class="[
              'ban-nav-item',
              {
                'ban-nav-cur': activeLink == item.routeName,
                lastBorder: index === navList.length - 1,
              },
            ]"
          >
            <router-link :to="{ path: item.url }">
              <div
                :class="[
                  'icon-right',
                  {
                    fade: activeLink == item.routeName,
                    last: lastLink === item.routeName,
                  },
                ]"
              >
                <img
                  :src="require('@/assets/images/product/icon_arrow_right.png')"
                />
              </div>

              <div
                :class="[
                  'item-right',
                  {
                    fade: activeLink == item.routeName,
                    last: lastLink === item.routeName,
                  },
                ]"
              >
                <div class="item-icon">
                  <img :src="item.icon" />
                </div>
                <div class="item-tips">{{ item.name }}</div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <div class="nav-right" data-aos="fade-in" data-aos-delay="100">
        <div class="nav-title">{{ navInfo.title }}</div>
        <div class="nav-desc" v-html="navInfo.desc"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

export default {
  name: 'ProductContentNav',
  props: {
    route: { type: Object },
    navInfo: { type: Object },
  },
  data() {
    return {
      activeLink: -1,
      lastLink: 'ProductPark',
      navList: [
        {
          routeName: 'ProductWater',
          name: '智慧水务',
          url: '/wisdom-water-product',
          icon: require('@/assets/images/product/nav_icon_01.svg'),
        },
        {
          routeName: 'ProductZeroCarbon',
          name: '智慧零碳',
          url: '/wisdom-zero-carbon-product',
          icon: require('@/assets/images/product/nav_icon_03.svg'),
        },
        {
          routeName: 'ProductEnv',
          name: '智慧环保',
          url: '/wisdom-env-product',
          icon: require('@/assets/images/product/nav_icon_02.svg'),
        },
        // {
        //   routeName: 'ProductPark',
        //   name: '智慧园区',
        //   url: '/wisdom-park-product',
        //   icon: require('@/assets/images/product/nav_icon_03.svg'),
        // },
      ],
    };
  },

  watch: {
    route: {
      handler(route) {
        this.$nextTick(() => {
          if (route.from.name || route.to.name) {
            this.lastLink = route.from.name;
            this.activeLink = route.to.name;
            console.log(this.lastLink, this.activeLink);
          }
        });
      },
      immediate: true,
    },
  },
  mounted() {
    this.initActiveLink();
  },
  methods: {
    initActiveLink() {
      switch (this.$route.name) {
        case 'ProductWater':
          this.activeLink = 0;
          break;
        case 'ProductPark':
          this.activeLink = 2;
          break;
        case 'ProductEnv':
          this.activeLink = 1;
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style scoped lang="less">
@import '~@/assets/css/global';

@keyframes marginLeftForwards {
  from {
    margin-left: 0;
  }
  to {
    margin-left: 188px;
  }
}

@keyframes marginLeft {
  from {
    margin-left: 188px;
  }
  to {
    margin-left: 0;
  }
}

@keyframes leftAndOpcity {
  from {
    opacity: 1;
    left: 25px;
  }
  to {
    opacity: 0;
    left: 0px;
  }
}
@keyframes leftAndOpcityForwards {
  from {
    opacity: 0;
    left: 0px;
  }
  to {
    opacity: 1;
    left: 27px;
  }
}

// 动画
@keyframes move {
  0% {
    margin-left: 0px;
  }
  50% {
    margin-left: 92px;
  }
  100% {
    margin-left: 184px;
  }
  to {
    margin-left: 184px;
  }
}

.product-nav {
  height: 500px;
  padding-top: 114px;
  // background-color: #283780;
  background: url('~@/assets/images/banner_bg_product.png') center no-repeat;
  background-size: cover;

  .nav-box {
    display: flex;
    .nav-left {
      .nav-title {
        height: 158px;
        line-height: 158px;
        font-size: 32px;
        color: #fff;
      }
      .banner-nav {
        width: 360px;
        height: 176px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        .ban-nav-item {
          width: 100%;
          flex: 1;
          // height: 56px;
          border-top: 1px solid rgba(255, 255, 255, 0.5);

          &:last-child {
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);
          }
          a {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            padding-left: 12px;
            .icon-right {
              position: relative;
              left: -27px;
              width: 23px;
              height: 9px;
              color: #fff;
              opacity: 0;
              &.fade {
                animation: leftAndOpcityForwards 0.3s linear forwards;
              }
              &.last {
                animation: leftAndOpcity 0.3s linear;
              }
            }
            .fade {
              opacity: 1;
              left: -27px;
            }
            img {
              display: flex;
              width: 100%;
              height: 100%;
            }
            .item-right {
              display: flex;
              align-items: center;
              .item-icon {
                width: 36px;
                height: 36px;
                margin-right: 20px;
              }
              .item-tips {
                font-size: 14px;
                color: rgba(255, 255, 255, 0.7);
              }
              &.fade {
                animation: marginLeftForwards 0.3s linear forwards;
              }
              &.last {
                animation: marginLeft 0.3s linear;
              }
            }
          }
        }
        .ban-nav-item.ban-nav-cur,
        .ban-nav-item.ban-nav-cur + .ban-nav-item {
          border-top-color: #fff;
        }
        .ban-nav-item.ban-nav-cur.lastBorder {
          border-bottom-color: #fff;
        }
        .ban-nav-cur,
        .ban-nav-item:hover {
          color: #fff;
          cursor: pointer;
          .item-tips {
            color: #fff;
          }
        }
        .ban-nav-cur,
        .ban-nav-item:focus {
          cursor: pointer;
        }
      }
    }
    .nav-right {
      // flex: 1;
      width: 830px;
      margin-left: 118px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .nav-title {
        font-size: 24px;
        color: #ffffff;
      }
      .nav-desc {
        margin-top: 30px;
        font-size: 12px;
        color: #cfcfcf;
        line-height: 20px;
      }
    }
  }
}
</style>
