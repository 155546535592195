<template>
  <!-- 对外合作 -->
  <div class="foreign-cooperation">
    <div class="content-box">
      <page-title
        :title="title"
        :describe="describe"
        data-aos="fade-down"
        data-aos-delay="100"
        class="cooperation-head"
      ></page-title>
      <div class="info-box">
        <img
          src="@/assets/images/about/foreign_cooperation.png"
          alt="合作伙伴"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/pageTitle/index';

export default {
  name: 'ForeignCooperation',
  components: { PageTitle },
  data() {
    return {
      title: '对外合作',
      describe: 'Foreign cooperation',
    };
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
@keyframes up {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 -720px;
  }
}
.foreign-cooperation {
  min-width: 1200px;
  height: 720px;
  background: #f9f9f9;
  .content-box {
    width: 100%;
    height: 100%;
    background: url('~@/assets/images/about/foreign_cooperation_bg.png');
    background-position: center;
    background-size: cover;
    animation: up 30s linear infinite;
    .cooperation-head {
      width: 1200px;
      margin: 0 auto;
      padding-top: 60px;
      margin-bottom: 49px;
    }
    .info-box {
      width: 1200px;
      margin: 0 auto;
      position: relative;
      top: -55px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
