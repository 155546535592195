<template>
  <div class="container-wrapper">
    <div class="list-info" v-for="(v, i) in listInfo" :key="i">
      <div class="list-center">
        <div :id="`zero${i}`"></div>
        <PageTitle :title="v.title" :describe="v.describe" class="title" />
        <div class="list-container">
          <div
            class="container-left"
            :style="
              v.isLeft
                ? `background-image: url(${v.listImg});float:right`
                : `background-image: url(${v.listImg});float:left`
            "
          ></div>
          <div
            class="container-right"
            :style="v.isLeft ? 'float:left' : 'float:right'"
          >
            <!-- 左侧列表 -->
            <div class="list-nav-detail">
              <div
                class="list-detail"
                v-for="(item, index) in v.listLeft"
                :key="index"
              >
                <div class="point"></div>
                <div class="list-nav">{{ item.text }}</div>
              </div>
            </div>
            <!-- 右侧列表 -->
            <div class="list-nav-detail">
              <div
                class="list-detail"
                v-for="(item, index) in v.listRight"
                :key="index"
              >
                <div class="point"></div>
                <div class="list-nav">{{ item.text }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/pageTitle/index';
export default {
  components: { PageTitle },
  props: {
    isLeft: {},
  },
  name: '',
  data() {
    return {
      listInfo: [
        {
          title: '政府零碳监管服务',
          describe:
            '政府零碳监管服务面向政府、园区等管理者提供双碳工作的碳监管、碳达峰分析、碳达峰碳中和规划等软件产品。',
          // 图片是否在左侧
          isLeft: false,
          listImg: require('@/assets/images/solution/zero-carbon/zero_carbon-01.jpg'),
          listLeft: [
            { text: '城市零碳驾驶舱' },
            { text: '碳监测与智能分析系统' },
            { text: '温室气体清单动态管理系统' },
            { text: '企业动态画像' },
            { text: '零碳分析与规划系统' },
            { text: '减排措施库管理系统' },
            { text: '碳中和举措协同管理系统' },
            { text: 'CCER项目管理系统' },
          ],
          listRight: [
            { text: '碳排放配额分配系统' },
            { text: '碳汇管理系统' },
            { text: '零碳综合调度系统' },
            { text: '碳排放因子数据库管理系统' },
            { text: '零碳资源目录管理系统' },
            { text: '循环经济评估考核系统' },
            { text: 'AI辅助決策系统' },
            { text: '……' },
          ],
        },
        {
          title: '行业(企业)零碳服务',
          describe:
            '行业(企业)零碳服务面向重点行业企业提供双碳工作的碳排放监测、碳核算、碳资产管理和碳规划等软件产品。',
          // 图片是否在左侧
          isLeft: true,
          listImg: require('@/assets/images/solution/zero-carbon/zero_carbon-02.jpg'),
          listLeft: [
            { text: '企业碳监测与智能分析系统' },
            { text: '企业碳资产管理系统' },
            { text: '企业碳配额服务系统' },
            { text: '企业碳核查系统' },
            { text: '企业CCER项目管理系统' },
            { text: '企业零碳情景分析系统' },
            { text: '企业碳减排措施库管理系统' },
            { text: '企业碳考核管理系统' },
          ],
          listRight: [
            { text: '企业碳价值分析系统' },
            { text: 'LCA产品碳足迹' },
            { text: '企业量化风险监控系统' },
            { text: '企业碳环境影响评价系统' },
            { text: '企业CDP碳信息披露系统' },
            { text: '企业碳排放交易支撑系统' },
            { text: '企业碳管家' },
            { text: '……' },
          ],
        },
        {
          title: '碳金融服务',
          describe:
            '碳金融服务面向金融机构和企业提供关于低碳项目评级、监管、投资分析、项目跟踪管理等软件产品。',
          // 图片是否在左侧
          isLeft: false,
          listImg: require('@/assets/images/solution/zero-carbon/zero_carbon-04.jpg'),
          listLeft: [
            { text: '碳信息披露' },
            { text: 'ESG评估' },
            { text: '碳汇投资服务系统' },
          ],
          listRight: [],
        },
        {
          title: '公众零碳服务',
          describe:
            '公众零碳服务面向社会公众提供绿色消费、碳账户、碳足迹计算器等碳普惠软件产品。',
          // 图片是否在左侧
          isLeft: true,
          listImg: require('@/assets/images/solution/zero-carbon/zero_carbon-03.jpg'),
          listLeft: [
            { text: '碳普惠管理系统' },
            { text: '个人低碳服务系统' },
            { text: '绿色消费服务系统' },
            { text: '碳足迹计算器' },
          ],
          listRight: [],
        },
      ],
      currIndex: 0,
    };
  },
  created() {
    const { target } = this.$router.currentRoute.query;
    this.currIndex = target || 0;
  },
  mounted() {},
  methods: {
    scrollIntoView() {
      this.$nextTick(() => {
        document.getElementById(`zero${this.currIndex}`).scrollIntoView({
          behavior: 'smooth', // 平滑过渡
          block: 'start', // 上边框与视窗顶部平齐。默认值
        });
      });
    },
  },
  computed: {},
  watch: {
    currIndex: {
      handler() {
        this.scrollIntoView();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.container-wrapper {
  .list-info {
    height: 660px;
    width: 100%;
    &:nth-child(2n) {
      background: #f9f9f9;
    }
    .list-center {
      width: 1200px;
      margin: 0 auto;
      overflow: hidden;
      .title {
        margin-top: 80px;
      }
      .list-container {
        margin-top: 60px;
        .container-left {
          width: 560px;
          height: 370px;
          background-size: 100% 100%;
        }
        .container-right {
          width: 640px;
          height: 370px;
          background: #ffffff;
          border: 1px solid #eaedf1;
          display: flex;
          .list-nav-detail {
            width: 236px;
            height: 300px;
            margin-top: 50px;
            margin-left: 50px;
          }
          .list-detail {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            .point {
              width: 5px;
              height: 5px;
              background: #2750d6;
              border-radius: 50%;
              margin-right: 12px;
            }
            .list-nav {
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #343434;
            }
          }
        }
      }
    }
  }
}
</style>
