<template>
  <!-- 企业文化 -->
  <div class="culture-box">
    <page-title
      :title="title"
      :describe="describe"
      class="cul-title"
    ></page-title>
    <div class="cul-content">
      <div
        class="cul-item"
        v-for="(item, index) in filterList"
        :key="index"
        @mouseenter="enter(index)"
      >
        <div :class="['info-box', item.checked ? 'text-checked' : '']">
          <div class="item-name">{{ item.name }}</div>
          <div class="item-text" v-html="item.text"></div>
        </div>
        <img
          :class="['item-imgBg', item.checked ? 'bg-checked' : '']"
          :src="itemBg"
          alt=""
        />
        <img
          :class="['item-imgRight', item.checked ? 'img-checked' : '']"
          :src="item.imgHover"
          alt=""
        />
        <img
          :style="`opacity: ${item.checked ? 0 : 1}`"
          :src="item.img"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/pageTitle/index';

export default {
  name: 'EnterpriseCulture',
  components: {
    PageTitle,
  },
  data() {
    return {
      title: '企业文化',
      describe: 'Enterprise culture',
      imgList: [
        {
          name: '公司愿景',
          text: '数智引领绿色产业变革',
          img: require('@/assets/images/about/culture/culture_01.png'),
          imgHover: require('@/assets/images/about/culture/culture_hover_01.png'),
          checked: true,
        },
        {
          name: '发展目标',
          text: '业界领先智慧型数字技术<br/>综合服务商',
          img: require('@/assets/images/about/culture/culture_02.png'),
          imgHover: require('@/assets/images/about/culture/culture_hover_02.png'),
          checked: false,
        },
        {
          name: '经营法则',
          text: '精英治理、共创共享<br/>持续完善、务求至臻',
          img: require('@/assets/images/about/culture/culture_03.png'),
          imgHover: require('@/assets/images/about/culture/culture_hover_03.png'),
          checked: false,
        },
        {
          name: '产业板块',
          text: '智慧零碳、智慧水务',
          img: require('@/assets/images/about/culture/culture_04.png'),
          imgHover: require('@/assets/images/about/culture/culture_hover_04.png'),
          checked: false,
        },
        {
          name: '企业文化',
          text: '品质责任、开放包容<br/>创新创造、至臻至善',
          img: require('@/assets/images/about/culture/culture_05.png'),
          imgHover: require('@/assets/images/about/culture/culture_hover_05.png'),
          checked: false,
        },
        {
          name: '核心价值观',
          text: '以人为本、以客户为中心<br/>以价值创造为根本',
          img: require('@/assets/images/about/culture/culture_06.png'),
          imgHover: require('@/assets/images/about/culture/culture_hover_06.png'),
          checked: false,
        },
      ],
      activeIndex: 0,
      itemBg: require('@/assets/images/about/culture/culture_hover_bg.png'),
      currIndex: 0,
    };
  },
  methods: {
    enter(index) {
      this.activeIndex = index;
    },
  },
  computed: {
    filterList() {
      return this.imgList.map((e, i) => ({
        ...e,
        checked: this.activeIndex === i ? true : false,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.culture-box {
  width: 1200px;
  height: 720px;
  margin: 0 auto;
  .cul-title {
    margin-top: 50px;
    margin-bottom: 23px;
  }
  .cul-content {
    display: flex;
    flex-wrap: wrap;
    .cul-item {
      width: 400px;
      height: 298px;
      position: relative;
      overflow: hidden;
      img {
        width: 400px;
        height: 245px;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
      }
      .item-imgBg {
        opacity: 0;
      }
      .item-imgRight {
        width: 400px;
        height: 298px;
        position: absolute;
        bottom: -100px;
        right: -1px;
        opacity: 0;
        transform: unset;
      }
      .info-box {
        position: absolute;
        top: 120px;
        left: 20px;
        z-index: 2;
        transition: transform 0.5s ease;
        .item-name {
          font-size: 28px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #313a44;
          line-height: 40px;
        }
        .item-text {
          margin-top: 38px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #49525c;
          line-height: 28px;
        }
      }
      .text-checked {
        transform: translateY(-50px);
        opacity: 1;
        transition: transform 0.5s ease;
        .info-box,
        .item-name,
        .item-text {
          color: #ffffff;
        }
      }
      .bg-checked {
        opacity: 1;
        transition: all 0.5s ease;
      }
      .img-checked {
        bottom: 0;
        opacity: 1;
        transition: all 0.5s ease;
      }
    }
  }
}
</style>
