<!--
 * Description: 组件：方案介绍
 * Created Date: 2021-01-22 9:02:46 AM
 * Author: Zhao xiaoqin <zxq@hcttop.com>
 * -----
 * Last Modified: 2021-01-22, 3:30:45 PM
 * Modified By: Liu Man <lm@hcttop.com>
 -->

<template>
  <div
    class="scheme-introduction"
    :style="
      `background:url(${introduction.bg}) no-repeat center center;background-size: cover;`
    "
    id="scheme-introduction"
  >
    <div class="content introduction">
      <div class="left-introduce" data-aos="fade-in" data-aos-delay="100">
        <div class="line"></div>
        <div class="title">方案介绍</div>
        <div class="label">业务痛点</div>
        <div class="point-list">
          <div
            class="item"
            v-for="(item, index) in introduction.importantPoint"
            :key="index"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="label">方案描述</div>
        <div class="desc">{{ introduction.description }}</div>
      </div>
      <div class="right-features" data-aos="fade-in" data-aos-delay="400">
        <div class="features-list">
          <div
            :style="
              `width:${
                introduction.featuresList.length == 3 &&
                index == introduction.featuresList.length - 1
                  ? '100%'
                  : '280px'
              };padding-top:${
                introduction.featuresList.length == 3 &&
                index == introduction.featuresList.length - 1
                  ? '96px'
                  : index >= 2
                  ? '75px'
                  : '20px'
              }`
            "
            :class="[
              'item',
              { bottom: introduction.isSpecial && index >= 2 },
              { top: introduction.isSpecial && index < 2 },
            ]"
            v-for="(item, index) in introduction.featuresList"
            :key="index"
          >
            <div class="name">{{ item.name }}</div>
            <div
              class="desc"
              :style="
                `width:${
                  introduction.featuresList.length == 3 &&
                  index == introduction.featuresList.length - 1
                    ? '522px'
                    : '160px'
                }`
              "
            >
              {{ item.desc }}
            </div>
          </div>
          <div class="animate-box">
            <div
              class="circle-bg"
              :style="`background-image: url(${circleBg})`"
            ></div>
            <div
              class="animate-img"
              :style="
                `background-image: url(${
                  introduction.featuresList.length == 3
                    ? animateImg1
                    : animateImg2
                })`
              "
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SchemeIntroduction',
  props: ['introduction'],
  data() {
    return {
      circleBg: require('@/assets/images/product/fanganjiesao_circle.png'),
      animateImg1: require('@/assets/images/product/fanganjiesao_img_01.png'),
      animateImg2: require('@/assets/images/product/fanganjiesao_img_02.png'),
    };
  },
};
</script>

<style lang="less">
@import '../../../assets/css/global';
@import '../../../assets/css/product';
</style>
