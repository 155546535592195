<template>
  <!-- 产品中心-智慧零碳 -->
  <div class="product-zero-carbon">
    <page-header></page-header>
    <div class="top-banner">
      <div class="banner-info">
        <div class="banner-text">
          <div class="banner-title">智慧零碳软件产品</div>
          <!-- <div class="banner-solid"></div> -->
          <div class="banner-desc">
            用数智技术全面支撑政府、企业与全社会的碳达峰、碳中和信息化
          </div>
        </div>
      </div>
    </div>
    <Container />
    <page-footer></page-footer>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageFooter from '@/components/PageFooter';
import Container from './components/list';
export default {
  components: { PageHeader, PageFooter, Container },
  name: 'ZeroCarbon',
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.product-zero-carbon {
  margin-top: 72px;
  min-width: 1200px;
  .top-banner {
    width: 100%;
    height: 425px;
    background: linear-gradient(to right, #d8e2ee 50%, #ecf5fe 50%);
    .banner-info {
      width: 100%;
      height: 100%;
      background: url('~@/assets/images/solution/banner-top.jpg') center
        no-repeat;
      background-size: 1920px 425px;
      overflow: hidden;
      .banner-text {
        width: 1200px;
        margin: 0 auto;
        .banner-title {
          margin-top: 104px;
          font-size: 42px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #101010;
        }
        .banner-solid {
          margin-top: 24px;
          width: 40px;
          height: 1px;
          background: #101010;
          border-radius: 2px;
        }
        .banner-desc {
          margin-top: 24px;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #101010;
        }
      }
    }
  }
}
</style>
