<template>
  <!-- 关于虹川 -->
  <div class="about-banner-box">
    <div class="about-banner">
      <div class="about-content">
        <page-title
          :title="title"
          :describe="describe"
          data-aos="fade-down"
          data-aos-delay="100"
          class="about-title"
          :isBlack="false"
        ></page-title>
        <div
          class="company-profile"
          v-html="companyProfile"
          data-aos-delay="100"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/pageTitle/index';
export default {
  name: 'AboutHcttop',
  components: { PageTitle },
  data() {
    return {
      title: '关于虹川',
      describe: '',
      companyProfile:
        '虹川科技成立于2015年，总部位于中国深圳，我国数字经济、双碳产业、生态文明融合创新发展的探索者和践行者，致力于为客户提供持续完善的数字化综合解决方案，赋能客户创造可持续发展价值。虹川科技基于“一体两翼两融合双总部”发展战略，目标发展成为业界领先智慧型数字技术综合服务商。<br/><br/>虹川科技是一家拥有完整技术链和完整服务链公司，主营业务为智慧零碳和智慧水务，依靠自身大数据、云计算、物联网、人工智能、区块链等技术在产业领域应用累积，通过数字技术驱动产业创新发展，重塑价值链体系，引领产业智慧变革。<br/><br/>虹川科技高度重视人才和团队建设，全面设立和实施“优秀合伙人”、“股权激励”和“绩效奖励”计划，以价值创造为根本，以成就共同理想为目标，努力打造一家具有远大梦想和奋斗者基因的公司。<br/><br/>虹川科技始终坚持以“科技赋能、产业报国”为使命，高度重视科技创新和产业价值并重发展，努力创建具有高度竞争力和良好美誉度的业界领导品牌。',
      activeIndex: 0,
      checkedImg: require('@/assets/images/about/title_hover.png'),
    };
  },
  created() {},
  mounted() {},
  methods: {
    enter(index) {
      this.activeIndex = index;
    },
  },
  computed: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
.about-banner-box {
  min-width: 1200px;
  height: 620px;
  background: linear-gradient(to right, #1c45a1 50%, #2e56c5 50%);
  .about-banner {
    width: 100%;
    height: 620px;
    background: url('~@/assets/images/about/about_banner_bg.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .about-content {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      ::v-deep .about-title {
        padding-top: 70px;
        margin-bottom: 40px;
        font-size: 56px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 1px;
      }
      .company-profile {
        width: 610px;
        height: 342px;
        text-align: justify;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
      }
    }
  }
}
</style>
