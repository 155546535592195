/**
 * Description: reauired, the file description, reference link, etc.
 * Created Date: 2021-01-27 2:11:15 PM
 * Author: Hu Yongjun <hyj@hcttop.com>
 * -----
 * Last Modified: 2021-01-27, 2:11:15 PM
 * Modified By: Hu Yongjun <hyj@hcttop.com>
 */

import Vue from 'vue';

import MetaInfo from 'vue-meta-info';
import VueParticles from 'vue-particles';
import VueScrollTo from 'vue-scrollto';

Vue.use(MetaInfo);

Vue.use(VueParticles);
Vue.use(VueScrollTo);
