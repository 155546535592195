<!--
 * Description: 组件：详情内容
 * Created Date: 2021-01-25 4:48:46 PM
 * Author: Zhao xiaoqin <zxq@hcttop.com>
 * -----
 * Last Modified: 2021-01-25, 6:50:36 PM
 * Modified By: Liu Man <lm@hcttop.com>
 -->

<template>
  <!-- 详情内容 -->
  <div class="detail-content">
    <!-- 系统架构 -->
    <div class="sys-architecture">
      <div class="content architecture">
        <div class="area-title">
          系统架构
        </div>
        <div class="jiagou-img" data-aos="fade-up" data-aos-delay="200">
          <img :src="currentDetail.sysArchImg" alt="" />
        </div>
      </div>
    </div>
    <!-- 系统功能 -->
    <div class="sys-function">
      <div class="content function">
        <div class="area-title">系统功能</div>
        <div class="function-list">
          <div
            class="item"
            :style="
              `width:${currentDetail.functionList.length == 3 ? '30%' : '25%'}`
            "
            v-for="(item, index) in currentDetail.functionList"
            :key="index"
            data-aos="fade-up"
            :data-aos-delay="400"
          >
            <div class="icon">
              <img :src="item.icon" alt="" />
            </div>
            <div class="info">
              <div class="name">{{ item.name }}</div>
              <div class="desc">{{ item.desc }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 系统界面 -->
    <div class="sys-interface">
      <div class="content interface">
        <div class="area-title">系统界面</div>
        <div class="views" data-aos="fade-up" data-aos-delay="600">
          <img :src="currentDetail.sysViews" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    currentDetail: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="less">
@import '../../../assets/css/global';
.detail-content {
  .area-title {
    height: 38px;
    padding: @section-title-top 0;
    font-size: 28px;
    color: #000000;
    line-height: 37px;
    text-align: center;
    letter-spacing: 2px;
    box-sizing: content-box;
  }
  // 系统架构
  .sys-architecture {
    .architecture {
      padding-bottom: 70px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .jiagou-img {
        // width: 962px;
      }
    }
  }
  // 系统功能
  .sys-function {
    background: #f0f1f5;
    .function {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .function-list {
        width: 100%;
        padding: 30px 0 50px;
        flex-wrap: wrap;
        display: flex;
        justify-content: center;
        align-items: center;
        .item {
          height: 130px;
          margin-bottom: 40px;
          display: flex;
          .icon {
            width: 80px;
            height: 80px;
          }
          .info {
            display: flex;
            flex-direction: column;
            .name {
              height: 20px;
              line-height: 20px;
              margin-bottom: 16px;
              font-size: 18px;
              color: #000000;
            }
            .desc {
              width: 250px;
              max-height: 74px;
              font-size: 14px;
              color: #343434;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
  // 系统界面
  .sys-interface {
    height: 580px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.1) 20%,
      rgba(242, 246, 248, 0.5) 40%,
      #d7e3ed 100%
    );
    .interface {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .views {
        width: 1112px;
        height: 300px;
        margin: 20px 0 60px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
