<!--
 * Description:新闻中心.
 * Created Date: 2021-01-19 11:33:45 AM
 * Author: Liu Man <lm@hcttop.com>
 * -----
 * Last Modified: 2021-01-19, 11:33:45 AM
 * Modified By: Liu Man <lm@hcttop.com>
 -->

<template>
  <div class="news">
    <page-header></page-header>
    <banner-news></banner-news>
    <div class="news-container">
      <!-- 置顶新闻 -->
      <topic-news></topic-news>
      <div class="news-nav">
        <div
          :class="['nav-item', { active: item.isActive }]"
          v-for="(item, index) in navList"
          :key="index"
          @click="setCurrentNavList(index)"
        >
          {{ item.label }}
        </div>
      </div>
      <news-list :news-type="navList[currentNavList].key"></news-list>
    </div>
    <page-footer></page-footer>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageFooter from '@/components/PageFooter';
import TopicNews from '@/components/TopicNews';
import BannerNews from './components/BannerNews.vue';
import NewsList from './components/NewsList.vue';
import { newsTypeKey } from '@/assets/data/constant';
export default {
  components: { PageHeader, PageFooter, TopicNews, BannerNews, NewsList },
  data() {
    return {
      currentNavList: 0,
      navList: [
        {
          key: newsTypeKey.all,
          label: '全部',
          isActive: true,
        },
        {
          key: newsTypeKey.qydt,
          label: '公司动态',
          isActive: false,
        },
        {
          key: newsTypeKey.hyzx,
          label: '行业资讯',
          isActive: false,
        },
      ],
    };
  },
  created() {
    // 底部跳转获取当前数据列表类型
    const urlQueryParams = new URLSearchParams(window.location.search);
    const type = urlQueryParams.get('type');

    if (!type) {
      return;
    }

    let tempCurrent = 0;
    this.navList.forEach((item, index) => {
      if (item.key === type) {
        tempCurrent = index;
        return;
      }
    });
    this.setCurrentNavList(tempCurrent);
  },
  methods: {
    /** 切换新闻类型 */
    switchType(index) {
      this.navList.map((item, indexN) => {
        let temp = item;
        if (index === indexN) {
          temp.isActive = true;
        } else {
          temp.isActive = false;
        }
      });
    },
    setCurrentNavList(index) {
      this.currentNavList = index;
    },
  },
  watch: {
    currentNavList(nv) {
      this.switchType(nv);
    },
  },
};
</script>

<style lang="less" scoped>
.news {
  min-width: 1200px;
  margin-top: 72px;
}
.news-container {
  width: 1200px;
  margin: 0 auto;
  padding-top: 100px;
  .news-nav {
    display: flex;
    // padding: 40px 0;
    padding-top: 80px;
    .nav-item {
      width: 240px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      border: 1px solid #a5a5a5;
      font-size: 16px;
      color: #444444;
      margin-right: 60px;
      cursor: pointer;
      transition: all 0.5s;
      &:last-child {
        margin-right: 0;
      }
      &:hover,
      &.active {
        color: #ffffff;
        background: #2750d6;
        border: 1px solid #2750d6;
      }
    }
  }
}
</style>
