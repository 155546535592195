<template>
  <!-- 解决方案-智慧零碳(v2.1) -->
  <div class="zero-carbon" id="zero-carbon">
    <page-header></page-header>
    <solution-banner :info="bannerInfo"></solution-banner>
    <!-- 解决方案全景图 -->
    <application-scenario
      title="解决方案全景图"
      :list="chooseList"
      :bgImg="rightImage"
      typeInfo="zero"
    ></application-scenario>
    <!-- 智慧零碳解决方案背景图 -->
    <div class="zero-carbon-wrapper">
      <div class="zero-carbon-container">
        <div class="container-title">智慧零碳解决方案背景</div>
        <!-- 双碳目标 -->
        <div class="zero-carbon-title target-title">中国双碳目标</div>
        <div class="target">
          <div class="target-text"></div>
        </div>
        <!-- 双碳措施 -->
        <div class="double-carbon-measures">
          <div class="zero-carbon-title measure-title">中国双碳措施</div>
          <div class="measures">
            <div
              class="measures-info"
              v-for="(item, index) in measureList"
              :key="index"
              @mouseenter="enter(index)"
              @mouseleave="leave"
            >
              <div class="title">{{ item.title }}</div>
              <div class="sub-title" v-for="(v, i) in item.value" :key="i">
                {{ v }}
              </div>
              <div class="desc">{{ item.text }}</div>
              <img
                :src="currIndex === index ? item.imgHover : item.img"
                alt=""
              />
            </div>
          </div>
        </div>

        <!-- 双碳路径 -->
        <div class="path-wrapper">
          <div class="zero-carbon-title path-title">中国双碳路径</div>
          <div class="path-img"></div>
        </div>
      </div>
    </div>
    <!-- 产品展示 -->
    <div class="product-wrapper">
      <div class="product-title">产品展示</div>
      <div class="product-img"></div>
    </div>
    <!-- 跳转商务咨询 -->
    <business-consulting></business-consulting>
    <page-footer></page-footer>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import SolutionBanner from '../product/components/SolutionBanner';
import ApplicationScenario from '../product/components/ApplicationScenario';
import BusinessConsulting from '../product/components/BusinessConsulting';
import PageFooter from '@/components/PageFooter';
export default {
  components: {
    PageHeader,
    SolutionBanner,
    ApplicationScenario,
    BusinessConsulting,
    PageFooter,
  },
  name: '',
  data() {
    return {
      bannerInfo: {
        text: '智慧零碳',
        subText: '助力政府、企业低碳发展<br/>早日实现碳达峰、碳中和目标',
        img: require('@/assets/images/solution/zero-carbon/zero_carbon_banner.jpg'),
        title: {
          img: require('@/assets/images/home/banner_title-02.svg'),
          with: '729px',
          height: '78px',
        },
        gradient: 'linear-gradient(to right, #d8e2ee 50%, #ecf5fe 50%)',
      },
      routeInfo: {},
      index: 0,
      rightImage: require('@/assets/images/solution/panorama-right-img.png'),
      chooseList: [
        {
          more: true, // 是否有跳转
          title: '政府零碳监管平台',
          anchor: 'zfltjg',
          msg:
            '帮助政府领导掌控双碳工作全局，提供城市级的碳监测与智能分析、温室气体清单编制、碳达峰碳中和规划、智能AI辅助决策、综合监管与考评等服务，助力城市早日实现碳中和目标。',
          img: require('@/assets/images/solution/zero-carbon/zero_carbon-01.jpg'),
        },
        {
          more: false, // 是否有跳转
          title: '行业零碳监管平台',
          anchor: '',
          msg:
            '为8大重点碳排放行业企业提供碳监测、碳资产管理、CCER项目管理、碳规划、碳考核、碳价值等应用服务，助力行业企业早日实现碳中和。',
          img: require('@/assets/images/solution/zero-carbon/zero_carbon-02.jpg'),
        },
        {
          more: false, // 是否有跳转
          title: '公众智慧零碳服务平台',
          anchor: '',
          msg:
            '推动全社会低碳行动，提升绿色低碳意识，引导绿色低碳生产生活和消费方式，营造全社会节能降碳氛围，平台向公众提供碳科普、碳足迹计算器、碳账户和低碳激励等应用服务。',
          img: require('@/assets/images/solution/zero-carbon/zero_carbon-03.jpg'),
        },
        {
          more: false, // 是否有跳转
          title: '碳金融服务平台',
          anchor: '',
          msg:
            '实现政府、企业、碳交易所及绿色金融机构多方信息互联互通，为企业提供专业碳风险评估；为金融机构提供碳风险识别、碳金融产品开发与管理，动态跟踪并科学评估碳金融投资项目，降低投资风险、提高业绩，助力绿色金融可持续性发展。',
          img: require('@/assets/images/solution/zero-carbon/zero_carbon-04.jpg'),
        },
      ],
      measureList: [
        {
          img: require('@/assets/images/solution/zero-carbon/measure_icon-01.png'),
          imgHover: require('@/assets/images/solution/zero-carbon/measure_icon_hover-01.png'),
          title: '减少碳排放',
          value: ['制造业 ', '建筑领域', '能源行业', '农业部门', '交通领域'],
          text:
            '采取调整能源结构、发展低碳清洁能源、提高能源利用效率、调整产业结构、提高生产效率等手段，在能源、制造、交通、建筑、农业等领域减少碳排放。',
        },
        {
          img: require('@/assets/images/solution/zero-carbon/measure_icon-02.png'),
          imgHover: require('@/assets/images/solution/zero-carbon/measure_icon_hover-02.png'),
          title: '绿色金融',
          value: ['绿色信贷', '绿色股指', '绿色基金', '绿色债券'],
          text:
            '推进绿色低碳金融产品和服务开发，设立碳减排货币政策工具，将绿色信贷纳入宏观审慎评估框架，引导银行等金融机构为绿色低碳项目提供长期限、低成本资金。',
        },
        {
          img: require('@/assets/images/solution/zero-carbon/measure_icon-03.png'),
          imgHover: require('@/assets/images/solution/zero-carbon/measure_icon_hover-03.png'),
          title: '碳交易',
          value: ['碳配额', '碳交易', '碳清缴', 'CCER'],
          text:
            '加快建设完善全国碳排放权交易市场，逐步扩大市场覆盖范围，丰富交易品种和交易方式，完善配额分配管理。',
        },
        {
          img: require('@/assets/images/solution/zero-carbon/measure_icon-04.png'),
          imgHover: require('@/assets/images/solution/zero-carbon/measure_icon_hover-04.png'),
          title: '增加碳汇',
          value: ['生态固碳', '技术固碳（CCUS）'],
          text:
            '持续增加森林面积和蓄积量，加强草原、湿地、海洋等生态系统保护，提升生态系统碳汇能力。',
        },
        {
          img: require('@/assets/images/solution/zero-carbon/measure_icon-05.png'),
          imgHover: require('@/assets/images/solution/zero-carbon/measure_icon_hover-05.png'),
          title: '全民低碳',
          value: ['碳足迹', '绿色出行', '碳普惠'],
          text:
            '把绿色低碳发展纳入国民教育体系。开展绿色低碳社会行动示范创建。凝聚全社会共识，加快形成全民参与的良好格局。',
        },
      ],
      currIndex: 0,
      currList: [],
      currInfo: {},
    };
  },
  created() {},
  mounted() {},
  methods: {
    enter(i) {
      this.currIndex = i;
    },
    leave() {
      this.currIndex = null;
    },
    scrollToHash(hash) {
      this.$nextTick(() => {
        this.timer = setTimeout(() => {
          this.$scrollTo(`#${hash}`, {
            duration: 1000,
            offset: -70,
          });
        }, 200);
      });
    },
  },
  computed: {},
  watch: {
    $route: {
      handler() {
        if (this.$route.hash) {
          this.scrollToHash('zero-carbon');
        }
      },
      immediate: true,
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      Object.assign(vm.routeInfo, { to, from });
    });
  },
};
</script>

<style lang="scss" scoped>
.zero-carbon {
  height: 100%;
  margin-top: 72px;
  // 零碳
  .zero-carbon-wrapper {
    min-width: 1200px;
    height: 1800px;
    background: #f6f7fa;

    .zero-carbon-title {
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #313a44;
      line-height: 32px;
      padding-left: 26px;
      position: relative;
      &::after {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #313a44;
        position: absolute;
        top: 10px;
        left: 6px;
      }
    }

    .zero-carbon-container {
      width: 100%;
      margin: 0 auto;
      overflow: hidden;
      .container-title {
        text-align: center;
        margin-top: 90px !important;
        font-size: 40px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #343434;
        font-size: 40px;
        line-height: 32px;
      }
      .target-title {
        margin-top: 50px !important;
        margin-bottom: 52px !important;
      }
      .target {
        width: 100%;
        height: 450px;
        background: url('~@/assets/images/solution/target-img.jpg') center
          no-repeat;
        background-size: cover;

        .target-text {
          height: 450px;
          background: url('~@/assets/images/solution/target_text.svg');
          background-size: 100% 100%;
        }
      }

      .double-carbon-measures {
        .measure-title {
          margin-top: 63px !important;
          margin-bottom: 56px !important;
        }
        .measures {
          display: flex;
          .measures-info {
            width: 232px;
            height: 330px;
            background: #ffffff;
            padding: 20px;
            position: relative;
            margin-right: 10px;
            text-align: justify;
            &:last-child {
              margin-right: 0;
            }
            .title {
              font-size: 20px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #283780;
              line-height: 28px;
              letter-spacing: 1px;
              margin-bottom: 30px;
            }
            .sub-title {
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #343434;
              line-height: 20px;
              padding-left: 15px;
              display: flex;
              align-items: center;
              position: relative;
              margin-bottom: 10px;
              opacity: 1;
              transition: all 0.5s ease;
              &::after {
                content: '';
                position: absolute;
                left: 0;
                width: 4px;
                height: 4px;
                background: #343434;
                border-radius: 50%;
              }
            }
            .desc {
              width: 192px;
              height: 96px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #343434;
              line-height: 24px;
              opacity: 0;
              position: absolute;
              top: 163px;
              transition: all 0.5s ease;
              z-index: 2;
            }
            img {
              width: 112px;
              height: 128px;
              position: absolute;
              bottom: 3px;
              right: 4px;
            }
            &:hover {
              .sub-title {
                opacity: 0;
              }
              .desc {
                opacity: 1;
                transform: translateY(-100px);
                transition: all 0.5s ease;
              }
            }
          }
        }
      }

      .path-wrapper {
        .path-title {
          margin-top: 66px !important;
          margin-bottom: 54px !important;
        }
        .path-img {
          width: 1216px;
          height: 384px;
          background: url('~@/assets/images/solution/path-img.svg') no-repeat;
          background-size: 100% 100%;
          margin-top: 43px;
        }
      }

      .container-title,
      .target-title,
      .target.target-text,
      .measure,
      .double-carbon-measures,
      .path-wrapper {
        width: 1200px;
        margin: 0 auto;
      }
    }
  }
  // 产品展示
  .product-wrapper {
    width: 100%;
    height: 680px;
    display: flex;
    flex-direction: column;

    .product-title {
      text-align: center;
      margin-top: 40px;
      font-size: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #343434;
      margin-top: 80px !important;
      margin-bottom: 110px !important;
    }
    .product-img {
      width: 1197px;
      height: 360px;
      margin-top: 110px;
      background: url('~@/assets/images/solution/product-img.png');
      background-size: 100% 100%;
    }
    .product-title,
    .product-img {
      width: 1200px;
      margin: 0 auto;
    }
  }
}
</style>
