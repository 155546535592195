<template>
  <div class="news-image">
    <div class="image-info">
      <img :src="`${urlAddress}${url}`" alt="" />
    </div>
    <div class="swiper-arrow arrow-left" @click="handlePrevious">
      <img :src="require('@/assets/images/icon_arrow_left.png')" alt="" />
    </div>
    <div class="swiper-arrow arrow-right" @click="handleNext">
      <img :src="require('@/assets/images/icon_arrow_right.png')" alt="" />
    </div>
    <p class="text">{{ desc }}</p>
    <div class="image-list">
      <img
        :class="['image-item', currIndex === i ? 'img-active' : '']"
        v-for="(v, i) in imageList"
        :key="i"
        :src="`${urlAddress}${v.url}`"
        alt=""
        @click="handleClick(i)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ImagePreview',
  props: {
    imageList: {
      type: Array,
      defualt: () => [],
    },
  },
  computed: {
    urlAddress() {
      return process.env.NODE_NEV === 'production'
        ? process.env.VUE_APP_URL_ADDRESS
        : process.env.VUE_APP_URL_ADDRESS + '/hcttop-website';
    },
    imgLen() {
      return this.imageList.length;
    },
    desc() {
      return this.imageList?.[this.currIndex].note || '';
    },
    url() {
      return this.imageList?.[this.currIndex].url || '';
    },
  },
  data() {
    return {
      currIndex: 0,
    };
  },
  created() {},
  mounted() {
    console.log(this.imageList);
  },
  methods: {
    handleClick(index) {
      this.currIndex = index;
    },
    handlePrevious() {
      this.currIndex =
        this.currIndex === 0 ? this.imgLen - 1 : this.currIndex - 1;
    },
    handleNext() {
      this.currIndex =
        this.currIndex === this.imgLen - 1 ? 0 : this.currIndex + 1;
    },
  },
  watch: {
    imageList: {
      handler() {
        this.currIndex = 0;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.news-image {
  width: 580px;
  height: 100%;
  overflow: hidden;
  position: relative;
  .image-info {
    width: 100%;
    height: 380px;
    img {
      width: inherit;
      height: 380px;
      border-radius: 8px;
      object-fit: cover;
    }
  }
  .swiper-arrow {
    position: absolute;
    top: 160px;
    transform: translate(0, 30%);
    width: 29px;
    height: 58px;
    cursor: pointer;
    z-index: 1000;
    &.arrow-right {
      right: 0;
    }
  }

  .text {
    width: 580px;
    height: 40px;
    border-bottom: 1px solid #dadada;
    line-height: 40px;
    font-size: 16px;
    color: #181818;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .image-list {
    width: 100%;
    height: 80px;
    position: absolute;
    top: 420px;
    display: flex;
    align-items: center;
    padding-top: 10px;
    .image-item {
      width: 140px;
      height: 80px;
    }

    img {
      width: 100%;
      margin-right: 10px;
      object-fit: cover;
      cursor: pointer;
      &:last-child {
        margin-right: 0px;
      }
      &:hover {
        border-color: #229dff;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
    .img-active {
      border: 1px solid #229dff;
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
