/**
 * Description: reauired, the file description, reference link, etc.
 * Created Date: 2021-01-27 2:03:22 PM
 * Author: Hu Yongjun <hyj@hcttop.com>
 * -----
 * Last Modified: 2021-01-27, 2:27:11 PM
 * Modified By: Liu Man <lm@hcttop.com>
 */

import Vue from 'vue';

import {
  Button,
  Form,
  FormItem,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Input,
  Collapse,
  CollapseItem,
  Pagination,
  Message,
  Icon,
  Image,
  Carousel,
  CarouselItem,
  Row,
  Col,
} from 'element-ui';

Vue.use(Button);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Input);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Pagination);
Vue.use(Icon);
Vue.use(Image);
Vue.use(Row);
Vue.use(Col);
Vue.use(Carousel);
Vue.use(CarouselItem);

Vue.prototype.$message = Message;
