export const joinUsList = new Map()
  .set('shenzhen', [
    {
      title: '市场销售经理(中高级)',
      subtitle: '',
      content: [
        {
          label: '工作职责',
          value: [
            '1、制定行业销售计划，完成销售目标，达成销售业绩；',
            '2、上门拜访客户，获取需求，建立和维护客户关系、招投标运作，发掘新老客户销售机会；',
            '3、负责客户初期方案的制作，参与招投标项目方案和标书的编写；',
            '4、负责与客户的协调沟通、问题处理及合同签订；',
            '5、了解行业动态，能根据行业动态形成分析报告。',
          ],
        },
        {
          label: '任职要求',
          value: [
            '1、本科及以上学历，3年以上销售管理经验，对智慧水务领域有一定见解和认知，具有水利水务、给排水、市场营销等相关专业背景优先；',
            '2、有日常拜访，具备良好的沟通技巧与说服力，熟悉市场谈判技巧；',
            '3、具有独立开拓新市场和解决问题的能力。',
          ],
        },
      ],
      bgType: 'market',
    },
    {
      title: 'JAVA软件开发工程师(中高级)',
      subtitle: '',
      content: [
        {
          label: '工作职责',
          value: [
            '1、参与公司产品的需求评审、设计开发及维护工作；',
            '2、负责与前端工程师协作，实现接口API的设计开发和联调；',
            '3、按照公司要求完成单元测试以及修复bug，确保代码质量；',
            '4、参与公司研发过程文档的编写。',
          ],
        },
        {
          label: '任职要求',
          value: [
            '1、本科及以上学历，3年以上java或java web开发经验，java基础扎实；',
            '2、熟练使用主流的开发框架、SpingMVC、SpringBoot、SpringCloud 、Mybatis等，对框架原理有深入的理解者可优先考虑；',
            '3、掌握MySql、PostgreSQL等主流数据库两种以上，有一定的SQL优化经验；',
            '4、了解DevOps，熟练掌握常用的开发工具：如eclipse、git、svn、maven等；',
            '5、熟练掌握主流web容器，如tomcat、jetty、nginx等；',
            '6、熟悉大数据分析、WebSocket编程、Mqtt及相关物联网等技术优先考虑；',
            '7、了解web前端技术，如html、javascript、Ajax、JQuery等优先考虑。',
          ],
        },
      ],
      bgType: 'java',
    },
    {
      title: 'WEB前端开发工程师(中高级)',
      subtitle: '',
      content: [
        {
          label: '工作职责',
          value: [
            '1、 参与公司产品的需求评审和方案设计；',
            '2、 负责公司产品Web前端和移动端的功能设计、开发及维护工作；',
            '3、 参与公司产品的用户体验优化；',
            '4、 参与公司前端公共组件的设计开发和维护。',
          ],
        },
        {
          label: '任职要求',
          value: [
            '1、本科及以上学历，3年以上Web前端开发经验；',
            '2、熟悉W3C标准，熟练掌握ES6、BOM、DOM等；',
            '3、熟练掌握Vue全家桶，需要有Vue项目经验；',
            '4、熟悉Web前端跨平台技术，具有良好的编码习惯及规范；',
            '5、熟悉ECharts、Element-UI、Axios等框架；',
            '6、具有GIS地图、3D相关工作经验，熟悉WebGL技术优先考虑。',
          ],
        },
      ],
      bgType: 'web',
    },
    {
      title: '产品经理(中高级)',
      subtitle: '',
      content: [
        {
          label: '工作职责',
          value: [
            '1、负责水务、零碳行业软件产品的需求分析、产品原型设计；',
            '2、组织设计、开发、测试团队项目需求评审，保障项目开发交付进度与质量；',
            '3、参与产品调研工作，挖掘用户真实需求，输出产品调研和解决方案文档；',
            '4、同业竞品分析，推动产品的持续更新和优化。',
          ],
        },
        {
          label: '任职要求',
          value: [
            '1、本科及以上学历，计算机类/给排水/环保类/能源类等相关专业本科以上学历优先；',
            '2、3年以上环保、水务等领域产品经验，熟悉产品全生命周期管理，有PMP证书优先；',
            '3、具有优秀的表达能力和沟通技巧，能独自承担客户沟通、调研和引导；',
            '4、具有优秀的产品设计能力和书写文笔功底，能独立撰写产品相关文档；',
            '5、熟练使用思维脑图、Axure RP、墨刀、Visio等产品设计工具。',
          ],
        },
      ],
      bgType: 'pm',
    },
    {
      title: '解决方案经理',
      subtitle: '',
      content: [
        {
          label: '工作职责',
          value: [
            '1.负责公司水务产品解决方案的主导工作，配合销售进行市场拓展；',
            '2.依据行业现状和客户需求结合公司智慧水务产品完成解决方案、标书、测试报告、实施方案和其他技术文案；',
            '3.项目前期产品调研、演示、讲解、价值呈现、引导用户需求，与客户进行沟通，确定实施方案并负责跟踪交付；',
            '4.熟悉行业规划，具备行业规划相应的技能；',
            '5.学习和掌握相应领域的专业知识，了解和掌握相应行业知识。',
          ],
        },
        {
          label: '任职要求',
          value: [
            '1、计算机、软件工程、给排水等相关专业，本科以上学历；',
            '2、有两年以上售前工作或智慧水务产品经验，熟悉技术方案的设计、实施及项目应标流程；',
            '3、了解大数据平台、数据仓库、数据挖掘等领域主流技术；',
            '4、优秀的方案撰写能力，思路清晰，逻辑思维强，能够根据业务需求设计合理的解决方案；具备招、投标书的文字处理及编写能力；',
            '5、熟悉政企解决方案，有相关行业经验；',
            '6、良好的客户服务意识和沟通能力，良好的团队意识，具有一定抗压能力；',
            '7、具备智慧城市项目售前技术支持经验者优先。',
          ],
        },
      ],
      bgType: 'solution',
    },
    {
      title: '解决方案总监',
      subtitle: '',
      content: [
        {
          label: '工作职责',
          value: [
            '1、负责公司产品方案的设计与宣讲，完成大型、多人的产品推介宣讲：',
            '2、负责公司产品解决方案的主导工作，完成项目支持和市场拓展，如客户交流、重大项目策略制定和解决方案构建等；',
            '3、依据行业现状和客户需求进行市场分析，结合公司产品组织制定市场规划和竞争策略，完成解决方案、标书、测试报告、实施方案和其他技术文案。',
          ],
        },
        {
          label: '任职要求',
          value: [
            '1、本科及以上学历，计算机、软件工程、给排水等相关专业，5年以上售前技术支持工作经验，熟悉技术方案的设计、实施及项目应标流程；',
            '2、了解大数据平台、数据仓库、数据挖掘等领域主流技术；',
            '3、优秀的方案撰写能力，能够根据业务需求设计合理的解决方案；',
            '4、具备招、投标书的文字处理及编写能力；',
            '5、熟悉政企解决方案，有洞察客户需求提供解决方案并获得市场成功的经验；',
            '6、具备智慧城市项目售前技术支持经验者优先；有论坛、展厅专题宣讲经验优先；有规划院、设计院、研究院工作经历优先。',
          ],
        },
      ],
      bgType: 'solution',
    },
    {
      title: 'JAVA架构师',
      subtitle: '',
      content: [
        {
          label: '岗位职责',
          value: [
            '1、负责应用系统需求分析、技术选型、架构设计、框架搭建、核心算法设计，核心代码编写；',
            '2、负责应用系统模块分解、核心接口设计、团队分工安排，解决团队中的技术不一致；',
            '3、解决重要项目中的关键架构问题和技术难题，负责项目中关键技术难点的攻关和预研；',
            '4、通过设计评审、代码评审等方式，识别通用组件或重构需求，保证开发质量。',
          ],
        },
        {
          label: '任职资格',
          value: [
            '1、计算机相关专业本科以上学历，至少5年以上Java架构师设计经验；',
            '2、精通SpringCloud、Dubbo 等主流服务框架，有大型微服务架构的实施经验；',
            '3、精通Servlet、Spring、Springmvc、Springboot、MyBatis、MQ、Redis开发；',
            '4、精通MySQL或Oracle数据库，具有大型、分布式数据架构设计经验；',
            '5、熟悉ZooKeeper、Kafka、ElasticSearch等技术；Redis、MongoDB等NOSQL数据库；',
            '6、具备多线程、高并发、高吞吐编程能力；',
            '7、熟悉大数据、物联网、区块链、知识图谱等技术。',
          ],
        },
      ],
      bgType: 'solution',
    },
    {
      title: 'GIS开发高级工程师',
      subtitle: '',
      content: [
        {
          label: '岗位职责',
          value: [
            '1、参与公司产品的需求评审、设计、开发及维护工作；',
            '2、负责公司产品GIS相关产品的需求分析、系统设计和开发工作；',
            '3、参与架构优化、性能和用户体验提升；',
            '4、负责GIS相关技术培训、编写和维护相关文档。',
          ],
        },
        {
          label: '任职资格',
          value: [
            '1、本科及以上学历，地理信息、计算机、软件开发等专业背景，5年以上开发工作经验；',
            '2、熟悉GIS理论知识和基本原理，熟悉空间数据及影像图处理技术；',
            '3、负责高性能WebGIS框架开发，包括地图服务API设计；',
            '4、熟悉GIS平台软件：ArcGIS、GeoServer或QGIS等软件；',
            '5、熟悉常用GIS开源框架，如OpenLayer、Leaflet、threejs等，具有相关项目开发经验者优先；',
            '6、熟悉运用html、css、js、vue、JQuery等前端脚本。',
          ],
        },
      ],
      bgType: 'gis',
    },
  ])
  .set('qingdao', [
    {
      title: '产品经理',
      subtitle: '',
      content: [
        {
          label: '工作职责',
          value: [
            '1. 负责公司智慧水务产品策划和优化工作',
            '2. 通过市场分析、用户分析等，提炼用户需求，制作产品需求计划和模型，撰写产品功能需求说明书',
            '3. 制定产品的中长期规划，推动产品的快速健康发展，树立优质口碑和形象',
            '4. 了解并熟悉业务指标体系建设，熟悉招投标流程及需求，用产品的方式更好的抽象业务数据需求',
            '5. 了解智慧水务、智慧城市领域的相关和类似产品，分析行业发展热点，并提出创新或改进需求',
          ],
        },
        {
          label: '任职要求',
          value: [
            '1. 本科及以上学历，3年以上相关产品设计工作经验',
            '2. 环境、水务等相关专业优先',
            '3. 有智慧水务、智慧环保工作经验优先',
            '4. 有TO G、TO B的产品经验优先',
            '5. 精通产品交互设计的相关流程，包括功能分析、用户角色分析、原型设计、界面开发、易用性测试等',
            '6. 出色的表达能力，文档写作能力和产品原型表现能力',
            '7. 有较强的敬业精神，责任心及良好的沟通能力和团队合作精神，具有较强的学习能力和洞察力',
          ],
        },
      ],
      bgType: 'pm',
    },
    {
      title: '解决方案经理',
      subtitle: '',
      content: [
        {
          label: '工作职责',
          value: [
            '1. 负责公司智慧水务解决方案的主导工作，配合销售进行市场拓展',
            '2. 依据行业现状和客户需求结合公司智慧水务产品完成解决方案、标书、测试报告、实施方案和其他技术文案',
            '3. 项目前期产品调研、演示、讲解、价值呈现、引导用户需求，与客户进行沟通，确定实施方案并负责跟踪交付',
            '4. 熟悉行业规划，具备行业规划相应的技能',
            '5. 学习和掌握相应领域的专业知识，了解和掌握相应行业知识',
          ],
        },
        {
          label: '任职要求',
          value: [
            '1. 计算机、软件工程、给排水等相关专业，本科以上学历',
            '2. 有两年以上售前工作或智慧水务产品经验，熟悉技术方案的设计、实施及项目应标流程',
            '3. 了解大数据平台、数据仓库、数据挖掘等领域主流技术',
            '4. 优秀的方案撰写能力，思路清晰，逻辑思维强，能够根据业务需求设计合理的解决方案；具备招、投标书的文字处理及编写能力',
            '5. 熟悉政企解决方案，有相关行业经验',
            '6. 良好的客户服务意识和沟通能力，良好的团队意识，具有一定抗压能力',
            '7. 具备智慧城市项目售前技术支持经验者优先',
          ],
        },
      ],
      bgType: 'solution',
    },
    {
      title: 'Java开发工程师(中级)',
      subtitle: '',
      content: [
        {
          label: '工作职责',
          value: [
            '1、参与公司产品的需求评审、设计开发及维护工作',
            '2、负责与前端工程师协作，实现接口API的设计开发和联调',
            '3、按照公司要求完成单元测试以及修复bug，确保代码质量',
            '4、参与公司研发过程文档的编写',
            '5、公司安排的其它工作',
          ],
        },
        {
          label: '任职要求',
          value: [
            '1、本科及以上学历，三年以上java或java web开发经验，java基础扎实',
            '2、熟练使用主流的开发框架、SpingMVC、SpringBoot、SpringCloud 、Mybatis等，对框架原理有深入的理解者可优先考虑',
            '3、掌握MySql、PostgreSQL等主流数据库两种以上，有一定的SQL优化经验',
            '4、了解DevOps，熟练掌握常用的开发工具：如eclipse、git、svn、maven等',
            '5、熟练掌握主流web容器，如tomcat、jetty、nginx等',
            '6、熟悉大数据分析、WebSocket编程、Mqtt及相关物联网等技术优先考虑',
            '7、了解web前端技术，如html、javascript、Ajax、JQuery等优先考虑',
          ],
        },
      ],
      bgType: 'java',
    },
    {
      title: 'Web前端开发工程师(中级)',
      subtitle: '',
      content: [
        {
          label: '工作职责',
          value: [
            '1、参与公司产品的需求评审和方案设计',
            '2、负责公司产品Web前端和移动端的功能设计、开发及维护工作',
            '3、参与公司产品的用户体验优化',
            '4、参与公司前端、移动端公共组件的设计开发和维护',
            '5、公司安排的其它工作',
          ],
        },
        {
          label: '任职要求',
          value: [
            '1、本科及以上学历，三年以上Web前端开发经验、1~2年Hybird项目开发经验，对GIS地图，大数据，物联网产品有浓厚兴趣',
            '2、精通HTML/CSS/JavaScript，熟悉W3C标准，熟练掌握ES6,html5,css3,DOM,JSON等',
            '3、熟练掌握一种现代前端框架Vue/React/AngularJS，熟悉Websocket',
            ' 4、熟练掌握Web前端跨平台技术，负责过前端、移动端项目，熟悉常用的前段设计模式MVC/MVVM，有组件化及OOP思想，具有良好的编码习惯及规范',
            '5、有GIS地图项目相关工作经验优先',
            '6、熟练使用Echarts框架，掌握一种前端构建工具webpack/gulp/fis等，熟练运用git',
            '7、对WEB性能优化，安全有一定的见解和相关实践，注重用户体验，有产品意识',
          ],
        },
      ],
      bgType: 'web',
    },
    {
      title: '市场部总经理(北方区域)',
      subtitle: '',
      content: [
        {
          label: '岗位职责',
          value: [
            '1、负责北方区域市场全面管理工作，建立北方市场营销体系。',
            '2、参与制定企业市场策略和激励政策、并负责落地实施，完成公司销售目标及回款任务。',
            '3、组建与管理销售团队，丰富销售手段，协助所属员工完成下达的任务指标。',
            '4、具备与客户领导层级良好的商务沟通和协调能力。',
            '5、有效解决或处理市场危机公关，妥当处理客户投诉事件。',
            '6、协助制定企业产品和企业品牌推广方案，并监督执行。',
          ],
        },
        {
          label: '任职要求',
          value: [
            '1、本科以上学历，具有水利给排水、市场营销等相关经验优先。',
            '2、在水利水务、IT行业有市场拓展、整合资源的能力。',
            '3、有大数据、智慧城市等行业解决方案营销的优先。',
            '4、组织管理能力强，具备良好的坚韧品格、协作和挑战精神。',
            '5、有丰富的商业公关能力，从事销售、运营等5年以上工作经验。',
            '6、认同和遵守公司的企业文化和价值观。',
          ],
        },
      ],
      bgType: 'market',
    },
    {
      title: '市场总监(北方区域)',
      subtitle: '',
      content: [
        {
          label: '岗位职责',
          value: [
            '1、开拓区域市场或具体客户，建立和维系良好的客户关系。',
            '2、具备与客户良好的商务沟通和协调能力。',
            '3、执行力强，能够达到公司制定的业绩目标任务。',
            '4、领导交办的其他工作。',
          ],
        },
        {
          label: '任职要求',
          value: [
            '1、专科及以上学历，水务、环保专业优先。',
            '2、市场营销岗位工作经验5年以上，工作经历及市场能力优秀者可放宽要求。',
            '2、拥有水务、环保、智慧城市等相关市场经验优先。',
            '3、拥有一定的市场资源或渠道优先。',
            '4、基本面和可塑性强，认同和遵守公司的企业文化和价值观。',
          ],
        },
      ],
      bgType: 'market',
    },
  ]);
