<template>
  <div class="panorama-wrapper">
    <div class="panorama-container">
      <div class="panorama-title">{{ title }}</div>
      <div class="panorama-d">
        <div class="left-d">
          <div
            :class="index === i ? 'list-hover' : 'list'"
            v-for="(v, i) in list"
            @mouseover="handleMouse(i)"
            :key="i"
          >
            <div class="title">{{ v.title }}</div>
            <div class="describe" v-if="index === i">{{ v.msg }}</div>
            <div
              class="more"
              v-if="index === i && v.more"
              @click="goToOtherPage(v)"
            >
              MORE<span class="more-icon"></span>
            </div>
          </div>
        </div>
        <div class="right-d" :style="`background-image: url(${itemBg})`">
          <!-- {{ rightImgText }} -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
    typeInfo: {
      type: String,
      default: '',
    },
    bgImg: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      index: 0,
    };
  },
  computed: {
    itemBg() {
      return this.list.find((e, i) => i === this.index).img;
    },
  },
  methods: {
    goToOtherPage(nav) {
      localStorage.setItem('CURRENT_ANCHOR', nav.anchor);
      this.$router.push(
        `/solution/solution-detail?type=${this.typeInfo}&name=${nav.anchor}`
      );
    },
    handleMouse(i) {
      if (this.index === i) return;
      this.index = i;
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      Object.assign(vm.routeInfo, { to, from });
    });
  },
};
</script>

<style lang="scss" scoped>
.panorama-wrapper {
  min-width: 1200px;
  height: 812px;
  overflow: hidden;
  .panorama-container {
    width: 1200px;
    margin: 0 auto;
    .panorama-title {
      text-align: center;
      margin-top: 100px;
      font-size: 40px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #343434;
    }
    .panorama-d {
      margin: 0 auto;
      margin-top: 80px;
      width: 1200px;
      height: 480px;
      display: flex;
      justify-content: space-between;
      .left-d {
        width: 450px;
        position: relative;
        .list {
          transition: all 0.5s ease;
          width: 450px;
          height: 80px;
          background: #ffffff;
          box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          line-height: 80px;
          .title {
            padding-left: 20px;
            font-size: 28px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #313a44;
          }
          &:last-child {
            position: absolute;
            bottom: 0;
          }
        }
        .list-hover {
          transition: all 0.5s ease;
          width: 450px;
          height: 240px;
          background: linear-gradient(148deg, #3899e8 0%, #2750d6 100%);
          box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
          overflow: hidden;
          position: relative;
          padding: 20px;
          .title {
            font-size: 28px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 40px;
          }
          .describe {
            width: 410px;
            height: 80px;
            margin-top: 30px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            text-align: justify;
            line-height: 20px;
            letter-spacing: 1px;
          }
          .more {
            cursor: pointer;
            height: 26px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            display: flex;
            position: absolute;
            right: 20px;
            bottom: 20px;
            .more-icon {
              width: 41px;
              height: 24px;
              background: url('~@/assets/images/home/arrows-r.svg') center
                no-repeat;
              background-size: cover;
              margin-top: 2px;
              margin-left: 20px;
            }
          }
        }
      }
      .right-d {
        width: 740px;
        background-size: 100% 100%;
        transition: all 0.75s ease-in-out;
      }
    }
  }
}
</style>
