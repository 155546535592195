<template>
  <!-- 联系我们 -->
  <div class="contact-container">
    <page-header></page-header>
    <div class="service" id="get-service">
      <div class="service-box">
        <el-form
          ref="form"
          v-if="!isSuccess"
          :model="form"
          :rules="rules"
          label-position="left"
        >
          <div class="service-title">
            服务咨询<span>Service and consulting</span>
          </div>
          <el-form-item
            label="公司名称："
            prop="companyName"
            :class="{
              'hc-is-focus': isfocus && currFocusName === 'companyName',
            }"
          >
            <el-input
              class="input"
              v-model="form.companyName"
              placeholder="请输入公司名称"
              @focus="changeFocus(true, 'companyName')"
              @blur="changeFocus(false, 'companyName')"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="您的姓名："
            prop="name"
            :class="{ 'hc-is-focus': isfocus && currFocusName === 'name' }"
          >
            <el-input
              v-model="form.name"
              placeholder="请输入姓名"
              @focus="changeFocus(true, 'name')"
              @blur="changeFocus(false, 'name')"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="联系电话："
            prop="phone"
            :class="{ 'hc-is-focus': isfocus && currFocusName === 'phone' }"
          >
            <el-input
              maxLength="11"
              v-model.number="form.phone"
              placeholder="请输入电话"
              @focus="changeFocus(true, 'phone')"
              @blur="changeFocus(false, 'phone')"
            ></el-input>
          </el-form-item>

          <el-form-item
            class="cooperation"
            label="合作意向："
            prop="cooperationArr"
          >
            <el-checkbox-group
              @change="selectCooperationField"
              v-model="form.cooperationArr"
            >
              <el-checkbox
                v-for="(item, index) in cooperationTypeList"
                :key="index"
                :label="item.itemName"
                :value="item.itemCode"
              ></el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item
            label=""
            :rules="[
              {
                message: '请至少选择一个合作意向，或输入其他合作意向',
                trigger: ['blur', 'change'],
              },
            ]"
            prop="otherCooperationField"
            :class="{
              'hc-is-focus':
                isfocus && currFocusName === 'otherCooperationField',
            }"
          >
            <el-input
              class="other"
              v-model="form.otherCooperationField"
              placeholder="其他合作意向"
              @focus="changeFocus(true, 'otherCooperationField')"
              @blur="changeFocus(false, 'otherCooperationField')"
            ></el-input>
          </el-form-item>
          <el-form-item style="text-align: right">
            <el-button
              class="submit-btn"
              type="primary"
              @click.prevent="onSubmit('form')"
            >
              完成并提交
            </el-button>
          </el-form-item>
        </el-form>
        <div v-if="isSuccess" class="success">
          <img
            :src="require('@/assets/images/contact/submit_success.png')"
            alt=""
          />
          <div class="success-text">
            提交成功<span>我们将尽快与您联系</span>
          </div>
          <div class="success-btn">
            <div @click="() => (isSuccess = false)">再次填写</div>
            <div @click="goBack">返回上一级</div>
          </div>
        </div>
      </div>
    </div>
    <page-footer></page-footer>
  </div>
</template>
<script>
import { contactUs } from '@/api/contact.js';
import PageHeader from '@/components/PageHeader';
import PageFooter from '@/components/PageFooter';
export default {
  name: 'contact-us',
  components: {
    PageHeader,
    PageFooter,
  },
  data() {
    return {
      timer: null,
      isfocus: false,
      currFocusName: '',
      // 业务咨询表单
      form: this.getDefault(),
      // 合作意向
      cooperationTypeList: [
        {
          id: 10,
          typeCode: 'cooperationField',
          typeName: '合作意向',
          itemCode: 'zhsw',
          itemName: '智慧水务',
          itemSort: 1,
          itemRemark: null,
          itemCss: null,
        },
        {
          id: 16,
          typeCode: 'cooperationField',
          typeName: '合作意向',
          itemCode: 'zhyq',
          itemName: '智慧零碳',
          itemSort: 3,
          itemRemark: null,
          itemCss: null,
        },
        {
          id: 11,
          typeCode: 'cooperationField',
          typeName: '合作意向',
          itemCode: 'zhhb',
          itemName: '智慧环保',
          itemSort: 2,
          itemRemark: null,
          itemCss: null,
        },
        // {
        //   id: 12,
        //   typeCode: 'cooperationField',
        //   typeName: '合作意向',
        //   itemCode: 'zhyq',
        //   itemName: '智慧园区',
        //   itemSort: 3,
        //   itemRemark: null,
        //   itemCss: null,
        // },
        {
          id: 13,
          typeCode: 'cooperationField',
          typeName: '合作意向',
          itemCode: 'wlwpt',
          itemName: '物联网平台',
          itemSort: 4,
          itemRemark: null,
          itemCss: null,
        },
        {
          id: 14,
          typeCode: 'cooperationField',
          typeName: '合作意向',
          itemCode: 'dsjpt',
          itemName: '大数据平台',
          itemSort: 5,
          itemRemark: null,
          itemCss: null,
        },
        {
          id: 15,
          typeCode: 'cooperationField',
          typeName: '合作意向',
          itemCode: 'xtjc',
          itemName: '系统集成',
          itemSort: 6,
          itemRemark: null,
          itemCss: null,
        },
      ],
      // 表单验证
      rules: {
        companyName: [
          { required: true, message: '请输入公司名称', trigger: 'blur' },
          {
            min: 5,
            max: 15,
            message: '长度在 5 到 15 个字符',
            trigger: 'blur',
          },
        ],
        name: [
          { required: true, message: '请输入您的姓名', trigger: 'blur' },
          { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' },
        ],
        phone: [
          {
            required: true,
            message: '请输入联系电话',
            trigger: 'blur',
          },
          {
            type: 'number',
            message: '请输入正确的联系电话',
            trigger: 'blur',
          },
        ],
        cooperationField: [
          {
            type: 'array',
            message: '请至少选择一个合作意向',
            trigger: ['blur', 'change'],
          },
        ],
      },
      isSuccess: false,
    };
  },
  mounted() {
    // 记录锚点定位位置
    this.curAnchor = localStorage.getItem('CURRENT_ANCHOR');
    this.scrollToHash(this.curAnchor);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    scrollToHash(hash) {
      this.$nextTick(() => {
        this.timer = setTimeout(() => {
          this.$scrollTo(`#${hash}`, {
            duration: 1000,
            offset: -70,
          });
        }, 200);
      });
    },
    changeFocus(b, name) {
      this.isfocus = b;
      this.currFocusName = name;
    },
    getDefault() {
      return {
        companyName: '',
        name: '',
        phone: null,
        cooperationArr: [],
        cooperationField: '',
        otherCooperationField: '',
      };
    },
    // 提交
    onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const params = this.form;
          contactUs(params).then(res => {
            if (res.data.code == 200) {
              this.$message({
                message: '提交成功',
                type: 'success',
              });
              this.isSuccess = true;
              this.cooperationArr = [];
              this.form = this.getDefault();
            } else {
              this.$message({
                message: res.data.message,
                type: 'error',
              });
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    goBack() {
      const pathName = sessionStorage.getItem('PRE_ROUTE');
      this.$router.push(pathName);
    },
    // 选择合作意向
    selectCooperationField(value) {
      const list = value.map((item, index) => {
        let obj = item;
        obj = this.cooperationTypeList[index].itemCode;
        return obj;
      });
      this.form.cooperationField = list.toString();
    },
  },
};
</script>
<style scoped lang="less">
@import '~@/assets/css/global';
.contact-container {
  width: 100%;
  height: 672px;
  padding-top: 72px;
  background: linear-gradient(to right, #cfe4f5 50%, #e4f0f9 50%);
}
.service {
  height: 672px;
  padding-top: 75px;
  background: url('~@/assets/images/contact/service_consulting.png') center
    center no-repeat;
  background-size: cover;
  .service-box {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;

    .el-form {
      width: 522px;
      height: 518px;
      background: #ffffff;
      border-radius: 8px;
      padding: 30px;
      .service-title {
        margin-bottom: 30px;
        font-size: 30px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #343434;
        line-height: 32px;
        display: flex;
        flex-direction: column;
        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #343434;
          line-height: 14px;
        }
      }
      .submit-btn {
        width: 446px;
        height: 48px;
        background: #2750d6;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        border-radius: 0;
        margin-left: 16px;
      }
      ::v-deep .cooperation .el-form-item__label {
        padding-left: 10px;
      }
      ::v-deep .el-input__inner {
        &:focus {
          border-color: #2750d6;
        }
        &::placeholder {
          color: #798695;
        }
      }
      ::v-deep .el-form-item {
        display: flex;
        white-space: nowrap;
        margin-bottom: 20px;

        .el-form-item__label {
          width: 110px;
        }
        .el-form-item__content {
          width: 350px;
          .el-checkbox-group {
            display: flex;
            flex-wrap: wrap;
            .el-checkbox {
              width: 110px;
              margin-right: 10px;
              &:nth-child(3n) {
                margin-right: 0px;
              }
            }
          }
          .other {
            margin-left: 110px;
          }
          .el-checkbox__input.is-checked + .el-checkbox__label {
            color: #2750d6;
          }
          .el-checkbox__input.is-checked .el-checkbox__inner {
            background-color: #2750d6;
            border-color: #2750d6;
          }
          .el-checkbox__inner:hover {
            border-color: #2750d6;
          }
        }
      }
    }
    .success {
      padding: 70px 30px 30px 46px;
      width: 522px;
      height: 518px;
      background: #ffffff;
      border-radius: 8px;
      display: flex;
      align-items: center;
      flex-direction: column;
      img {
        width: 123px;
        height: 141px;
        margin-bottom: 24px;
      }
      .success-text {
        font-size: 30px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #343434;
        line-height: 32px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        text-align-last: justify;
        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #343434;
          line-height: 24px;
          margin-bottom: 61px;
        }
      }
      .success-btn {
        width: 100%;
        div {
          width: 446px;
          height: 48px;
          background: #2750d6;
          text-align: center;
          line-height: 48px;
          cursor: pointer;
          &:nth-child(1) {
            color: #ffffff;
            margin-bottom: 30px;
          }
          &:nth-child(2) {
            background: #ffffff;
            border: 1px solid #2750d6;
            &:hover {
              color: #2750d6;
            }
          }
        }
      }
    }
  }
}
</style>
