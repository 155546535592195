// 智慧水务产品案例
export const waterCaseList = new Map()
  .set('yygkpt', {
    name: '运营管控平台',
    // banner
    bannerInfo: {
      bg: require('@/assets/images/cases/operation-management/operation_management_banner.jpg'),
      title: '智慧水务运营管控平台',
      desc:
        '智慧水务综合运营管控平台服务于水务企业核心业务，整合供排水全流程各节点涉水设施的运行数据，从实际工作场景出发,在供排水调度各环节中实现从信息采集到到分析判断、预警、自适应的全程指挥操作，为水务工作提供强大的决策支持。',
      tabs: ['产品组成', '核心功能', '产品价值'],
      gradient: 'linear-gradient(to right, #f2f7fb 50%, #f2f7fb 50%)',
    },
    // 产品组成
    composition: {
      title: '产品组成',
      text: '',
      img: require('@/assets/images/cases/operation-management/operation_management.svg'),
      mt: '0',
      bgStyle: 'h830',
      titleStyle: 'mt155 mb70',
    },
    // 核心应用
    application: {
      title: '核心应用',
      titleStyle: 'title-80-40',
      tabs: [
        { name: '监测预警', width: 240 },
        { name: '分析模拟', width: 240 },
        { name: '调度决策', width: 240 },
      ],
      name: '功能包括：',
      rows: [1, 0, 1], // 是否单行
      infoList: [
        {
          title:
            '平台实时获取全流程各节点在线数据,动态设置预警规则,实现重要指标预前报警。',
          img: require('@/assets/images/cases/operation-management/operation_management-01.png'),
          value: [
            '供排水全流程水质、水压、液位、设备等实时在线监测',
            '监测指标历史趋势分析,预警阈值动态设置',
            '预警触发工单,跟踪预警事件及时处置',
            '预警类型频次统计分析',
          ],
        },
        {
          title:
            '基于管网水力模型对管网工况进行模拟分析,实现供排水水系统的科学管理和优化运行。',
          img: require('@/assets/images/cases/operation-management/operation_management-02.png'),
          value: [
            '供水需求量预测',
            '供水管网压力模拟',
            '排水设施上下游拓扑分析',
            '爆管影响范围分析',
            '汛期积水点动态模拟',
          ],
        },
        {
          title:
            '结合历史趋势与模拟分析预测、设备设施运行参数,计算最优调度方案。',
          img: require('@/assets/images/cases/operation-management/operation_management-03.png'),
          value: [
            '供水生产输配调度',
            '排水负荷均衡调度',
            '突发事件应急指挥调度',
            '调度指令库动态匹配',
          ],
        },
      ],
    },
    // 产品价值
    productValue: {
      title: '产品价值',
      titleStyle: '',
      icons: [1, 2, 4],
      infoList: [
        {
          name: '全面监测',
          desc:
            '实时对供排水运行全过程进行监测、分析与预警,做到变被动为主动,全面感知。',
        },
        {
          name: '智能运作',
          desc:
            '强化供排水业务管理的科学性和前瞻性,同时对某些事态进行预处理并自主作出决策。',
        },
        {
          name: '协同行动',
          desc:
            '现场多部门联合处置，最终达到水务管理与服务的有机、协同化运作。',
        },
      ],
    },
  })
  .set('shjzhjg', {
    name: '水环境综合监管平台',
    // banner
    bannerInfo: {
      bg: require('@/assets/images/cases/water-environment/water_environment_banner.jpg'),
      title: '水环境智慧化监管平台',
      desc:
        '水环境智慧化监管平台以水质测量预警站为基础，结合移动水质监测，水环境质量监测站点以及其他测量方式，可实时监控内河流域和排污口水质状况、自动预警、污染溯源、信息综合分析。为治理后水质做持续跟踪监测，有效防止偷排复燃。',
      tabs: ['产品组成', '核心功能', '产品价值'],
      gradient: 'linear-gradient(to right, #f2f7fb 50%, #f2f7fb 50%)',
    },
    // 产品组成
    composition: {
      title: '产品组成',
      text: '',
      img: require('@/assets/images/cases/water-environment/water_environment.svg'),
      mt: '0',
      bgStyle: 'h830',
      titleStyle: 'mt155 mb90',
    },
    // 核心应用
    application: {
      title: '核心应用',
      titleStyle: 'title-80-40',
      tabs: [
        { name: '水环境监测', width: 240 },
        { name: '水环境污染溯源分析', width: 240 },
        { name: '监管：网格管理', width: 240 },
      ],
      name: '功能包括：',
      rows: [1, 1, 1], // 是否单行
      infoList: [
        {
          title:
            '平台实时获取排放监控数据、过程监控数据等在线数据,动态设置预警规则,实现重要指标超标报警。',
          img: require('@/assets/images/cases/water-environment/water_environment-01.png'),
          value: [
            '排放监控数据、过程监控数据、设备运维数据等实时在线监测',
            '监测指标历史趋势分析，预警阈值动态设置',
            '对重点污染源的排放做综合分析及时做出相关预警告知有关部门',
          ],
        },
        {
          title:
            '根据监测点位信息、水流速信息、上报时间差,可计算出污染源头所处的范围区间，并自动筛选出该范围内的排口。',
          img: require('@/assets/images/cases/water-environment/water_environment-02.png'),
          value: [
            '水位水质实时监测',
            '入河污染负荷、允许入河负荷量、削减负荷指标控制',
            '结合水文和气象条件,进行日常水质模拟',
          ],
        },
        {
          title:
            '将河道划分成一个个单元网格,以村为最基层单位,将行政区内的河道对应到相应河长进行管理,最终实现全市河长制以各区县、乡镇、村为单元的网格化管理。',
          img: require('@/assets/images/cases/water-environment/water_environment-03.png'),
          value: ['河道巡查统计', '监督绩效考评', '综合信息管理'],
        },
      ],
    },
    // 产品价值
    productValue: {
      title: '产品价值',
      titleStyle: '',
      icons: [1, 2, 4],
      infoList: [
        {
          name: '多元共治',
          desc:
            '以平台为媒介,通过综合分析和统一调度帮助组织应对不同的风险,解决不同的问题',
        },
        {
          name: '智能决策',
          desc: '智能信息系统实时监测.实时解析数据.为治理政策提供数据支撑。',
        },
        {
          name: '分级管理',
          desc:
            '运用科学化管理方法将河道湖泊管理落实到个人。建立健全管理机制，让管理闭环',
        },
      ],
    },
  })
  .set('qygsyth', {
    name: '区域供水一体化管理平台',
    // banner
    bannerInfo: {
      bg: require('@/assets/images/cases/regional-water-supply/regional_water_supply_banner.jpg'),
      title: '区域一体化供水管理平台',
      desc:
        '针对区域的供水管网、供水设施、计量、二次供水等相关基础设施进行精细化、智慧化管理，实现对区域内供水管网及设施运行数据实时监控、消火栓、井盖实时监控、区域内计量体系建立及区域漏损率分级、实时统计分析。提升区域供水管理和服务水平。',
      tabs: ['产品组成', '核心功能', '产品价值'],
      gradient: 'linear-gradient(to right, #f2f7fb 50%, #f2f7fb 50%)',
    },
    // 产品组成
    composition: {
      title: '产品组成',
      text: '',
      img: require('@/assets/images/cases/regional-water-supply/regional_water_supply.svg'),
      mt: '0',
      bgStyle: 'h830',
      titleStyle: 'mt155 mb90',
    },
    // 核心应用
    application: {
      title: '核心应用',
      titleStyle: 'title-80-40',
      tabs: [
        { name: '区域供水管网运行监测', width: 240 },
        { name: '区域计量体系建立', width: 240 },
        { name: '区域二次供水智慧泵房管理', width: 240 },
      ],
      name: '功能包括：',
      rows: [0, 0, 0], // 是否单行
      infoList: [
        {
          title:
            '基于管网水力模型对管网工况进行模拟分析,实现供水系统的科学管理和优化运行。',
          img: require('@/assets/images/cases/regional-water-supply/regional_water_supply-01.png'),
          value: [
            '供水管网压力监控',
            '水质在线监控',
            '区域流量监控',
            '爆管影响范围分析',
            '区域管网设施监控',
            '在线监测报警',
          ],
        },
        {
          title:
            '建立区域管网分区计量管理体系、实现对用户的用水规律、管网负荷工况、漏损率进行实时监管。',
          img: require('@/assets/images/cases/regional-water-supply/regional_water_supply-02.png'),
          value: [
            '管网流量和压力监控',
            '水量统计分析',
            '管网漏损分段统计',
            '用水异常报警',
            '区域产销差分析',
          ],
        },
        {
          title:
            '基于对二次供水加压泵房实现智慧监控改造、实现对泵房运行参数，计算最优调度方案。',
          img: require('@/assets/images/cases/regional-water-supply/regional_water_supply-03.png'),
          value: [
            '小区居民用水分析',
            '泵房运行监控',
            '泵房环境安保监控',
            '突发事件应急控制',
            '二次供水运行数据分析',
          ],
        },
      ],
    },
    // 产品价值
    productValue: {
      title: '产品价值',
      titleStyle: '',
      icons: [1, 2, 4],
      infoList: [
        {
          name: '组织模式，从职能到平台',
          desc:
            '以能力和共享为导向，沉淀能力，共享给所有员工和业务，帮助组织应对不同的风险，解决不同的问题',
        },
        {
          name: '运作模式：从人工到智能',
          desc:
            '机器自动化、智能化实现业务全流程监管；智能系统实时监测，无需人工干预即可实现异常预警',
        },
        {
          name: '治理模式：从分布到集中',
          desc:
            '对外，让整个业务体系高效匹配用户的需求；对内，让新业务快速生长孕育。',
        },
      ],
    },
  })
  .set('ecgsbf', {
    name: '二次供水泵房管理平台',
    // banner
    bannerInfo: {
      bg: require('@/assets/images/cases/second-pump-room/second_pump_room_banner.jpg'),
      title: '二次供水泵房管理平台',
      desc:
        '二次供水泵房管理平台为提高泵房的管理水平和效率，确保高层住宅的安全稳定供水，建设二次供水智慧水务目标，助力决策，最大限度保障城市供水安全和降低供水事故损失。',
      tabs: ['产品组成', '核心功能', '产品价值'],
      gradient: 'linear-gradient(to right, #f2f7fb 50%, #f2f7fb 50%)',
    },
    // 产品组成
    composition: {
      title: '产品组成',
      text: '',
      img: require('@/assets/images/cases/second-pump-room/second_pump_room.svg'),
      mt: '0',
      bgStyle: 'h830',
      titleStyle: 'mt155 mb70',
    },
    // 核心应用
    application: {
      title: '核心应用',
      titleStyle: 'title-80-40',
      tabs: [
        { name: '泵房监测', width: 240 },
        { name: '数据分析', width: 240 },
        { name: '供水3D模型分析', width: 240 },
      ],
      name: '功能包括：',
      rows: [1, 1, 1], // 是否单行
      infoList: [
        {
          title: '展示泵房实时数据,对各泵房进行组织调度,包括安保和应急控制。',
          img: require('@/assets/images/cases/second-pump-room/second_pump_room-01.png'),
          value: [
            '泵房运行情况及泵组状态展示',
            '泵房应急控制，各水泵、阀门的关闭、调整',
            '安防数据展示(维保、告警、门禁、巡检),视频查看',
          ],
        },
        {
          title: '基于泵房数据进行图表展示、统计分析,从而做到科学决策。',
          img: require('@/assets/images/cases/second-pump-room/second_pump_room-02.png'),
          value: [
            '收集设备运行、水量、水压、水质、环境监测数据分析',
            '设备联动分析,分析各设备数据之间的联动性',
            '告警溯源分析,精准追寻告警源头',
          ],
        },
        {
          title:
            '获取水表数据,用户数据,通过3D模型展示二次供水实况,并实时告警。',
          img: require('@/assets/images/cases/second-pump-room/second_pump_room-03.png'),
          value: [
            '二次供水用户概况、楼层及泵房物理建模,供水压力分析',
            '小区全体供水概况整体分析及末端压力分析',
            '小区营收情况分析',
            '实时告警追踪',
          ],
        },
      ],
    },
    // 产品价值
    productValue: {
      title: '产品价值',
      titleStyle: '',
      icons: [1, 2, 4, 3],
      infoList: [
        {
          name: '最大化资源利用',
          desc:
            '提升资源利用率,优化产业链配置,实现网络化、智能化、服务化、协同化产业新形态。',
        },
        {
          name: '最小化运营成本',
          desc: '提升人、财、物管理效率;降低生产、运营、服务成本。',
        },
        {
          name: '提高运营效率',
          desc:
            '管理风险的提前预警、快速诊断、科学管控,以更加精细和动态的方式管理:业务流程。',
        },
        {
          name: '加速优化服务体系',
          desc: '优化营商环境,构建标准化、规范化、亲情化的服务体系。',
        },
      ],
    },
  })
  .set('zhczyth', {
    name: '智慧厂站一体化管控平台',
    // banner
    bannerInfo: {
      bg: require('@/assets/images/cases/plant-station-integration/plant_station_integration_banner.jpg'),
      title: '智慧厂站一体化管控平台',
      desc:
        '智慧厂站一体化管控平台服务于水厂企业核心业务，实现水厂的全流程自动化控制、数字化运维管理、全生命周期的设备资产管理。一体化的安防管控以及科学决策辅助，使水厂能在少人无人值守条件下安全，优质，高效，节约的运行。',
      tabs: ['产品组成', '核心功能', '产品价值'],
      gradient: 'linear-gradient(to right, #f2f7fb 50%, #f2f7fb 50%)',
    },
    // 产品组成
    composition: {
      title: '产品组成',
      text: '',
      img: require('@/assets/images/cases/plant-station-integration/plant_station_integration.svg'),
      mt: '0',
      bgStyle: 'h1010',
      titleStyle: 'mt155 mb70',
    },
    // 核心应用
    application: {
      title: '核心应用',
      titleStyle: 'title-80-40',
      tabs: [
        { name: '工艺远程操控', width: 240 },
        { name: '设备台账', width: 240 },
        { name: '监测预警', width: 240 },
        { name: '能耗分析', width: 240 },
      ],
      name: '功能包括：',
      rows: [1, 1, 1, 1], // 是否单行
      infoList: [
        {
          title:
            '实时获取各工艺环节各节点在线数据,动态设置预警规则,实现重要指标预前报警。',
          img: require('@/assets/images/cases/plant-station-integration/plant_station_integration-01.png'),
          value: [
            '监控原水水质、水位,控制源水取水量。',
            '智能投加咖药环节各投剂的用量。',
            '预设对设备的运行进行调控变更。',
          ],
        },
        {
          title: '基于现有运行中的设备对其进行造册登记,并对运行情况进行监测。',
          img: require('@/assets/images/cases/plant-station-integration/plant_station_integration-02.png'),
          value: [
            '远程控制部分设备。',
            '实时对设备当前运行数据进行统计分析。',
            '对不同设备不同参数进行报警预设。',
            '设备预设峰值报警告知。',
          ],
        },
        {
          title:
            '平台实时获取各监控点的事实数据,动态设置预警规则,实现重要指标预前报警。',
          img: require('@/assets/images/cases/plant-station-integration/plant_station_integration-03.png'),
          value: [
            '水厂内部人员识别监控。',
            '人工巡检实时定位追踪。',
            '设备运行是否在线。',
          ],
        },
        {
          title: '结合每天/周/月/年度水厂的电能以及投药消耗量进行维度统计。',
          img: require('@/assets/images/cases/plant-station-integration/plant_station_integration-04.png'),
          value: [
            '电耗/药耗变化趋势。',
            '电耗/药耗的比例分布。',
            '电耗/药耗的同比环比。',
          ],
        },
      ],
    },
    // 产品价值
    productValue: {
      title: '产品价值',
      titleStyle: '',
      icons: [1, 2, 4],
      infoList: [
        {
          name: '组织模式，从职能到平台',
          desc:
            '以角色与岗位区分,对应岗位的人员职责进行明确划分。结合各组织机构当中不同等级进行权限管理维护。',
        },
        {
          name: '运作模式:从人工到智能',
          desc:
            '人为预设运行设备的峰值，智能化实现业务全流程监管;智能系统实时监测,无需人工干预即可实现异常预警。',
        },
        {
          name: '管理模式:从人为到智能',
          desc:
            '原有人工干预操作流程工艺进行远程检测,人为智能控制。减少值班以及维护人员工作量。保证水厂运行安全化,科学化。',
        },
      ],
    },
  })
  .set('skaqzh', {
    name: '水库安全综合监管平台',
    // banner
    bannerInfo: {
      bg: require('@/assets/images/cases/reservoir-safety/reservoir_safety_banner.jpg'),
      title: '水库安全综合监管平台',
      desc:
        '水库安全综合监管平台，利用境内水库网格化管理思想，智慧阀门、智能井盖等高科技设备,整合现有各种基础数据.专题数据、监测数据和监控视频，面向各级领导、工作人员提供不同层次.不同维度.动态数据查询.事件.上报和日常管理服务。',
      tabs: ['产品组成', '核心功能', '产品价值'],
      gradient: 'linear-gradient(to right, #f2f7fb 50%, #f2f7fb 50%)',
    },
    // 产品组成
    composition: {
      title: '产品组成',
      text: '',
      img: require('@/assets/images/cases/reservoir-safety/reservoir_safety.svg'),
      mt: '0',
      bgStyle: 'h1010',
      titleStyle: 'mt155 mb80',
    },
    // 核心应用
    application: {
      title: '核心应用',
      titleStyle: 'title-80-40',
      tabs: [
        { name: '水库安全监测预警/水库安全监测', width: 300 },
        { name: '应急响应调度支持', width: 240 },
        { name: '安全风险分析', width: 240 },
      ],
      name: '',
      rows: [1, 1, 1], // 是否单行
      multiple: true,
      infoList: [
        {
          img: require('@/assets/images/cases/reservoir-safety/reservoir_safety-01.png'),
          value: [
            {
              title: '水雨情监测',
              text: [
                '对水库水位、降雨量、坝体外观实时监测;数据自动传输',
                '实现水位越限报警;大雨、暴雨预警;水库实时视频传输水位越限自动拍照。',
              ],
            },
            {
              title: '大坝安全监测',
              text: [
                '渗流量监测、位移、沉降、倾斜等监测',
                '对水库渗流量进行监测,判断蓄水建筑物是否稳定,水库的防渗、排水设施工作是否正常',
              ],
            },
          ],
        },
        {
          img: require('@/assets/images/cases/reservoir-safety/reservoir_safety-02.png'),
          value: [
            {
              title: '险情上报',
              text: [
                '对水库水位、降根据风险级别,将险情部位、险情时间、险情描述、险情图片/视频/语音进行上报,告警信息发送相应责任人及部门。雨量、坝体外观实时监测;数据自动传输',
              ],
            },
            {
              title: '启动应急流程',
              text: ['启用应急响应方案,启动应急响应流程。'],
            },
            {
              title: '资源调度协调',
              text: ['实现现有物资、人员、设备调度、协调、管理。'],
            },
          ],
        },
        {
          img: require('@/assets/images/cases/reservoir-safety/reservoir_safety-03.png'),
          value: [
            {
              title: '汛期水位预警分析',
              text: [
                '基于水雨情监测数据统计分析,结合水库水文信息,评估汛期水位超标风险。',
              ],
            },
            {
              title: '工程运行安全分析',
              text: [
                '结合监测数据、勘测资料和观测成果,结合水利专业模型，综合分析工程缺陷、安全情况，对可能发生的溃坝风险进行告警。',
              ],
            },
          ],
        },
      ],
    },
    // 产品价值
    productValue: {
      title: '产品价值',
      titleStyle: '',
      icons: [1, 2, 4],
      infoList: [
        {
          name: '水库安全运营管理实现“四化”',
          desc:
            '水库基础管理信息化,水库巡检管理标准化,水库安全运维智能化,管理决策智慧化',
        },
        {
          name: '智慧监测',
          desc:
            '水库安全监控平台向巡检系统发送异常监测事件,指派给相应巡检负责人,并进行巡检事件闭环追踪,实现安全运维协同联动。',
        },
        {
          name: '数据管理及共享',
          desc:
            '建立水库安全管理数据库,对水库安全基础信息进行管理,数据库可实现数据的管理、调用及共享功能,支持与第三方数据接入功能。',
        },
      ],
    },
  })
  .set('gwgis', {
    name: '管网GIS管理平台',
    // banner
    bannerInfo: {
      bg: require('@/assets/images/cases/pipe-gis/pipe_gis_banner.jpg'),
      title: '综合GIS管理平台',
      desc:
        '综合GIS管理平台通过整合水务企业管网及附属设施等相关资产、外业工单、片区网格化等相关功能，实现水务企业业务管理的全面化、流程化、精简化，可以为水务企业管理人员在资产管理、绩效考核、外业维护、报表统计等工作提供全方位的支持。',
      tabs: ['产品组成', '核心功能', '产品价值'],
      gradient: 'linear-gradient(to right, #f2f7fb 50%, #f2f7fb 50%)',
    },
    // 产品组成
    composition: {
      title: '产品组成',
      text: '',
      img: require('@/assets/images/cases/pipe-gis/pipe_gis.svg'),
      mt: '0',
      bgStyle: 'h830',
      titleStyle: 'mt155 mb90',
    },
    // 核心应用
    application: {
      title: '核心应用',
      titleStyle: 'title-80-40',
      tabs: [
        { name: '管网GIS管理', width: 240 },
        { name: '外业维护', width: 240 },
        { name: '网格化管理', width: 240 },
      ],
      name: '功能包括：',
      rows: [0, 1, 0], // 是否单行
      infoList: [
        {
          title: '平台可以查询管网及设施的相关信息,实现资产的定性管理。',
          img: require('@/assets/images/cases/pipe-gis/pipe_gis-01.png'),
          value: [
            '根据地图定位查询相应的管网设施',
            '通过资产编码查询相应的管网设施',
            '管网及设施属性的查询',
            '管道纵断面生成',
            '管网及设施分类及属性统计',
            '管网拓扑连接分析',
          ],
        },
        {
          title: '模块可以实现外业流程管理、工单统计等功能。',
          img: require('@/assets/images/cases/pipe-gis/pipe_gis-02.png'),
          value: ['外业工单流程管理', '外业工单类型统计', '外业工单GIS分布'],
        },
        {
          title:
            '平台可以对水务企业相关的管理工作按照片区网格化进行管理,细化管理边界、简化管理流程。',
          img: require('@/assets/images/cases/pipe-gis/pipe_gis-03.png'),
          value: [
            '资产网格化管理',
            '外业网格化管理',
            '人员网格化管理',
            '漏损网格化管理',
          ],
        },
      ],
    },
    // 产品价值
    productValue: {
      title: '产品价值',
      titleStyle: '',
      icons: [1, 2, 4],
      infoList: [
        {
          name: '整合资产状况',
          desc: '满足水务企业对现有资产的梳理及管理,发挥资产价值。',
        },
        {
          name: '细化业务流程',
          desc:
            '细化水务企业相关业务流程,实现相关业务的实施流程感知,提升水务企业业务水平。',
        },
        {
          name: '简化管理模式',
          desc:
            '满足水务企业解决在管理上出现的权责不清等相关问题,简化水务企业业务管理流程,细化水务企业人员绩效考核。',
        },
      ],
    },
  });
