<template>
  <div class="solution-detail">
    <page-header></page-header>
    <page-banner-comp
      :bannerInfo="currDetail.bannerInfo"
      :scrollIndex="scrollIndex"
      @scrollTop="scrollTop"
    ></page-banner-comp>

    <!-- 水务：核心应用 / 零碳：方案架构 -->
    <div class="target-fix" id="target0"></div>
    <graphic-text-comp
      :info="
        type === 'water' ? currDetail.application : currDetail.architecture
      "
    ></graphic-text-comp>

    <!-- 水务：方案特点 / 零碳：核心应用 -->
    <div class="target-fix" id="target1"></div>
    <component
      :is="compType"
      :info="type === 'water' ? currDetail.features : currDetail.application"
    ></component>

    <!-- 方案优势 -->
    <div class="target-fix" id="target2"></div>
    <program-advantage-comp
      :info="currDetail.advantages"
    ></program-advantage-comp>

    <!-- 跳转商务咨询 -->
    <business-consulting></business-consulting>
    <page-footer></page-footer>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageFooter from '@/components/PageFooter';
import PageBannerComp from '../product/components/PageBannerComp';
import GraphicTextComp from '../product/components/GraphicTextComp';
import CoreApplicationComp from '../product/components/CoreApplicationComp';
import ProgramAdvantageComp from '../product/components/ProgramAdvantageComp';
import BusinessConsulting from '../product/components/BusinessConsulting';
import { waterDetailList, zeroDetailList } from './solutionData';

export default {
  name: 'SolutionDetail',
  components: {
    PageHeader,
    PageFooter,
    PageBannerComp,
    GraphicTextComp,
    CoreApplicationComp,
    ProgramAdvantageComp,
    BusinessConsulting,
  },
  data() {
    return {
      scrollIndex: 0,
      targets: [],
      currDetail: {},
      type: 'water',
      name: '',
    };
  },
  created() {},
  mounted() {
    this.getTargets();
    window.addEventListener('resize', () => {
      this.getTargets();
    });
  },
  computed: {
    compType() {
      return this.type === 'water'
        ? 'graphic-text-comp'
        : 'core-application-comp';
    },
  },
  methods: {
    getTargets() {
      this.$nextTick(() => {
        let arr = [];
        for (let i = 0; i < 3; i++) {
          let doom = document.getElementById(`target${i}`);
          arr.push(doom.offsetTop);
        }
        this.targets = arr;
      });
    },
    getCurrData() {
      switch (this.type) {
        case 'water':
          this.currDetail = waterDetailList.get(this.name);
          break;
        case 'zero':
          this.currDetail = zeroDetailList.get(this.name);
          break;
        default:
          this.currDetail = {};
          break;
      }
    },
    scrollTop(val) {
      let index = 0;
      if (val >= this.targets[0]) {
        if (val >= this.targets[1]) index = 1;
        if (val >= this.targets[2]) index = 2;
      }
      this.scrollIndex = index;
    },
  },
  watch: {
    $route: {
      handler() {
        if (this.$route.query.type) {
          const { type, name } = this.$route.query;
          this.type = type;
          this.name = name;
          this.getCurrData();
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.solution-detail {
  min-width: 1200px;
  margin-top: 72px;
  .target-fix {
    position: relative;
    top: -80px;
    display: block;
    height: 0;
    overflow: hidden;
  }
}
</style>
