// 解决方案-智慧水务
export const waterDetailList = new Map()
  .set('zhswgs', {
    name: '智慧水务-供水一体化',
    // banner
    bannerInfo: {
      bg: require('@/assets/images/solution/water/water_affairs_banner-01.jpg'),
      title: '供水一体化解决方案',
      desc:
        '以更加精细和精准的方式管理供水的整个生产、管理和服务流程，以更加“智慧”的方式辅助水务企业供水调配和运维，保证可靠供水，降低产销差，提升运营管控能力',

      tabs: ['核心应用', '方案特点', '方案优势'],
      gradient: 'linear-gradient(to right, #f2f7fb 50%, #f2f7fb 50%)',
    },
    // 核心应用
    application: {
      title: '核心应用',
      text:
        '供水一体化运行全程监测，结合智能控制，从粗放式的供水一体化调度逐步转变为智慧化的供水一体化运营，在保障供水平稳、保障水质的同时，实现供水需求平衡，从而避免浪费或者不足。',
      img: require('@/assets/images/solution/water-supply/core_application_supply.svg'),
      mt: '42px',
      bgStyle: 'h920',
      titleStyle: 'title-150-30',
    },
    // 方案特点
    features: {
      title: '方案特点',
      text: '',
      img: require('@/assets/images/solution/water-supply/program_features_supply.svg'),
      mt: '0',
      bgStyle: 'h900',
      titleStyle: 'title-80-60',
    },
    // 方案优势
    advantages: {
      title: '方案优势',
      titleStyle: '',
      icons: [1, 2, 3, 4], // icon序号
      infoList: [
        {
          name: '控制智能化',
          desc:
            '发展行业先进控制技术，实现控制智能化。提高智能化系统的覆盖率率。',
        },
        {
          name: '运营数字化',
          desc:
            '打造水务数字孪生体。以数据驱动业务标准化；以数据引导智慧化建设。',
        },
        {
          name: '决策智慧化',
          desc:
            '多维研判构建复杂系统的模型和算法，模型驱动决策，实现科学决策、高效处置。',
        },
        {
          name: '管理精准化',
          desc:
            '决策能够贯彻执行，执行可追踪，过程可回溯，结果可复盘的闭环式精准化管理。',
        },
      ],
    },
  })
  .set('zhswyj', {
    name: '智慧水务-应急一体化',
    // banner
    bannerInfo: {
      bg: require('@/assets/images/solution/water/water_affairs_banner-03.jpg'),
      title: '应急一体化解决方案',
      desc:
        '在极端事故时供排水业务的协同处置和应急响应。对接在线监测数据和业务数据，通过数据分析研判极端事故的产生，自动启动应急响应，同时协调各部门联动，线上线下协同，降低极端事故造成的负面影响',

      tabs: ['核心应用', '方案特点', '方案优势'],
      gradient: 'linear-gradient(to right, #f2f7fb 50%, #f2f7fb 50%)',
    },
    // 核心应用
    application: {
      title: '核心应用',
      text:
        '针对各类典型的水质突变、管道爆管、地面坍塌、厂内断电和防汛排涝，结合专家经验以及历史数据，制定相应的运行方案和应急预案，实现应急事件第一时间发现、第一时间上传、第一时间处置。',
      img: require('@/assets/images/solution/water-emergency/core_application_emergency.svg'),
      mt: '6px',
      bgStyle: 'h920',
      titleStyle: 'title-150-30',
    },
    // 方案特点
    features: {
      title: '方案特点',
      text: '',
      img: require('@/assets/images/solution/water-emergency/program_features_emergency.svg'),
      mt: '0',
      bgStyle: 'h900 f7',
      titleStyle: 'title-80-160',
    },
    // 方案优势
    advantages: {
      title: '方案优势',
      titleStyle: '',
      icons: [1, 2, 3], // icon序号
      infoList: [
        {
          name: '多维研判提升决策能力',
          desc:
            '数据共享，通过多维数据分析研判极端事故的产生，判断事件影响程度，自动匹配最优响应方案降低极端事故造成的负面影响。',
        },
        {
          name: '视频联动提升沟通效率',
          desc:
            '视野共享、实时掌控不同场景现场事态发展情况，掌握一手资讯，对事件处置进展了然于胸，多方沟通无信息差。',
        },
        {
          name: '多方联动提升处置效率',
          desc:
            '领导共享、指挥分布于不同节点、不同类型的应急资源，对紧急事故做出最快速的反应和最适当的处理。',
        },
      ],
      height: '850px',
      top: '45px',
    },
  })
  .set('zhswps', {
    name: '智慧水务-排水一体化',
    // banner
    bannerInfo: {
      bg: require('@/assets/images/solution/water/water_affairs_banner-02.jpg'),
      title: '排水一体化解决方案',
      desc:
        '实现排水设施运行工况及水位信息全天候、全方位、全时段的掌控，构建运营分析模块，推进排水管网和水质净化厂优化运行，全方位提升排水服务质量，保障城市排水机制',

      tabs: ['核心应用', '方案特点', '方案优势'],
      gradient: 'linear-gradient(to right, #f2f7fb 50%, #f2f7fb 50%)',
    },
    // 核心应用
    application: {
      title: '核心应用',
      text:
        '以现存问题为导向，部署物联网监测设备，获取监测大数据，构建管网水力模型及水环境模型，建设厂、站、网一体化管理模式。转变观念，从“结果管控”到 “过程管控”，实现结果-通路-源头全链路监控与透视，追根溯源，从根本上解决水务问题。',
      img: require('@/assets/images/solution/water-drainage/core_application_drainage.png'),
      mt: '34px',
      bgStyle: 'h920',
      titleStyle: 'title-150-30',
    },
    // 方案特点
    features: {
      title: '方案特点',
      text: '',
      img: require('@/assets/images/solution/water-drainage/program_features_drainage.svg'),
      mt: '0',
      bgStyle: 'h900',
      titleStyle: 'title-80-85',
    },
    // 方案优势
    advantages: {
      title: '方案优势',
      titleStyle: '',
      icons: [1, 2, 4], // icon序号
      infoList: [
        {
          name: '厂网联动效能提升',
          desc: '通过厂网联动运行提升了污水处理效率，同时降低了污水处理成本。',
        },
        {
          name: '水环境持续改善',
          desc: '使溢流污染得到改善，河道水质持续改善，呈现良好的水生态环境。',
        },
        {
          name: '百姓获得感提升',
          desc:
            '降雨对市民出行和生活的影响越来越小。降低投诉量，排水安全保障能力进一步提升。',
        },
      ],
    },
  })
  .set('zhswshj', {
    name: '智慧水务-水环境一体化',
    // banner
    bannerInfo: {
      bg: require('@/assets/images/solution/water/water_affairs_banner-04.jpg'),
      title: '水环境一体化解决方案',
      desc:
        '以水质测量预警站为基础，针对水务，水利，环保，企业的需求，建立水环境质量在线监测体系，自动预警、污染溯源、信息综合分析，有效改善环境质量状况，提升居民的生活质量和舒适度',

      tabs: ['核心应用', '方案特点', '方案优势'],
      gradient: 'linear-gradient(to right, #f2f7fb 50%, #f2f7fb 50%)',
    },
    // 核心应用
    application: {
      title: '核心应用',
      text:
        '在水资源管理、水污染综合防治、水环境治理、水域纳污能力监管、行政监管与执法等方面，建设涵盖监测与预警、问题处理与闭环、综合考核和评价的水环境一体化监管机制，保障整体水环境。',
      img: require('@/assets/images/solution/water-env/core_application_env.svg'),
      mt: '-34px',
      bgStyle: 'h920',
      titleStyle: 'title-150-30',
    },
    // 方案特点
    features: {
      title: '方案特点',
      text: '',
      img: require('@/assets/images/solution/water-env/program_features_env.svg'),
      mt: '0',
      bgStyle: 'h900 f7',
      titleStyle: 'title-80-160',
    },
    // 方案优势
    advantages: {
      title: '方案优势',
      titleStyle: '',
      icons: [1, 2, 4, 3], // icon序号
      infoList: [
        {
          name: '污染全留痕',
          desc: '流域水质数据和污染源排放数据融合分析，实现污染全留痕。',
        },
        {
          name: '线索全收集',
          desc:
            '多渠道收集污染线索、及时保存污染证据，实现科学准确的问题清单。',
        },
        {
          name: '效果全评估',
          desc:
            '实现“经济发展-能源消耗-防控措施-污染排放-流域环境-人群健康”的系统化评估。',
        },
        {
          name: '决策全智能',
          desc:
            '以数据和算法为径，构建流域水质预测溯源体系，为流域管理提供科学建议。',
        },
      ],
    },
  });

// 解决方案-智慧零碳
export const zeroDetailList = new Map().set('zfltjg', {
  name: '智慧零碳-政府零碳',
  // banner
  bannerInfo: {
    bg: require('@/assets/images/solution/zero-carbon/zero_carbon_banner-01.jpg'),
    title: '政府零碳监管平台',
    desc:
      '帮助政府领导掌控双碳工作全局，提供城市级的碳监测与智能分析、温室气体清单编制、碳达峰碳中和规划、智能AI辅助决策、综合监管与考评等服务，助力城市早日实现碳中和目标。',

    tabs: ['方案架构', '核心应用', '方案优势'],
    gradient: 'linear-gradient(to right, #f2f7fb 50%, #f2f7fb 50%)',
  },
  // 方案架构
  architecture: {
    title: '方案架构',
    text: '',
    img: require('@/assets/images/solution/zero-carbon-gov/solution_architecture.svg'),
    mt: '',
    bgStyle: 'h1070',
    titleStyle: '',
  },
  // 核心应用
  application: {
    title: '核心应用',
    tabs: [
      { name: '城市零碳驾驶舱', width: 240 },
      { name: '碳监测与智能分析系统', width: 240 },
      { name: '温室气体清单动态管理系统', width: 240 },
      { name: '零碳分析与规划系统', width: 240 },
      { name: '减排措施管理系统', width: 240 },
    ],
    titleStyle: {
      mt: '80px',
      mb: '60px',
    },
    name: '功能包括：',
    rows: [0, 0, 0, 0, 0], // 是否单行
    infoList: [
      {
        title: '汇总全局性的碳监管信息,为领导管理双碳工作提供决策支撑',
        img: require('@/assets/images/solution/zero-carbon-gov/core_app_01.png'),
        value: [
          '驾驶舱总览',
          '监测一张图',
          '能源一张图',
          '碳盘查',
          '碳资产管理',
          '碳达峰预测',
        ],
      },
      {
        title:
          '提供城市级的碳监测服务，掌握实时的城市温室气体排放、能耗数据，对异常告警并分析',
        img: require('@/assets/images/solution/zero-carbon-gov/core_app_02.png'),
        value: ['监测全景图', '告警管理', '碳监测、温室气体监测、能耗监测'],
      },
      {
        title:
          '管理城市的温室气体排放数据，摸清排放源，支持排放量动态管理，为减排规划及管理提供依据',
        img: require('@/assets/images/solution/zero-carbon-gov/core_app_03.png'),
        value: ['温室气体清单全景图', '温室气体查询'],
      },
      {
        title:
          '基于城市的碳排放情况，进行碳达峰碳中和情景模拟预测，基于模拟结果制定双碳实施路径规划',
        img: require('@/assets/images/solution/zero-carbon-gov/core_app_04.png'),
        value: [
          '零碳分析与规划全景图',
          '城市碳排放现状分析',
          '碳达峰碳中和情景模拟',
          '碳达峰碳中和目标规划',
        ],
      },
      {
        title:
          '平台提供丰富的降碳减排措施数据库，为碳达峰碳中和目标规划提供支撑',
        img: require('@/assets/images/solution/zero-carbon-gov/core_app_05.png'),
        value: ['先进经验库', '本地措施库', '减排措施库综合看板'],
      },
    ],
  },
  // 方案优势
  advantages: {
    title: '方案优势',
    titleStyle: 'title-80-100',
    icons: [1, 2, 4], // icon序号
    infoList: [
      {
        name: '双碳监管精准、高效',
        desc:
          '实时、动态掌握城市各行业、重点控排企业的能耗、碳排放数据、使城市的碳资产管理更高效、双碳协同评价考核更智能。',
      },
      {
        name: '决策研判有依据',
        desc:
          '加强各行业领域碳排放形势分析、预测、预警，实现科学合理制定达峰目标、时间表与路线图，科学辅助政府节能降碳决策。',
      },
      {
        name: '双碳服务更贴心',
        desc:
          '为行业、企业和公众提供碳环境影响评价、碳披露、碳金融、碳科普等贴心的政务服务。',
      },
    ],
  },
});
