<!--
 * Description: 首页顶部轮播模块.
 * Created Date: 2021-01-14 3:21:13 PM
 * Author: Zhao xiaoqin <zxq@hcttop.com>
 * -----
 * Last Modified: 2021-01-19, 10:08:09 AM
 * Modified By: Liu Man <lm@hcttop.com>
 -->

<template>
  <div :class="['carousel-box', { paused: isPaused }]">
    <swiper
      :options="swiperOption"
      ref="mySwiper"
      @slideChangeTransitionStart="transChange(getCurrentSwiperIndex)"
      class="test"
    >
      <swiper-slide class="slide slide-1">
        <div class="slide-content">
          <div class="content-box" v-if="currentSwiperIndex == 0">
            <div class="banner-title" data-aos="fade-up">
              <div class="text-title">
                业界领先智慧型数字技术综合服务商
                <span>
                  Industry first-class intelligent digital technology integrated
                  service provider
                </span>
              </div>
              <div class="sub-text-title">
                <div class="name">虹川科技</div>
                <div class="desc">数智引领绿色产业变革</div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="slide slide-2">
        <div class="slide-content">
          <div class="content-box" v-if="currentSwiperIndex == 1">
            <div data-aos="fade-up">
              <div class="banner-text">
                <div class="text">智慧零碳</div>
                <div class="sub-text">
                  助力政府、企业低碳发展<br />
                  早日实现碳达峰、碳中和目标
                </div>
              </div>
              <div class="go-page" @click="pageTo(switchTab[1])">了解详情</div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="slide slide-3">
        <div class="slide-content">
          <div class="content-box" v-if="currentSwiperIndex == 2">
            <div data-aos="fade-up">
              <div class="banner-text">
                <div class="text">智慧水务</div>
                <div class="sub-text">
                  助力政府、水务公司实现信息化升级<br />
                  数字化转型、智慧化变革
                </div>
              </div>
              <div class="go-page" @click="pageTo(switchTab[2])">了解详情</div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <!-- <swiper-slide class="slide slide-3">
      <div class="slide-content">
        <div class="tips">
          <div class="tips-ch">虹川科技，绿色智慧城市理念倡行者</div>
          <div class="tips-en">
            HCTTOP TECHNOLOGY<br />GREEN SMART CITY COMMEMORATE ADVOCATE
          </div>
        </div>
      </div>
    </swiper-slide> -->
      <div
        class="swiper-pagination swiper-pagination-white"
        slot="pagination"
      ></div>
    </swiper>
    <!-- banner方块 -->
    <div class="business-container">
      <div class="business-sector">
        <div
          class="sector-msg"
          v-for="(item, index) in switchTab"
          :key="index"
          @click="pageTo(item)"
        >
          <div class="sector-top">
            <div class="icon-image">
              <img :src="item.icon" alt="" class="images-info" />
            </div>
            <div class="icon-title">
              {{ item.text }}
            </div>
          </div>
          <div class="sector-bottom" :title="item.describe">
            {{ item.describe }}
          </div>
          <!-- 盒子进度条 -->
          <div
            :class="
              currentSwiperIndex === index
                ? 'scroll scrool-bar-animation'
                : 'scroll'
            "
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Carousel',
  data() {
    return {
      switchTab: [
        {
          icon: require('@/assets/images/home/hongchuan-icon.png'),
          text: '虹川科技',
          describe: '业界领先智慧型数字技术综合服务商',
          direction: '',
        },
        {
          icon: require('@/assets/images/home/wisdom-icon.png'),
          text: '智慧零碳',
          describe: '数字技术、智慧化服务全面赋能双碳产业',
          direction: '/wisdom-zero-carbon-product',
        },
        {
          icon: require('@/assets/images/home/wisdom--water-icon.png'),
          text: '智慧水务',
          describe: '数智赋能水务全流程、全场景、全要素业务',
          direction: '/wisdom-water-product',
        },
      ],
      currentSwiperIndex: 0,
      // 是否暂停标记
      isPaused: false,
      swiperOption: {
        spaceBetween: 30,
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        autoplay: {
          // 修改时间时一起修改animation的时间：animation: widthChange 5000ms;
          delay: 5000,
          disableOnInteraction: false,
        },
      },
    };
  },
  computed: {
    mySwipers() {
      return this.$refs.mySwiper.swiper;
    },
    getCurrentSwiperIndex() {
      return this.$refs.mySwiper.swiper.realIndex;
    },
  },
  methods: {
    pageTo(v) {
      if (v.direction !== '') this.$router.push(v.direction);
    },
    /** 跳转到解决方案 */
    // toSolution() {
    //   this.$router.push('wisdom-zero-carbon-product');
    // },
    /** swiper切换：设置当前index */
    transChange(index) {
      this.currentSwiperIndex = index;
    },
    /** 继续自动轮播 */
    startPlay() {
      this.isPaused = false;
      this.mySwipers.autoplay.start();
    },
    /** 暂停自动轮播 */
    pausedPlay() {
      this.isPaused = true;
      this.mySwipers.autoplay.stop();
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style lang="less" scoped>
@import '~@/assets/css/global';
.business-container {
  width: 100%;
  .business-sector {
    width: 1200px;
    height: 104px;
    margin: 0 auto;
    position: relative;
    top: -52px;
    z-index: 10;
    display: flex;
    .sector-msg {
      width: 350px;
      height: 104px;
      background: linear-gradient(180deg, #f4f6f8 0%, #fefefe 100%);
      box-shadow: 0px 7px 14px 0px rgba(49, 58, 68, 0.1);
      border-radius: 2px;
      border: 2px solid #ffffff;
      padding: 20px 10px;
      cursor: pointer;
      position: relative;
      &:nth-child(n + 2) {
        margin-left: 20px;
      }
      .sector-top {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        .icon-image {
          width: 33px;
          height: 28px;
          .images-info {
            width: 100%;
            height: 100%;
          }
        }
        .icon-title {
          margin-left: 10px;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #1c1c1c;
          line-height: 28px;
          letter-spacing: 1px;
          &:hover {
            color: #2750d6;
          }
        }
      }
      .sector-bottom {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #888f97;
        line-height: 22px;
        letter-spacing: 1px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .scroll {
        width: 316px;
        height: 2px;
        position: absolute;
        left: 0;
        bottom: -1px;
      }
      .scrool-bar-animation {
        background: #0e93ff;
        animation: widthChange 5200ms infinite;
      }
    }
  }
}
// 轮播图
.slide {
  height: 620px;
  width: 100% !important;

  .slide-content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    position: relative;
    .content-box {
      width: 1200px;
      height: 100%;
    }
  }

  .banner-text {
    display: flex;
    text-align: left;
    white-space: nowrap;
    align-items: center;
    position: absolute;
    top: 177px;
    .text {
      width: 224px;
      height: 78px;
      font-size: 56px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1c1c1c;
      line-height: 78px;
    }
    .sub-text {
      width: 444px;
      height: 66px;
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #313a44;
      line-height: 33px;
      letter-spacing: 1px;
      margin-left: 60px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: -30px;
        width: 1px;
        height: 60px;
        background: #313a44;
      }
    }
  }
  .go-page {
    width: 148px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #313a44;
    border: 1px solid #313a44;
    position: absolute;
    top: 275px;

    &:hover {
      color: #2439b2;
      border-color: #2439b2;
    }
  }

  &.slide-1 {
    // background: linear-gradient(to right, #dae3ed, #e7f1fc);
    background: linear-gradient(to right, grey, grey);
    .slide-content {
      background: url('~@/assets/images/home/home_banner_bg-01.jpg') center
        no-repeat;
      background-size: cover;
    }
    .content-box {
      position: relative;
      .banner-title {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 177px;
        .text-title {
          font-size: 48px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 67px;
          letter-spacing: 1px;
          display: flex;
          flex-direction: column;
          text-align: left;
          margin-bottom: 30px;
          span {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #bbc5d1;
            line-height: 17px;
            text-transform: uppercase;
          }
        }
        .sub-text-title {
          display: flex;
          align-items: center;
          .name {
            font-size: 32px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 45px;
            letter-spacing: 1px;
            margin-right: 45px;
            position: relative;
            &::after {
              content: '';
              position: absolute;
              top: 22px;
              right: -22px;
              width: 5px;
              height: 5px;
              background: #ffffff;
              border-radius: 50%;
            }
          }
          .desc {
            width: 370px;
            height: 36px;
            padding-left: 18px;
            border: 1px solid #ffffff;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 36px;
            letter-spacing: 17px;
            white-space: nowrap;
          }
        }
      }
    }
  }

  &.slide-2 {
    background: linear-gradient(to right, #e1e9f4, #e6eef9);
    color: #fff;
    .slide-content {
      background: url('~@/assets/images/home/home_banner_bg-02.jpg') center
        no-repeat;
      background-size: cover;
    }
    .content-box {
      transition: all 0.5s ease-in-out;
      position: relative;
    }
  }
  &.slide-3 {
    background: linear-gradient(to right, #e1e9f4, #e6eef9);
    .slide-content {
      background: url('~@/assets/images/home/home_banner_bg-03.jpg') center
        no-repeat;
      background-size: cover;
    }
    .content-box {
      transition: all 0.5s ease-in-out;
      position: relative;
    }
  }
}

@keyframes widthChange {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
// 轮播图指示点：自动播放
.carousel-box {
  position: relative;
  min-width: 1200px;
  ::v-deep {
    .swiper-pagination {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: not-allowed;
      .swiper-pagination-bullet {
        display: none;
        width: 80px;
        height: 4px;
        border-radius: 2px;
        overflow: hidden;
        background: rgba(214, 214, 214, 0.45);
        position: relative;
        opacity: 1;
        z-index: 100;

        &.swiper-pagination-bullet-active {
          opacity: 1;
          &::before {
            content: '';
            width: 0;
            height: 100%;
            background: #ffffff;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 100;
            border-radius: 2px;
            animation: widthChange 5000ms linear;
            animation-play-state: running;
          }
        }
      }
    }
  }
}

// 轮播图指示点：暂停播放
.paused {
  ::v-deep {
    .swiper-pagination {
      .swiper-pagination-bullet {
        &.swiper-pagination-bullet-active {
          opacity: 1;
          &::before {
            width: 100% !important;
            animation-play-state: paused;
          }
        }
      }
    }
  }
}
</style>
