var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-nav"},[_c('div',{staticClass:"content nav-box"},[_c('div',{staticClass:"nav-left"},[_c('div',{staticClass:"nav-title"},[_vm._v("解决方案")]),_c('div',{staticClass:"banner-nav"},_vm._l((_vm.navList),function(item,index){return _c('div',{key:index,class:[
            'ban-nav-item',
            {
              'ban-nav-cur': _vm.activeLink == item.routeName,
              lastBorder: index === _vm.navList.length - 1,
            } ]},[_c('router-link',{attrs:{"to":{ path: item.url }}},[_c('div',{class:[
                'icon-right',
                {
                  fade: _vm.activeLink == item.routeName,
                  last: _vm.lastLink === item.routeName,
                } ]},[_c('img',{attrs:{"src":require('@/assets/images/product/icon_arrow_right.png')}})]),_c('div',{class:[
                'item-right',
                {
                  fade: _vm.activeLink == item.routeName,
                  last: _vm.lastLink === item.routeName,
                } ]},[_c('div',{staticClass:"item-icon"},[_c('img',{attrs:{"src":item.icon}})]),_c('div',{staticClass:"item-tips"},[_vm._v(_vm._s(item.name))])])])],1)}),0)]),_c('div',{staticClass:"nav-right",attrs:{"data-aos":"fade-in","data-aos-delay":"100"}},[_c('div',{staticClass:"nav-title"},[_vm._v(_vm._s(_vm.navInfo.title))]),_c('div',{staticClass:"nav-desc",domProps:{"innerHTML":_vm._s(_vm.navInfo.desc)}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }