<template>
  <div class="home-swiper">
    <div class="swiper-top">
      <page-title
        :title="info.title"
        :describe="info.describe"
        class="home-title"
      ></page-title>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </div>
    <div class="swiper-bottom">
      <swiper :options="swiperOption" class="hc-swiper-container" ref="swiper">
        <swiper-slide v-for="(slide, index) in list" :key="index">
          <div
            @mouseover="handleMouse(index)"
            @mouseleave="handleLeave"
            class="swiper-center"
          >
            <div
              class="swiper-info"
              :style="`background-image:url(${slide.img})`"
            >
              <div
                :class="['swiper-icon', `icon-size${slide.iconSize}`]"
                :style="`background-image:url(${slide.icon})`"
              ></div>
              <div class="swiper-title">{{ slide.title }}</div>
              <div class="position">{{ slide.position }}</div>
            </div>
            <div
              class="swiper-info-hover"
              :style="`background-image:url(${slide.hoverImg})`"
            >
              <div class="hover-title">
                <img :src="slide.hoverIcon" alt="" />
                <div class="title">{{ slide.title }}</div>
              </div>
              <div class="hover-desc">{{ slide.hoverDesc }}</div>
              <div class="hover-solid"></div>
              <div class="hover-bottom">
                <div class="left">{{ slide.name }}</div>
                <div class="right">
                  <div class="position-text">{{ slide.position }}</div>
                  <div class="position-icon"></div>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/pageTitle/index';

export default {
  name: 'ProductSwiper',
  components: { PageTitle },
  props: {
    info: {
      type: Object,
      default: () => ({
        title: '',
        describe: '',
        bg: '',
        text: '',
        height: '108px',
      }),
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isAnimation: -1,
      isHover: false,
      swiperOption: {
        map: null,
        // slider之间的间隙
        spaceBetween: 20,
        // 可视slider的数量
        slidesPerView: 'auto',
        // 上下页切换
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        // 分页指示器
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        // 循环方式：无限循环
        loop: true,
        // effect: 'fade',
        // 自动滚动
        // autoplay: {
        //   delay: 4000,
        //   disableOnInteraction: false,
        // },
        speed: 500,
        autoplay: true,
        centeredSlides: false,
        slidesOffsetBefore: 0,
      },
      currentElem: null,
    };
  },
  created() {},
  mounted() {
    this.addMouseEvent();
  },
  methods: {
    handleMouse(i) {
      this.isAnimation = i;
      this.isHover = true;
    },
    handleLeave() {
      this.isHover = false;
    },
    addMouseEvent() {
      const swiperEl = this.swiper.el;
      swiperEl.addEventListener(
        'mouseenter',
        event => {
          this.swiper.autoplay.stop();
          if (this.currentElem) return;
          const target = event.target.closest('div.swiper-slide');
          if (!target) {
            this.isAnimation = -1;
            return;
          }

          if (!swiperEl.contains(target)) return;
          this.currentElem = target;
          this.onMouseEnter(this.currentElem);
        },
        true
      );

      swiperEl.addEventListener('mouseleave', () => {
        this.swiper.autoplay.start();
        if (!this.currentElem) return;

        if (this.currentElem.classList.contains('animation')) {
          this.currentElem.classList.remove('animation');
          this.currentElem.children[0].classList.remove(
            'slide-container--animation-fade-in'
          );
          this.currentElem.children[0].classList.add(
            'slide-container--animation-fade-out'
          );
        }

        this.isAnimation = -1;
      });
      swiperEl.addEventListener('mouseout', event => {
        // if we're outside of any <td> now, then ignore the event
        // that's probably a move inside the table, but out of <td>,
        // e.g. from <tr> to another <tr>
        if (!this.currentElem) return;

        // we're leaving the element – where to? Maybe to a descendant?
        let relatedTarget = event.relatedTarget;

        while (relatedTarget) {
          // go up the parent chain and check – if we're still inside currentElem
          // then that's an internal transition – ignore it
          if (relatedTarget == this.currentElem) return;

          relatedTarget = relatedTarget.parentNode;
        }

        // we left the <td>. really.

        if (this.currentElem.classList.contains('animation')) {
          this.currentElem.classList.remove('animation');
          this.currentElem.children[0].classList.remove(
            'slide-container--animation-fade-in'
          );
          this.currentElem.children[0].classList.add(
            'slide-container--animation-fade-out'
          );
        }

        this.isAnimation = -1;
        this.currentElem = null;
      });
    },
    onMouseEnter(ele) {
      const currentIndex = ele.getAttribute('data-swiper-slide-index');
      const dom = document.getElementsByClassName('swiper-slide');
      const domList = Array.from(dom);

      domList.forEach(item => {
        if (item.classList.contains('animation')) {
          item.classList.remove('animation');
        }

        const childClasslist = item.children[0].classList;
        if (item.getAttribute('data-swiper-slide-index') === currentIndex) {
          item.classList.add('animation');

          if (childClasslist.contains('slide-container--animation-fade-out')) {
            childClasslist.remove('slide-container--animation-fade-out');
            childClasslist.add('slide-container--animation-fade-in');
          }
        } else {
          if (childClasslist.contains('slide-container--animation-fade-in')) {
            childClasslist.remove('slide-container--animation-fade-in');
            childClasslist.add('slide-container--animation-fade-out');
          }
        }
      });
    },
  },
  computed: {
    swiper() {
      return this.$refs.swiper.swiper;
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
::v-deep .swiper-slide {
  width: 520px !important;
  height: 380px;
  position: relative;
}
.swiper-container {
  height: 440px;
}
::v-deep .swiper-button-prev {
  cursor: pointer;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 99px;
  left: 1123px;
  background-image: url('~@/assets/images/home/prev.png');
  background-size: 100% 100%;
  &:hover {
    cursor: pointer;
    width: 30px;
    height: 30px;
    background-image: url('~@/assets/images/home/prev-c.png');
    background-size: 100% 100%;
  }
}
::v-deep .swiper-button-next {
  cursor: pointer;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 99px;
  right: 0;
  background-image: url('~@/assets/images/home/next.png');
  background-size: 100% 100%;
  &:hover {
    cursor: pointer;
    width: 30px;
    height: 30px;
    background-image: url('~@/assets/images/home/next-c.png');
    background-size: 100% 100%;
  }
}

.home-swiper {
  min-width: 1200px;
  height: 600px;
  background: #d7e5f6 url('~@/assets/images/home/city/home_swiper_bg.png')
    center no-repeat;
  background-size: 1920px 600px;
  display: flex;
  flex-direction: column;

  .swiper-top {
    width: 1200px;
    height: 170px;
    margin: 0 auto;
    position: relative;
    .home-title {
      position: absolute;
      top: 80px;
      left: 0px;
      .title {
        line-height: 40px;
      }
    }
  }
  .swiper-bottom {
    padding-left: calc((100% - 1200px) / 2);
    height: 380px;
    .swiper-center {
      position: relative;
      width: 520px;
      height: 380px;
      .swiper-img {
        z-index: -1;
        position: absolute;
        top: 0;
      }
      .swiper-info {
        width: 520px;
        height: 380px;
        padding-top: 90px;
        opacity: 1;
        transition: all 0.5s linear;
        .swiper-icon {
          margin: 0 auto;
          background: center no-repeat;
          background-size: cover;
        }
        .icon-size1 {
          width: 100px;
          height: 69px;
        }
        .icon-size2 {
          width: 72px;
          height: 77px;
        }
        .icon-size3 {
          width: 88px;
          height: 73px;
        }
        .swiper-title {
          text-align: center;
          margin-top: 40px;
          font-size: 28px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }
        .position {
          margin-top: 100px;
          float: right;
          padding-right: 30px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
      }
      .swiper-info-hover {
        width: 520px;
        height: 380px;
        position: absolute;
        top: 0;
        padding: 47px 60px 39px 60px;
        opacity: 0;
        transition: all 0.5s linear;
        .hover-title {
          display: flex;
          align-items: center;
          img {
            width: 40px;
            height: 40px;
            margin-right: 20px;
            background-size: cover;
          }
          .title {
            font-size: 28px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #ffffff;
            white-space: nowrap;
          }
        }
        .hover-desc {
          width: 100%;
          height: 72px;
          margin-top: 66px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 24px;
          text-indent: 35px;
          text-align: justify;
        }
        .hover-solid {
          margin-top: 67px;
          width: 100%;
          height: 1px;
          background-color: #ffffff;
          opacity: 0.25;
        }
        .hover-bottom {
          margin: 0 auto;
          margin-top: 25px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .left {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
          }
          .right {
            display: flex;
            align-items: center;
            .position-icon {
              width: 12px;
              height: 14px;
              background: url('~@/assets/images/solution/position-icon.svg');
              background-size: 100% 100%;
            }
            .position-text {
              font-size: 12px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
              margin-right: 8px;
            }
          }
        }
      }
      .swiper-info,
      .swiper-info-hover {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }
      &:hover {
        .swiper-info {
          opacity: 0;
          transition: all 0.5s linear;
        }
        .swiper-info-hover {
          opacity: 1;
          transition: all 0.5s linear;
        }
      }
    }
  }
}
</style>
